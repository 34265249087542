import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { HTTP } from '../../../helper/http';

export interface NewSupportState {
  data: SupportData | null;
  status: 'idle' | 'loading' | 'failed';
  error: string;
}

export interface SupportData {
    email: string;
    title: string;
    message: string;
  }


const initialState: NewSupportState = {
  data: null,
  status: "idle",
  error: ""
}


export const contactSupport = createAsyncThunk(
  'campaign/contactSupport',
  async (p: SupportData) => {
    const payload =
    {
        "email": p.email,
        "title": p.title,
        "message": p.message
    }

    const response = await HTTP(
      new Request(
        `${process.env.REACT_APP_URL}/support`,
        {
          method: "POST",
          redirect: "follow",
          mode: "cors",
          credentials: "include",
          headers: {
            'Content-Type': `application/json`
          },
          body: JSON.stringify(payload)
        }
      )
    );
    if (response.status !== 200) {
      throw await response.json()
    }
    return await response.json()
  }
);

export const createSupportSlice = createSlice({
  name: 'createSupport',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactSupport.pending, (state) => {
        state.status = 'loading';
        state.error = "";
      })
      .addCase(contactSupport.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'idle';
        state.error = "";
      })
      .addCase(contactSupport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Unable to contact support please use email address. Error:  ${action.error.message}`;
      })
  }
});


export const contactSupportError = (state: RootState) => state.support.error;
export const contactSupportStatus = (state: RootState) => state.support.status;


export default createSupportSlice.reducer;
