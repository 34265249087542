import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

export const DurationCard = ({duration} : {duration: string}) => {

  return (
    <Paper
      elevation={2}
      sx={{
        flexGrow: 1,
        mx: "2px",
        backgroundColor: "#fff",
        marginTop: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        borderRadius: 5,
        flex: 1
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          mx: "2px",
          backgroundColor: "#fff",
          display: "flex",
          margin: 2,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          flex: 0.8,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: 10,
          }}
          color={(theme) => theme.palette.text.primary}
        >
          duration
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
          }}
          variant="h4"
          color={(theme) => theme.palette.text.primary}
        >
          {duration}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          mx: "2px",
          display: "flex",
          margin: 2,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          flex: 0.2,
        }}
      >
        <Avatar
          sx={{
            width: 60,
            height: 60,
            backgroundColor: (theme) => theme.palette.info.light,
          }}
        >
          <AccessAlarmIcon
            sx={{
              fontSize: 40,
              textAlign: "center",
              color: "#fff",
            }}
          />
        </Avatar>
      </Box>
    </Paper>
  );
}
