import { useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "../../app/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "./BootstrapDialog";
import TextField from "@mui/material/TextField";
import {
    addUserToAccount
  } from "./slice/users";

export const AddUserDialog = ({
  opened,
  handleClose,
}: {
  opened: boolean;
  handleClose: () => void;
}) => {
  const [email, setEmail] = useState("");
  const dispatch = useAppDispatch();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add user to account
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Please enter registered user email
          </Typography>
          <TextField
            required
            sx={{ marginTop: 3 }}
            id="campaign_name"
            fullWidth
            variant="outlined"
            color="info"
            size="small"
            placeholder="test@test.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="secondary"
            autoFocus
            onClick={() => {
                dispatch(addUserToAccount(email));
                handleClose();
                setEmail("")
            }}
          >
            add
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
