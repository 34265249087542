import { useEffect } from "react";
import {
  List,
  ListItemButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MainListItems } from "./ListItems";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { selectIsLoggedOut, firebaseSignout } from "../connect/slice/connect";
import {
  resetProfile,
  initialState as profileInitialState,
} from "../profile/slice/profile";

export const SideMenu = () => {
  const dispatch = useAppDispatch();
  const loggedOut = useAppSelector(selectIsLoggedOut);

  useEffect(() => {
    if (loggedOut) {
      dispatch(resetProfile(profileInitialState));
    }
  }, [loggedOut, dispatch]);

  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            sx={{ marginTop: 4, marginLeft: 5, marginRight: 5, minHeight: 30 }}
          >
            <Typography>
              <Box
                sx={{
                  fontWeight: "bold",
                  m: 1,
                  letterSpacing: 3,
                  fontSize: 30,
                  color: "#fff",
                }}
              >
                Boty
              </Box>
            </Typography>
          </Box>
          {/* <Divider /> */}
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 5 }}>
          <MainListItems />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <List>
          <ListItemButton
            sx={{ mb: 1, paddingLeft: 5 }}
            onClick={() => {
              dispatch(firebaseSignout());
            }}
          >
            <ListItemIcon>
              <LogoutIcon
                sx={{ color: "rgba(255, 255, 255, 0.5)", fontWeight: "bold" }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "rgba(255, 255, 255, 0.5)", fontWeight: "bold" }}
              primary="Logout"
            />
          </ListItemButton>
        </List>
        {/* <Button
          color="secondary"
          startIcon={<LogoutIcon />}
          to={"/"}
          component={RouterLink}
          variant="contained"
          onClick={() => {
            dispatch(firebaseSignout());
            dispatch(resetProfile(profileInitialState));
          }}
        >
          Logout
        </Button> */}
      </Box>
    </Box>
  );
};
