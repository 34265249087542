import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { HTTP } from '../../../helper/http';
;
export interface DeleteCampaignResponseState {
    status: 'idle' | 'loading' | 'failed';
    deletedCampaign: string;
    error: string;
}


const initialState: DeleteCampaignResponseState = {
    status: "idle",
    deletedCampaign: "",
    error: ""
}


export const deleteCampaign = createAsyncThunk(
    'campaign/Delete',
    async (id: string) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/${id}`,
                {
                    method: "DELETE",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        'Content-Type': `application/json`
                    },
                }
            )
        );
        if (response.status !== 204) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return id
    }
);

export const deleteCampaignSlice = createSlice({
    name: 'deleteCampaign',
    initialState,
    reducers: {
        clearDeletedCampaign: (state, action) => {
            state.deletedCampaign = "";
        },
        clearError: (state, action) => {
            state.error = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteCampaign.pending, (state) => {
                state.status = 'loading';
                state.deletedCampaign = ""
            })
            .addCase(deleteCampaign.fulfilled, (state, action) => {
                state.status = 'idle';
                state.error = "";
                state.deletedCampaign = action.payload
            })
            .addCase(deleteCampaign.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to delete campaign. Error:  ${action.error.message}`;
            })
    }
});

export const { clearDeletedCampaign, clearError } = deleteCampaignSlice.actions;

export const deleteCampaignError = (state: RootState) => state.deleteCampaign.error;
export const deletedCampaign = (state: RootState) => state.deleteCampaign.deletedCampaign;
export const deletedCampaignStatus = (state: RootState) => state.deleteCampaign.status;


export default deleteCampaignSlice.reducer;
