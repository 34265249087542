import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#4078b7',
      light: '#6592c3',
      dark: '#1b3a57',
      contrastText: '#fdfcfc',
    },
    secondary: {
      main: '#673ab7',
      light: '#9575cd',
      dark: '#512da8',
    },
    background: {
      default: '#fafafa',
    },
    text: {
      secondary: 'rgba(0,0,0,0.54)',
      primary: 'rgba(4,4,4,0.87)',
      disabled: 'rgba(2,2,2,0.38)',
    },
  },
});

export default theme;