import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { ReadyState } from 'react-use-websocket';
import { Bot } from "../../dashboard/slice/bots";
import { BotPayload } from "../../payloads/slice/payload";

export interface WSMsg {
  type: string;
  referenceId: string;
  data: any | null;
}

export interface PayloadWSMsg extends WSMsg {
  botId: string;
}

export interface TestRunMsgData {
  msgType: string;
  request: string;
  headers: Record<PropertyKey, string | number | string[]>;
  response: string;
  error: string;
}
export interface TestRunMsg {
  type: string;
  referenceId: string;
  botId: string;
  data: TestRunMsgData[];
}

export interface WSState {
  connectionState: ReadyState;
  sendJsonMessage: {};
  botStateMsg: Bot | null;
  botPayloadMsg: BotPayload | null;
  testRunMsg: TestRunMsg | null;
}

const initialState: WSState = {
  connectionState: ReadyState.UNINSTANTIATED,
  sendJsonMessage: {},
  botStateMsg: null,
  botPayloadMsg: null,
  testRunMsg: null 
};

export const wsSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    changeConnectionState: (state, action) => {
      state.connectionState = action.payload;
    },
    changeSendMessageState: (state, action) => {
      state.sendJsonMessage = action.payload;
    },
    setBotStateMsg: (state, action) => {
      state.botStateMsg = action.payload;
    },
    setBotPayloadMsg: (state, action) => {
      state.botPayloadMsg = action.payload;
    },
    setTestRunMsg: (state, action) => {
      state.testRunMsg = action.payload;
    },
  },
});

export const { changeConnectionState, changeSendMessageState, setBotStateMsg, setBotPayloadMsg, setTestRunMsg } = wsSlice.actions;

export const wsState = (state: RootState) => state.ws.connectionState;
export const wsSendMessage = (state: RootState) => state.ws.sendJsonMessage;
export const wsBotStateMsg = (state: RootState) => state.ws.botStateMsg;
export const wsBotPayloadMsg = (state: RootState) => state.ws.botPayloadMsg;
export const wsTestRunMsg = (state: RootState) => state.ws.testRunMsg;





export default wsSlice.reducer;
