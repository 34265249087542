import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Avatar, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import MailIcon from "@mui/icons-material/Mail";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PaymentsIcon from "@mui/icons-material/Payments";
import UpdateIcon from "@mui/icons-material/Update";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import {
  getAccounts,
  getUser,
  accounts,
  Account,
  user,
  selectAccount,
  selectedAccount,
  userStatus,
  accountsStatus,
} from "./slice/profile";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { ChangeEmailDialog } from "./ChangeEmailDialog";
import { FormatBytes } from "../campaign/TrafficCard";
import { StatusCard } from "./StatusCard";
import { TrafficCard } from "./TrafficCard";
import { UsersCard } from "./UsersCard";
import { CampaignsCard } from "./CampaignsCard";
import { DurationCard } from "./DurationCard";
import { SchedulerCard } from "./SchedulerCard";
import { BotsCard } from "./BotsCard";

const accountSelector = (data: Array<any>) => {
  if (!data.length) {
    return <MenuItem value="None">Account list is Empty</MenuItem>;
  }
  return data.map((v, i) => {
    return (
      <MenuItem key={i} value={v.id}>
        {v.owner_email}
      </MenuItem>
    );
  });
};

const isAccountActive = (b: boolean) => (b ? "locked" : "active");

const mapAccountId = (a: Array<Account>, userId: string): string => {
  let id = "unknown";
  Array.from(a).forEach((v) => {
    if (v?.ownerId === userId) {
      id = v?.id;
      return;
    }
  });
  return id;
};

export default function ProfileCard() {
  const dispatch = useAppDispatch();
  const accountsData = useAppSelector(accounts);
  const userData = useAppSelector(user);
  const currentAccount = useAppSelector(selectedAccount);
  const accountsLoading = useAppSelector(accountsStatus);
  const userLoading = useAppSelector(userStatus);

  const [accountId, selectAccountId] = useState(currentAccount?.id);

  const handleAccountChange = (event: SelectChangeEvent) => {
    selectAccountId(event.target.value as string);
    accountsData.forEach((v) => {
      if (v.id === event.target.value) {
        dispatch(selectAccount(v));
        document.cookie = `accountId=${v.id}`;
      }
    });
  };

  useEffect(() => {
    if (userData.id) {
      dispatch(getUser());
    }
  }, [userData.id, dispatch]);

  useEffect(() => {
    if (accountsData.length) {
      dispatch(getAccounts());
    }
  }, [accountsData.length, dispatch]);

  return (
    <Grid
      container
      flex={1}
      sx={{
        flexGrow: 1,
        mx: "2px",
        marginTop: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mx: "2px",
          display: "flex",
          flexDirection: "row",
          background: "transparent",
          alignContent: "center",
          flexGrow: 1,
        }}
      >
        {userLoading === "loading" ? (
          <Skeleton
            animation="wave"
            sx={{
              flexDirection: "column",
              flex: 0.5,
              alignItems: "stretch",
              display: "flex",
            }}
            height={600}
          />
        ) : (
          <Paper
            elevation={5}
            sx={{
              flexGrow: 1,
              flexDirection: "column",
              backgroundColor: "#fafafa",
              borderRight: "0.5px solid",
              borderColor: "#5e6e82",
              display: "flex",
              flex: 0.5,
              borderRadius: 5,
            }}
          >
            <Box
              sx={{
                margin: 4,
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="h6">
                  <Box
                    sx={{
                      fontWeight: "bold",
                      color: (theme) => theme.palette.text.disabled,
                    }}
                  >
                    User
                  </Box>
                </Typography>
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <Typography variant="subtitle2">
                  <Box
                    sx={{
                      color: (theme) => theme.palette.text.disabled,
                    }}
                  >
                    email
                  </Box>
                </Typography>
              </Box>
              <Box sx={{}}>
                <Typography variant="subtitle1">
                  <Box
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: "#fff",
                      }}
                    >
                      <MailIcon
                        sx={{
                          fontSize: 15,
                          textAlign: "center",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      />
                    </Avatar>
                    &nbsp;{userData ? userData.email : "none"}
                  </Box>
                </Typography>
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <Typography variant="subtitle2">
                  <Box
                    sx={{
                      color: (theme) => theme.palette.text.disabled,
                    }}
                  >
                    created
                  </Box>
                </Typography>
              </Box>
              <Box sx={{}}>
                <Typography variant="subtitle1">
                  <Box
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: "#fff",
                      }}
                    >
                      <AccessTimeIcon
                        sx={{
                          fontSize: 15,
                          textAlign: "center",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      />
                    </Avatar>
                    &nbsp;
                    {userData
                      ? new Date(userData.created).toLocaleString()
                      : "none"}
                  </Box>
                </Typography>
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <Typography variant="subtitle2">
                  <Box
                    sx={{
                      color: (theme) => theme.palette.text.disabled,
                    }}
                  >
                    account ID
                  </Box>
                </Typography>
              </Box>
              <Box sx={{}}>
                <Typography variant="subtitle1">
                  <Box
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: "#fff",
                      }}
                    >
                      <FingerprintIcon
                        sx={{
                          fontSize: 15,
                          textAlign: "center",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      />
                    </Avatar>
                    &nbsp;{mapAccountId(accountsData, userData.id)}
                  </Box>
                </Typography>
              </Box>
              {userData?.iss === "password" ? (
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: 5,
                  }}
                >
                  <Typography variant="h6">
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.text.disabled,
                      }}
                    >
                      Security
                    </Box>
                  </Typography>
                  <ChangePasswordDialog />
                  <ChangeEmailDialog />
                </Box>
              ) : (
                ""
              )}
              <Box sx={{}}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    sx={{
                      marginTop: 4,
                      marginBottom: 2,
                      flexGrow: 1,
                      flex: 1,
                    }}
                  >
                    <Typography variant="h6">
                      <Box
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        Account
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="subtitle2">
                      <Box
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        account owner
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography variant="subtitle1">
                      <Box
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 15,
                            height: 15,
                            backgroundColor: "#fff",
                          }}
                        >
                          <MailIcon
                            sx={{
                              fontSize: 15,
                              textAlign: "center",
                              color: (theme) => theme.palette.text.secondary,
                            }}
                          />
                        </Avatar>
                        &nbsp;
                        {currentAccount ? currentAccount?.owner_email : "none"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="subtitle2">
                      <Box
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        account type
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography variant="subtitle1">
                      <Box
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 15,
                            height: 15,
                            backgroundColor: "#fff",
                          }}
                        >
                          <PaymentsIcon
                            sx={{
                              fontSize: 15,
                              textAlign: "center",
                              color: (theme) => theme.palette.text.secondary,
                            }}
                          />
                        </Avatar>
                        &nbsp;
                        {currentAccount
                          ? currentAccount?.type ?? "not defined yet"
                          : "none"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="subtitle2">
                      <Box
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        created
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography variant="subtitle1">
                      <Box
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 15,
                            height: 15,
                            backgroundColor: "#fff",
                          }}
                        >
                          <AccessTimeIcon
                            sx={{
                              fontSize: 15,
                              textAlign: "center",
                              color: (theme) => theme.palette.text.secondary,
                            }}
                          />
                        </Avatar>
                        &nbsp;
                        {currentAccount
                          ? new Date(currentAccount.created).toLocaleString()
                          : "none"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="subtitle2">
                      <Box
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        updated
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography variant="subtitle1">
                      <Box
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 15,
                            height: 15,
                            backgroundColor: "#fff",
                          }}
                        >
                          <UpdateIcon
                            sx={{
                              fontSize: 15,
                              textAlign: "center",
                              color: (theme) => theme.palette.text.secondary,
                            }}
                          />
                        </Avatar>
                        &nbsp;
                        {currentAccount
                          ? new Date(currentAccount.updated).toLocaleString()
                          : "none"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="subtitle2">
                      <Box
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        subscription
                      </Box>
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography variant="subtitle1">
                      <Box
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 15,
                            height: 15,
                            backgroundColor: "#fff",
                          }}
                        >
                          <SettingsBackupRestoreIcon
                            sx={{
                              fontSize: 15,
                              textAlign: "center",
                              color: (theme) => theme.palette.text.secondary,
                            }}
                          />
                        </Avatar>
                        &nbsp;
                        {currentAccount
                          ? new Date(
                              currentAccount?.subscriptionDate
                            ).toLocaleString()
                          : "none"}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Divider />
              <Box
                sx={{
                  margin: 4,
                }}
              >
                <FormControl
                  sx={{ marginRight: 5, backgroundColor: "#fff" }}
                  fullWidth
                >
                  <InputLabel id="accounts">Accounts</InputLabel>
                  <Select
                    labelId="accounts"
                    label="Accounts"
                    variant="outlined"
                    value={accountId}
                    onChange={handleAccountChange}
                  >
                    {accountSelector(accountsData)}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Paper>
        )}

        {accountsLoading === "loading" ? (
          <Skeleton
            animation="wave"
            sx={{
              flexDirection: "column",
              flex: 0.5,
              alignItems: "stretch",
              display: "flex",
              marginLeft: 5,
            }}
            height={600}
          />
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              flexDirection: "column",
              flex: 0.5,
              alignItems: "stretch",
              display: "flex",
              marginLeft: 5,
            }}
          >
            <StatusCard
              status={
                currentAccount ? isAccountActive(currentAccount?.lock) : "none"
              }
            />

            <Box
              sx={{
                flexGrow: 1,
                flexDirection: "row",
                alignItems: "stretch",
                display: "flex",
              }}
            >
              <UsersCard
                users={currentAccount ? `${currentAccount?.vUsers}` : "none"}
              />
              <CampaignsCard
                campaigns={
                  currentAccount ? `${currentAccount?.campaigns}` : "none"
                }
              />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                flexDirection: "row",
                alignItems: "stretch",
                display: "flex",
              }}
            >
              <DurationCard
                duration={
                  `${currentAccount?.duration}` ? "enabled" : "disabled"
                }
              />
              <SchedulerCard
                scheduler={
                  `${currentAccount?.scheduler}` ? "enabled" : "disabled"
                }
              />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                flexDirection: "row",
                alignItems: "stretch",
                display: "flex",
              }}
            >
              <BotsCard
                bots={currentAccount ? `${currentAccount?.bots}` : "none"}
              />
              <TrafficCard
                traffic={FormatBytes(parseInt(currentAccount?.traffic ?? 0))}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
