import { useState, useEffect, useRef, memo } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Stack from "@mui/material/Stack";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import {
  ButtonGroup,
  Grow,
  Popper,
  Skeleton,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getBots,
  botsStatus,
  getBotsState,
  Bot,
  deletedBot,
  botsDeletedFrom,
  botsListStatus,
} from "./slice/bots";
import { Campaign } from "../campaign/slice/campaign";
import { CampaignPayloadDialog } from "./CampaignPayloadDialog";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { DeleteBotsDialog } from "./DeleteBotsDialog";
import { DeleteBotDialog } from "./DeleteBotDialog";
import { BotPayloadDialog } from "./BotPayloadDialog";
import AutoSizer from "react-virtualized-auto-sizer";
import { wsBotStateMsg, wsBotPayloadMsg } from "../connect/slice/ws";
import { StartStopBotDialog } from "./StartStopBotDialog";
import { TestRun } from "./TestRun";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import { AddBotsDialog } from "./AddBotsDialog";

const hasPayload = (b: boolean, suites: string) => {
  if (b) {
    return (
      <Tooltip title={
        <div style={{ whiteSpace: 'pre-line' }}>{suites}</div>
      }>
        <CheckCircleOutlineIcon color="success" />
      </Tooltip>
    );
  }
  return <DoNotDisturbOnIcon />;
};

const greenText = `
Feel free to remove stale/unused bots, this helps us to be greener ♻️
`;

const RenderRow = (props: ListChildComponentProps, campaignData: Campaign) => {
  const { data, index, style } = props;
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const e = data[index];

  return (
    <Box key={index} style={{ ...style }}>
      <Box
        sx={{
          borderRadius: 3,
          border: "0.5px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          maxHeight: 70,
          width: "100%",
          padding: 1.8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Tooltip title={greenText}>
              <EnergySavingsLeafIcon
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "green",
                  margin: 1,
                }}
              />
            </Tooltip>
            <Typography variant="h5" align={"left"}>
              <Box
                sx={{
                  fontWeight: "bold",
                  color: (theme) => theme.palette.text.secondary,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "20rem",
                }}
              >
                {e.name}
              </Box>
              <Box
                sx={{
                  color: "#bdbdbd",
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: 10,
                }}
              >
                ID: {e?.id}
              </Box>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 0.5,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {new Date(e?.created).toLocaleString()}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              created at
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 0.5,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e?.startAt
                ? new Date(e?.startAt).toLocaleString()
                : "Scheduler is not enabled"}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              start at
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 0.3,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e?.duration}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              duration
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 0.3,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e?.rampUp}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              ramp-up
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
            flex: 0.3,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e?.vUsers}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              virtual users
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 0.5,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e?.ip !== "" ? e?.ip : <CircularProgress size={15} />}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              IP
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 0.5,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {hasPayload(e?.payload?.length > 0, e?.payload?.map((v: Bot) => v.name).join("\n"))}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              payload
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 0.5,
          }}
        >
          <Typography variant="subtitle2" align={"center"} sx={{ margin: 1 }}>
            <Box
              sx={{
                fontWeight: "bold",
                color: () => {
                  if (e?.status === "created") {
                    return "#4078b7";
                  }
                  if (e?.status === "running") {
                    return "#4a9c07";
                  }
                  if (e?.status === "ready") {
                    return "#4078b7";
                  }
                  if (e?.status === "stopped") {
                    return "#d32f2f";
                  }
                  return "rgba(2,2,2,0.38)";
                },
              }}
            >
              {e?.status}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              status
            </Box>
          </Typography>
        </Box>
        <ButtonGroup ref={anchorRef} aria-label="split button">
          <IconButton
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            onClick={handleToggle}
            color="primary"
          >
            <MoreVertIcon />
          </IconButton>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    <BotPayloadDialog bot={e} campaignId={e?.campaignId} />
                    <DeleteBotDialog disabled={campaignData?.status === "pending" ? true : false} botId={e?.id} campaignId={e?.campaignId} />
                    <StartStopBotDialog disabled={campaignData?.status === "pending" ? true : false} bot={e} campaignId={e?.campaignId} />
                    <TestRun bot={e} />
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

const LoadMore = (getBots: () => void, loading: boolean) => {
  return (
    <LoadingButton variant="text" loading={loading} onClick={() => getBots()}>
      Load more...
    </LoadingButton>
  );
};

const Loading = (b: boolean) => {
  if (b) {
    return (
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Skeleton animation="wave" height={118} />
        <Skeleton animation="wave" height={118} />
        <Skeleton animation="wave" height={118} />
        <Skeleton animation="wave" height={118} />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: 550,
        flexGrow: 1,
        mx: "2px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="subtitle1" align={"center"}>
        <Box
          sx={{
            marginTop: 10,
          }}
        />

        <Box
          sx={{
            fontWeight: "bold",
            margin: 4,
            color: (theme) => theme.palette.text.disabled,
          }}
        >
          Bot list is empty.
        </Box>
      </Typography>
    </Box>
  );
};

export const BotSection = memo(({ campaignData }: { campaignData: Campaign }) => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(botsStatus);
  const [currentBots, setBots] = useState([] as Bot[]);
  const wsBot = useAppSelector(wsBotStateMsg);
  const wsBotPayload = useAppSelector(wsBotPayloadMsg);
  const lastDeletedBot = useAppSelector(deletedBot);
  const staleCampaignID = useAppSelector(botsDeletedFrom);
  const listStatus = useAppSelector(botsListStatus);

  const loadMore = () => {
    const startFetching = async () => {
      const { payload } = await dispatch(
        getBots({ id: campaignData?.id, offset: currentBots.length })
      );
      if ((payload as Bot[]).length > 0) {
        setBots([...currentBots, ...payload]);
        dispatch(getBotsState(campaignData?.id));
      }
    };

    startFetching();
  };

  useEffect(() => {
    let ignore = false;


    if (campaignData?.status === "pending" && !ignore) {
      const startFetching = async () => {

        const { payload } = await dispatch(
          getBots({ id: campaignData?.id, offset: 0 })
        );
        if ((payload as Bot[]).length > 0) {
          setBots(payload);
          dispatch(getBotsState(campaignData?.id));
        }

      };

      startFetching();
    }

    return () => {
      ignore = true;
    };
  }, [dispatch, campaignData?.id, campaignData?.status]);

  useEffect(() => {
    let ignore = false;

    const startFetching = async () => {
      const { payload } = await dispatch(
        getBots({ id: campaignData?.id, offset: 0 })
      );
      if (!ignore) {
        if ((payload as Bot[]).length > 0) {
          setBots(payload);
          dispatch(getBotsState(campaignData?.id));
        }
      }
    };

    startFetching();

    return () => {
      ignore = true;
    };
  }, [dispatch, campaignData?.id]);

  useEffect(() => {
    let ignore = false;

    if (wsBot?.status && wsBot?.id) {
      if (ignore) return;
      setBots((a) => {
        return a.map((v, i, a) => {
          if (v.id === wsBot?.id) {
            const bot = { ...wsBot };
            if (v?.payload) {
              bot.payload = v?.payload;
            }
            bot.name = v.name;
            return bot;
          }
          return v;
        });
      });
    }

    return () => {
      ignore = true;
    };
  }, [currentBots.length, wsBot?.status, wsBot?.id]);

  useEffect(() => {
    let ignore = false;

    if (wsBotPayload && currentBots.length) {
      if (ignore) return;
      setBots((a) => {
        return a.map((v, i, a) => {
          if (v.id === wsBotPayload?.botId) {
            const bot = { ...v };
            bot.payload = wsBotPayload?.data;
            return bot;
          }
          return v;
        });
      });
    }

    return () => {
      ignore = true;
    };
  }, [currentBots.length, wsBotPayload]);

  useEffect(() => {
    let ignore = false;

    if (lastDeletedBot) {
      const newArr = currentBots.filter((v) => v.id !== lastDeletedBot);
      const startFetching = async () => {
        const { payload } = await dispatch(
          getBots({ id: campaignData?.id, offset: newArr.length })
        );

        if (ignore) return;

        setBots([...newArr, ...(payload as Bot[])]);
      };

      startFetching();
    }

    return () => {
      ignore = true;
    };
  }, [dispatch, lastDeletedBot]);

  useEffect(() => {
    if (staleCampaignID === campaignData?.id) {
      setBots([]);
    }
  }, [dispatch, staleCampaignID]);

  // useEffect(() => {
  //   console.log(socket);
  //   console.log(sendWSMsg);
  //   if (socket.toString === ReadyState.OPEN.toString) {
  //     sendWSMsg({ "": "" }, true);
  //   }
  // }, [dispatch, sendWSMsg, socket]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="center"
          xs={12}
        >
          <Grid item xs={12}>
            <Typography variant="h6">
              <Box
                sx={{
                  fontWeight: "bold",
                  color: (theme) => theme.palette.primary.dark,
                }}
              >
                Bots
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          xs={12}
        >
          <Box
            sx={{
              flexGrow: 1,
              mx: "2px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction="row" spacing={2}>
              <CampaignPayloadDialog
                disabled={(currentBots.length > 0 ? false : true) || campaignData?.status === "pending"}
              />
              <AddBotsDialog
                disabled={campaignData?.status === "running" || campaignData?.status === "pending"}
                campaignId={campaignData?.id}
              />
              <DeleteBotsDialog
                disabled={(currentBots.length > 0 ? false : true) || campaignData?.status === "pending"}
                campaignId={campaignData?.id}
              />
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          display="flex"
          flex="1"
          justifyContent="center"
          xs={12}
          flexDirection="column"
        >
          <Box>
            <Typography variant="body2">
              <Box
                sx={{
                  color: (theme) => theme.palette.primary.light,
                  flexGrow: 1,
                  mx: "2px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                info: bots are asynchronous, response may be delayed
              </Box>
            </Typography>
          </Box>
          {currentBots.length ? (
            <TableContainer style={{ height: "calc(50vh)" }}>
              <AutoSizer>
                {({ height, width }) => (
                  <VariableSizeList
                    itemData={currentBots}
                    height={height}
                    width={width}
                    itemSize={() => 75}
                    itemCount={currentBots.length}
                    overscanCount={5}
                  >
                    {(props, campaignData) => RenderRow(props, campaignData)}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </TableContainer>
          ) : (
            Loading(listStatus === "loading")
          )}
        </Grid>
        {currentBots.length >= 20
          ? LoadMore(loadMore, status === "loading" ? true : false)
          : ""}
      </Grid>
    </Box>
  );
})
