import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SideMenu } from "./SideMenu";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Profile from "../profile/Profile";
import Campaigns from "../campaign/Campaigns";
import Copyright from "../copyright/Copyright";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectedStep } from "./slice/section";
import Dashboard from "../dashboard/Dashboard";
import CampaignCard from "../campaign/CampaignCard";
import PayloadCard from "../payloads/PayloadCard";
import Users from "../users/Users";
import Payloads from "../payloads/Payloads";
import { accountCookie } from "../../helper/cookie";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { NewCampaignOutState } from "../campaign/slice/create";
import { useNavigate } from "react-router-dom";
import { setResult } from "../campaign/slice/create";
import {
  getAccounts,
  getUser,
  accounts,
  user,
  selectAccount,
  selectedAccount,
} from "../profile/slice/profile";
import Image from "./pattern.png";
import Drawer from "@mui/material/Drawer";
import {
  changeConnectionState,
  wsState,
  setBotStateMsg,
  setBotPayloadMsg,
  setTestRunMsg,
  WSMsg,
  TestRunMsg,
  wsSendMessage,
  PayloadWSMsg,
} from "../connect/slice/ws";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { selectIsLoggedOut } from "../connect/slice/connect";
import { Snackbar, Alert } from "@mui/material";
import { CampaignOut } from "../campaign/slice/campaign";
import { setLaunchedCampaign } from "../dashboard/slice/launch";
import { SupportDialog } from "../contact/SupportDialog";

const drawerWidth = 270;

interface Props {
  screen?: () => Window;
}

export default function Section(props: Props) {
  const socketUrl = `${process.env.REACT_APP_WS}/ws`;
  const name = useAppSelector(selectedStep);
  const dispatch = useAppDispatch();
  const msgToSend = useAppSelector(wsSendMessage);
  const accountsData = useAppSelector(accounts);
  const currentAccount = useAppSelector(selectedAccount);
  const [init, setInit] = useState(true);
  const { screen } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [reloadApp, setReloadApp] = useState(false);
  const [createCampaignErrorInternal, setCreateCampaignErrorInternal] =
    useState("");
  const [createCampaignSuccessInternal, setCreateCampaignSuccessInternal] =
    useState("");
  const loggedOut = useAppSelector(selectIsLoggedOut);
  const navigate = useNavigate();
  const userData = useAppSelector(user);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const {
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log("opened"),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (lastJsonMessage !== null) {
      switch ((lastJsonMessage as unknown as WSMsg)?.type) {
        case "bot":
          dispatch(setBotStateMsg((lastJsonMessage as unknown as WSMsg)?.data));
          break;
        case "payload":
          const botPayload = lastJsonMessage as unknown as PayloadWSMsg;
          dispatch(
            setBotPayloadMsg({botId: botPayload?.botId, data: botPayload?.data})
          );
          break;
        case "testRun":
          dispatch(setTestRunMsg(lastJsonMessage as unknown as TestRunMsg));
          break;
        case "user":
          const cookie = (lastJsonMessage as unknown as WSMsg)?.data;

          const appVersion = accountCookie("appVersion");
          if (appVersion !== cookie?.app) {
            document.cookie = `appVersion=${cookie?.app}`;
            setReloadApp(true);
          }
          break;
        case "campaign":
          let errors = [];
          const msg = lastJsonMessage as unknown as NewCampaignOutState;
          dispatch(setResult(msg))
          msg?.data?.deployments.forEach((v) => {
            if (v.error) {
              errors.push(v.error);
            }
          });
          if (errors.length > 0) {
            setCreateCampaignErrorInternal(
              "Not all bots have been deployed. Please check created campaign and recreate campaign if needed."
            );
            setErrorOpen(true);
          } else {
            setCreateCampaignSuccessInternal(
              "Bots are deployed. NOTE: if you see the pending status for a long time, that might indicate the bot's fatal issues. Please consider removing stale/crashed bots."
            );
            setSuccessOpen(true);
          }
          break;
        case "campaignData":
          const launchedMsg = lastJsonMessage as unknown as CampaignOut;
          dispatch(setLaunchedCampaign(launchedMsg?.data));
          break;
        default:
          break;
      }
    }
    if (readyState.toString !== wsState.toString) {
      dispatch(changeConnectionState(readyState));
    }
  }, [dispatch, lastJsonMessage, lastMessage, readyState, sendJsonMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && Object.keys(msgToSend).length > 0) {
      sendJsonMessage(msgToSend);
    }
  }, [readyState]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (init) {
      dispatch(getUser());
      dispatch(getAccounts());
    }
    setInit(false);
  }, [init, dispatch]);

  useEffect(() => {
    if (accountsData.length) {
      if (accountCookie("accountId")) {
        let selected = false;
        Array.from(accountsData).forEach((v) => {
          if (v?.id === accountCookie("accountId")) {
            dispatch(selectAccount(v));
            document.cookie = `accountId=${v?.id}`;
            selected = true;
          }
        });
        if (!selected) {
          const account = accountsData[0];
          dispatch(selectAccount(account));
          document.cookie = `accountId=${account?.id}`;
        }
      } else {
        const account = accountsData[0];
        dispatch(selectAccount(account));
        document.cookie = `accountId=${account?.id}`;
      }
    }
  }, [accountsData, currentAccount, dispatch]);

  useEffect(() => {
    if (loggedOut && !userData?.id) {
      getWebSocket()?.close(1000);
      navigate("/");
    }
  }, [userData?.id, loggedOut, dispatch]);

  useEffect(() => {
    if (createCampaignErrorInternal) {
      setCreateCampaignErrorInternal(createCampaignErrorInternal);
      setErrorOpen(true);
    }
  }, [createCampaignErrorInternal, dispatch]);

  useEffect(() => {
    if (createCampaignSuccessInternal) {
      setCreateCampaignSuccessInternal(createCampaignSuccessInternal);
      setSuccessOpen(true);
    }
  }, [createCampaignSuccessInternal, dispatch]);

  useEffect(() => {
    if (reloadApp) {
      setAlertOpen(true);
    }
  }, [reloadApp, dispatch]);

  const handleErrorClose = () => {
    setErrorOpen(false);
    setCreateCampaignErrorInternal("");
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    setCreateCampaignSuccessInternal("");
  };

  const container =
    screen !== undefined ? () => screen().document.body : undefined;

  const handleAlertClose = () => {
    setAlertOpen(false);
    setReloadApp(false);
    window.location.reload();
  };

  const context = (view: string) => {
    switch (view) {
      // case "campaign":
      //   return <Campaign />;
      case "campaigns":
        return <Campaigns />;
      case "profile":
        return <Profile />;
      case "dashboard":
        return <Dashboard />;
      case "campaign":
        return <CampaignCard />;
      case "account Users":
        return <Users />;
      case "payloads":
        return <Payloads />;
      case "payload":
        return <PayloadCard />;
      default:
        break;
    }
  };

  return (
    <Grid container flex={1}>
      <SupportDialog location={"default"} />
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={16000}
        onClose={() => {
          handleErrorClose();
        }}
      >
        <Alert
          onClose={() => handleErrorClose()}
          severity="warning"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {createCampaignErrorInternal}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={16000}
        onClose={() => {
          handleSuccessClose();
        }}
      >
        <Alert
          onClose={() => handleSuccessClose()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {createCampaignSuccessInternal}
        </Alert>
      </Snackbar>
      <Snackbar
        open={alertOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={10000}
        onClose={() => {
          handleAlertClose();
        }}
      >
        <Alert
          onClose={() => handleAlertClose()}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Application has been updated, the window will be reloaded after 10
          seconds!
        </Alert>
      </Snackbar>
      <AppBar
        position="absolute"
        style={{ background: "transparent", boxShadow: "none" }}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, marginTop: 5 }}
          >
            <MenuIcon sx={{ color: "#1b3a57" }} />
          </IconButton>
          <Grid item flex={1}>
            <Typography variant="h4">
              <Box
                sx={{
                  fontWeight: "bold",
                  color: (theme) => theme.palette.primary.dark,
                  flexGrow: 1,
                  marginTop: 5,
                  // marginBottom: 5,
                }}
              >
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </Box>
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#1b3a57",
              width: drawerWidth,
              height: "100vh",
              borderRight: "0.5px solid",
              borderColor: "#5e6e82",
              display: "flex",
              flexDirection: "column",
              backgroundImage: `url(${Image})`,
            }}
          >
            <SideMenu />
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Box
            sx={{
              backgroundColor: "#1b3a57",
              width: drawerWidth,
              height: "100vh",
              borderRight: "0.5px solid",
              borderColor: "#5e6e82",
              display: "flex",
              flexDirection: "column",
              backgroundImage: `url(${Image})`,
            }}
          >
            <SideMenu />
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {context(name)}
        <Box sx={{ marginTop: 5 }}>
          <Copyright sx={{ pt: 4 }} />
        </Box>
      </Box>
    </Grid>
  );
}
