import { useCallback } from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { HTTP } from '../../../helper/http';

export interface Region {
    id: string;
    created: string;
    updated: string;
    name: string;
    provider: string;
}

export interface RegionsState {
    status: 'idle' | 'loading' | 'failed';
    regions: Region[] | [];
    error: string;
}

const initialState: RegionsState = {
    status: 'idle',
    regions: [],
    error: "",
};

export const getRegions = createAsyncThunk(
    'campaign/getRegions',
    async (id: string) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/regions/accounts/${id}`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);


export const regionsSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRegions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRegions.fulfilled, (state, action) => {
                if (action.payload) {
                    state.regions = action.payload;
                }
                state.status = 'idle';
            })
            .addCase(getRegions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to get Regions. Error:  ${action.error.message}`;
            })
    },
});

export const regionsError = (state: RootState) => state.regions.error;
export const regions = (state: RootState) => state.regions.regions;


export default regionsSlice.reducer;
