import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { getRegions, regions } from "../campaign/slice/region";
import { BootstrapDialog, BootstrapDialogTitle } from "./DeleteBotsDialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { RegionSelector } from "../campaign/CampaignCard";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  accounts,
  selectedAccount,
} from "../profile/slice/profile";
import { v4 as uuidv4 } from "uuid";
import { addBotsToCampaign } from "../campaign/slice/create";

export const AddBotsDialog = ({
  campaignId,
  disabled,
}: {
  campaignId: string;
  disabled: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [regionId, selectRegionId] = useState("");
  const [region, selectRegion] = useState("");
  const data = useAppSelector(regions);
  const [botAmount, setBotAmount] = useState(0);
  const [botUsers, setBotUsers] = useState(0);
  const [botDuration, setBotDuration] = useState("60s");
  const [botStartAt, setBotStartAt] = useState("");
  const [botRampUp, setBotRampUp] = useState("0s");
  const accountsData = useAppSelector(accounts);
  const currentAccount = useAppSelector(selectedAccount);

  const handleChange = (event: SelectChangeEvent) => {
    selectRegionId(event.target.value as string);
    data.forEach((v) => {
      if (v.id === event.target.value) {
        selectRegion(v.name as string);
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data.length) {
      selectRegionId(data[0].id);
      selectRegion(data[0].name);
    }
  }, [data]);

  useEffect(() => {
    if (currentAccount.id) {
      dispatch(getRegions(currentAccount.id));
    }
  }, [dispatch, currentAccount]);

  return (
    <div>
      <Button
        style={{ maxHeight: "40px" }}
        variant="outlined"
        color="secondary"
        disabled={disabled}
        sx={{
          fontWeight: "bold",
        }}
        onClick={handleClickOpen}
      >
        add bots
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add bots to the campaign
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container direction="column" justifyContent="center">
            <Grid item display="flex" flex="1" justifyContent="center" xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexGrow: 1,
                  flexDirection: "row",
                }}
              >
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 1,
                      margin: 3,
                      flexGrow: 1,
                      display: "flex",
                      maxWidth: 800,
                      flex: 1,
                    },
                  }}
                  noValidate
                  autoComplete="off"
                  alignItems={"stretch"}
                >
                  <Box sx={{}}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Regions
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        value={regionId}
                        label="Regions"
                        onChange={handleChange}
                      >
                        {RegionSelector(data)}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ display: "flex", flex: 1 }}
                        variant="body2"
                        align={"center"}
                      >
                        <Box
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          How many bots run per region?
                        </Box>
                      </Typography>
                      <FormControl sx={{ marginLeft: 5 }}>
                        <TextField
                          required
                          size="small"
                          id="bots"
                          label="Bots"
                          placeholder={`max ${
                            accountsData.filter(
                              (v) => v.id === currentAccount?.id
                            )[0]?.bots ?? 0
                          }`}
                          variant="outlined"
                          type="number"
                          onChange={(e) =>
                            setBotAmount(parseInt(e.target.value))
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        alignContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <Typography
                        sx={{ display: "flex", flex: 1 }}
                        variant="body2"
                        align={"center"}
                      >
                        <Box
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          How many virtual users run per bot?
                        </Box>
                      </Typography>
                      <FormControl sx={{ marginLeft: 5 }}>
                        <TextField
                          required
                          size="small"
                          id="outlined-basic"
                          label="Virtual Users"
                          placeholder={`max ${
                            accountsData.filter(
                              (v) => v.id === currentAccount?.id
                            )[0]?.vUsers ?? 0
                          }`}
                          variant="outlined"
                          type="number"
                          onChange={(e) =>
                            setBotUsers(parseInt(e.target.value))
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        alignContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <Typography
                        sx={{ display: "flex", flex: 1 }}
                        variant="body2"
                        align={"center"}
                      >
                        <Box
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          At what time start the bot?
                        </Box>
                      </Typography>
                      <FormControl sx={{ marginLeft: 5 }}>
                        <TextField
                          disabled={
                            !accountsData.filter(
                              (v) => v.id === currentAccount?.id
                            )[0]?.scheduler
                          }
                          id="datetime-local"
                          size="small"
                          label="Start At"
                          variant="outlined"
                          type="datetime-local"
                          defaultValue="2017-05-24T10:30"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setBotStartAt(
                              new Date(e.target.value).toISOString()
                            )
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        alignContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <Typography
                        sx={{ display: "flex", flex: 1 }}
                        variant="body2"
                        align={"center"}
                      >
                        <Box
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          What is the bot duration?
                        </Box>
                      </Typography>
                      <FormControl sx={{ marginLeft: 5 }}>
                        <TextField
                          required
                          size="small"
                          id="outlined-basic"
                          label="Duration(i.e. 1s, 1m, 1h)"
                          placeholder="min 2s"
                          variant="outlined"
                          onChange={(e) => setBotDuration(e.target.value)}
                        />
                      </FormControl>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        alignContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <Typography
                        sx={{ display: "flex", flex: 1 }}
                        variant="body2"
                        align={"center"}
                      >
                        <Box
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          What is the ramp-up duration per bot?
                        </Box>
                      </Typography>
                      <FormControl sx={{ marginLeft: 5 }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          label="Duration(i.e. 1s, 1m, 1h)"
                          placeholder="default 0s"
                          onChange={(e) => setBotRampUp(e.target.value)}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="secondary"
            autoFocus
            onClick={() => {
              dispatch(
                addBotsToCampaign({
                  id: campaignId,
                  data: {
                    accountId: currentAccount?.id,
                    bots: [
                      {
                        regionId: regionId,
                        regionName: region,
                        amount: botAmount,
                        vUsers: botUsers,
                        duration: botDuration,
                        rampUp: botRampUp,
                        ...(botStartAt && { startAt: botStartAt }),
                        referenceId: uuidv4(),
                      },
                    ],
                  },
                })
              );
              handleClose();
            }}
          >
            add
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
