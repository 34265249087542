import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";

export const PrivacyDialog = ({color}: {color: string}) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Link
        onClick={handleClickOpen}
        variant="subtitle1"
        style={{color: color}}
        underline="hover"
      >
        Privacy policy
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Privacy policy agreement
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <Typography
            variant="body1"
            color="text.disabled"
            sx={{ marginBottom: 2 }}
          >
            Effective January 1, 2023
          </Typography>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                General
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                This Privacy Policy explains how "Boty", collects, uses and
                shares your “Personal Data” – information that identifies,
                relates to, describes or is capable of being associated with you
                as a particular individual, such as your name, address, email
                address or telephone number – and other data through your use of
                any of "Boty" services.
              </Typography>
              <br />
              <Typography variant="subtitle1" color="text.primary">
                "Boty" reserves the right to update and change this Privacy
                Policy, and will inform you about the changes from time to time.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Collection of personal data
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                "Boty" has no knowledge about your full credit card number as
                this resides with our payment gateway providers, who are
                independent data controllers.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Use of personal data
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                "Boty" collects Personal Data for the following general
                purposes:
              </Typography>
              <li>Services provision, including billing</li>
              <li>Handling of your customer account</li>
              <li>Contact</li>
              <li>Compliance with legal requirements</li>
            </Stack>
            <br />
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Retention time
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                "Boty" do not store data “no longer than is necessary” and is
                compliant with GDPR. Account data may be deleted after
                subscription termination if it does not require in case of
                legal/financial processes.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Sharing of personal data
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                "Boty" does not rent, sell, or share personal data about you
                with other people or non-affiliated companies except to provide
                products or services you’ve requested, when we have your
                permission, or under the following circumstances:
              </Typography>
              <br />
              <Typography variant="subtitle1" color="text.primary">
                <strong>
                  We believe it is necessary to share information in order to
                  investigate, prevent, or take action regarding illegal
                  activities, suspected fraud, violations of "Boty" terms of
                  use, or as otherwise required by law. An example of such
                  illegal activity is using "Boty" to execute a denial of
                  service (DDOS) attack on a website or application not legally
                  owned or managed by the user.
                </strong>
              </Typography>
              <br />
              <Typography variant="subtitle1" color="text.primary">
                <strong>
                  "Boty" may share information with public authorities when
                  complied to do so by law, for instance in relation to law
                  enforcement requests and accounting requirements. When public
                  authorities receive personal data from us, they are
                  independent data controllers.
                </strong>
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Cookie
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                Boty is using only necessary service cookies. Service cookies
                may contain:
              </Typography>
              <li>Session cookies</li>
              <li>SSO cookies (Firebase)</li>
              <li>Security cookies (Cloudflare)</li>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Data transmission and storage
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                Data is encrypted at rest and in transit.
              </Typography>
            </Stack>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
