import React from "react";
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

import { LineChart, Line } from "recharts";

const data = [
  {
    name: "Page A",
    requests: 4000,
    errors: 7000,
    amt: 2400,
  },
  {
    name: "Page B",
    requests: 2390,
    errors: 9000,
    amt: 2210,
  },
  {
    name: "Page C",
    requests: 2000,
    errors: 7000,
    amt: 2290,
  },
  {
    name: "Page E",
    requests: 2000,
    errors: 7500,
    amt: 2181,
  },
  {
    name: "Page D",
    requests: 1900,
    errors: 8000,
    amt: 2000,
  },
  {
    name: "Page E",
    requests: 2000,
    errors: 7500,
    amt: 2181,
  },
  {
    name: "Page E",
    requests: 2000,
    errors: 7000,
    amt: 2181,
  },
  {
    name: "Page F",
    requests: 2390,
    errors: 9000,
    amt: 2500,
  },
  {
    name: "Page G",
    requests: 4000,
    errors: 7000,
    amt: 2100,
  },
];

export const About = (
  aboutRef:
    | ((instance: HTMLElement | null) => void)
    | React.RefObject<HTMLElement>
    | null
    | undefined
) => {
  return (
    <Container
      ref={aboutRef}
      maxWidth="lg"
      component="main"
      sx={{ pt: 18, pb: 6 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          flexGrow: 1,
          marginTop: 15,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            flex: 1,
            justifyContent: "center",
            backgroundColor: "#fafafa",
          }}
        >
          <LineChart
            width={600}
            height={600}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Line type="monotone" dataKey="errors" stroke={"#d32f2f"} />
            <Line type="monotone" dataKey="requests" stroke="#673ab7" />
          </LineChart>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 5,
            flex: 1,
            flexGrow: 1,
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant="h3"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Boty is a minimalistic and powerful robot framework
          </Typography>
          <Typography
            variant="h6"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Boty is using a reactive approach, bots are immutable and deployed
            during campaign initialization. It is designed to be easy to use and
            highly effective.
          </Typography>
          <br />
          <Typography
            variant="h6"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Boty is perfect for developers, QA engineers, and anyone else who
            needs to test the performance of their applications. It is highly
            customizable and can be used to simulate a wide range of different
            scenarios:{" "}
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <PrecisionManufacturingIcon
                  style={{
                    color: "rgba(4,4,4,0.87)",
                    fontWeight: "bold",
                  }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: "rgba(4,4,4,0.87)",
                  fontWeight: "bold",
                }}
                primary="DDOS testing"
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PrecisionManufacturingIcon
                  style={{
                    color: "rgba(4,4,4,0.87)",
                    fontWeight: "bold",
                  }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: "rgba(4,4,4,0.87)",
                  fontWeight: "bold",
                }}
                primary="Latency testing"
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PrecisionManufacturingIcon
                  style={{
                    color: "rgba(4,4,4,0.87)",
                    fontWeight: "bold",
                  }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: "rgba(4,4,4,0.87)",
                  fontWeight: "bold",
                }}
                primary="Stress testing"
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PrecisionManufacturingIcon
                  style={{
                    color: "rgba(4,4,4,0.87)",
                    fontWeight: "bold",
                  }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: "rgba(4,4,4,0.87)",
                  fontWeight: "bold",
                }}
                primary="Scalability testing"
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PrecisionManufacturingIcon
                  style={{
                    color: "rgba(4,4,4,0.87)",
                    fontWeight: "bold",
                  }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: "rgba(4,4,4,0.87)",
                  fontWeight: "bold",
                }}
                primary="Performance testing"
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PrecisionManufacturingIcon
                  style={{
                    color: "rgba(4,4,4,0.87)",
                    fontWeight: "bold",
                  }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: "rgba(4,4,4,0.87)",
                  fontWeight: "bold",
                }}
                primary="And any other type of testing where you need to have high load and atomic results"
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Container>
  );
};
