import { useCallback } from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { HTTP } from '../../../helper/http';


export interface Campaign {
  id: string;
  created: string;
  updated: string;
  name: string;
  ownerId: string;
  accountId: string;
  status: string;
  startedAt: string;
  startedBy: string;
  owner_email: string
  started_by_email: string
}

export interface CampaignOut {
  type: string;
  referenceId: string;
  data: Campaign | null;
}

export interface CampaignsCount {
  overall: string;
  running: string;
  finished: string;
}


export interface CampaignsState {
  status: 'idle' | 'loading' | 'failed';
  listStatus: 'idle' | 'loading' | 'failed';
  campaigns: Campaign[];
  campaign: Campaign;
  count: CampaignsCount;
  error: string;
}

export const initialState: CampaignsState = {
  status: 'idle',
  listStatus: 'idle',
  campaigns: [] as Campaign[],
  campaign: {} as Campaign,
  count: {} as CampaignsCount,
  error: "",
};

export const getCampaigns = createAsyncThunk(
  'campaign/getCampaigns',
  async (offset: Number) => {
    const response = await HTTP(
      new Request(
        `${process.env.REACT_APP_URL}/campaigns?offset=${offset}`,
        {
          method: "GET",
          redirect: "follow",
          mode: "cors",
          credentials: "include"
        }
      )
    );
    if (response.status !== 200) {
      let err = await response.json()
      if (response.status === 401 || response.status === 403) {
        err = "Please re-login"
      }
      throw err
    }
    return await response.json()
  }
);

export const getCampaignsCount = createAsyncThunk(
  'campaign/getCampaignsCount',
  async () => {
    const response = await HTTP(
      new Request(
        `${process.env.REACT_APP_URL}/campaigns/count`,
        {
          method: "GET",
          redirect: "follow",
          mode: "cors",
          credentials: "include"
        }
      )
    );
    if (response.status !== 200) {
      let err = await response.json()
      if (response.status === 401 || response.status === 403) {
        err = "Please re-login"
      }
      throw err
    }
    return await response.json()
  }
);


export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    selectCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    clearCampaigns: (state, action) => {
      state.campaigns = [] as Campaign[];
    },
    clearError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaigns.pending, (state) => {
        state.listStatus = 'loading';
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        if (action.payload) {
          state.campaigns = action.payload;
        }
        state.listStatus = 'idle';
        state.error = "";
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.listStatus = 'failed';
        state.error = `Unable to get Campaigns. Error:  ${action.error.message}`;
      })
      .addCase(getCampaignsCount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCampaignsCount.fulfilled, (state, action) => {
        if (action.payload) {
          state.count = action.payload;
        }
        state.status = 'idle';
        state.error = "";
      })
      .addCase(getCampaignsCount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Unable to get count. Error:  ${action.error.message}`;
      })
  },
});

export const { selectCampaign, clearCampaigns, clearError } = campaignSlice.actions;

export const campaignsError = (state: RootState) => state.campaign.error;
export const campaigns = (state: RootState) => state.campaign.campaigns;
export const campaignsStatus = (state: RootState) => state.campaign.status;
export const campaignsListStatus = (state: RootState) => state.campaign.listStatus;
export const count = (state: RootState) => state.campaign.count;
export const campaign = (state: RootState) => state.campaign.campaign;

export default campaignSlice.reducer;
