import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import List from "@mui/material/List";
import DeleteIcon from "@mui/icons-material/Delete";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import LoadingButton from "@mui/lab/LoadingButton";
import { create, update, createPayloadStatus } from "./slice/create";
import { selectedAccount } from "../profile/slice/profile";
import {
  setPayload,
  setName,
  selectPayloads,
  selectGlobalVariables,
  selectName,
  PayloadState,
  setWSUrl,
  selectEditId,
  setGlobalVariables,
} from "./slice/new";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ChangeOrderDialog } from "./ChangeOrderDialog";
import { ExpandCaseDialog } from "./ExpandCaseDialog";

export const MethodColor = (m: string) => {
  switch (m) {
    case "GET":
      return "#0cbb52";
    case "POST":
      return "#ffb400";
    case "PUT":
      return "#097bed";
    case "DELETE":
      return "#b10b32";
    default:
      return "#7905f5";
  }
};

const listItems = (
  data: PayloadState[],
  remove: (payload: PayloadState) => void
) => {
  if (!data?.length) {
    return (
      <Typography variant="subtitle1" align={"center"}>
        <Box
          sx={{
            marginTop: 10,
          }}
        />
        <ListAltRoundedIcon
          sx={{ fontSize: 120, color: (theme) => theme.palette.text.secondary }}
        />
        <Box
          sx={{
            fontWeight: "bold",
            margin: 4,
            color: (theme) => theme.palette.text.disabled,
          }}
        >
          Payload list is empty. Please add payload Name and payload
          configuration first.
        </Box>
      </Typography>
    );
  }

  return data?.map((e, i) => (
    <Paper
      key={i}
      elevation={2}
      sx={{
        margin: 1,
        borderRadius: 3,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: 690, 
        overflow: "auto"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          margin: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 0.9,
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
                fontSize: 10,
              }}
            >
              case:{i + 1}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 0.1,
              alignItems: "flex-end",
            }}
          >
            <ChangeOrderDialog i={i} />
            <ExpandCaseDialog payload={e} caseNumber={i} />
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                remove(e);
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        {e.httpReusable ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 0.9,
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
                fontSize: 10,
              }}
            >
              reusable connection
            </Typography>
          </Box>
        ) : (
          ""
        )}
        {e.type === "http" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 0.2,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "bold",
                  color: MethodColor(e.method),
                }}
              >
                {e.method}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flex: 0.7,
                flexGrow: 1,
              }}
            >
              <Typography variant="subtitle2" sx={{}}>
                {e.url}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              flexGrow: 1,
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 0.2,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "bold",
                  color: "#7905f5",
                }}
              >
                websocket
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flex: 0.7,
                flexGrow: 1,
              }}
            >
              <Typography variant="subtitle2" sx={{}}>
                {e.url}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: 1,
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
                fontSize: 10,
              }}
            >
              catch:
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {e.catch?.map((v) => {
              if (v?.name) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "flex-start",
                      backgroundColor: "#fafafa",
                      borderRadius: 3,
                      border: "0.5px solid",
                      borderColor: "rgba(0, 0, 0, 0.12)",
                      margin: 1,
                      padding: 1,
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.primary.dark,
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      {v?.name}
                    </Typography>
                  </Box>
                );
              }
              return "";
            })}
          </Box>
        </Box>
      </Box>
    </Paper>
  ));
};

export default function AddedPayloads() {
  const dispatch = useAppDispatch();

  const payloads = useAppSelector(selectPayloads);
  const globalVariables = useAppSelector(selectGlobalVariables);
  const name = useAppSelector(selectName);
  const currentAccount = useAppSelector(selectedAccount);
  const isLoading = useAppSelector(createPayloadStatus);
  const editId = useAppSelector(selectEditId);
  const [payloadName, setPayloadName] = useState("");

  const removePayloadFromList = (payload: PayloadState) => {
    const newPayload = payloads.filter((e) => e !== payload);
    if (!newPayload.some((e) => e.type === "websocket")) {
      dispatch(setWSUrl(""));
    }
    dispatch(setPayload(newPayload));
  };

  useEffect(() => {
    if (!payloadName && name) {
      setPayloadName(name);
    }
  }, [name]);

  const handleName = (t: string) => {
    setPayloadName(t);
    dispatch(setName(t));
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        mx: "2px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: 550,
          mx: "2px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            display: "flex",
            alignItems: "stretch",
            flexDirection: "column",
          }}
        >
          <TextField
            required
            id="campaign_name"
            variant="outlined"
            color="info"
            label="Name"
            sx={{ margin: 2 }}
            value={payloadName}
            onChange={(e) => handleName(e.target.value)}
            placeholder="User login suite"
          />
        </Box>
        <List
          sx={{
            overflow: "auto",
          }}
        >
          {listItems(payloads, removePayloadFromList)}
        </List>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 10,
          mx: "2px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          margin: 1
        }}
      >
        <LoadingButton
          color="secondary"
          variant="contained"
          loading={isLoading === "loading" ? true : false}
          disabled={payloads.length ? false : true}
          onClick={() => {
            if (editId) {
              dispatch(
                update({
                  data: payloads,
                  name: name,
                  accountId: currentAccount?.id,
                  globalVariables: globalVariables,
                  wsUrl: "",
                  editId: editId,
                })
              );
            } else {
              dispatch(
                create({
                  data: payloads,
                  name: name,
                  accountId: currentAccount?.id,
                  globalVariables: globalVariables,
                  wsUrl: "",
                  editId: "",
                })
              );
            }
            dispatch(setWSUrl(""));
            dispatch(setGlobalVariables({}));
          }}
        >
          {editId ? "Update" : "Create"} {name} Payload
        </LoadingButton>
      </Box>
    </Box>
  );
}
