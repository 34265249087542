import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { HTTP } from '../../../helper/http';

export interface NewCampaignBotState {
  regionId: string;
  regionName: string;
  amount: Number;
  vUsers: Number;
  duration: string;
  startAt?: string;
  referenceId: string;
  rampUp: string;
}

export interface NewCampaignState extends NewCampaignBotsState {
  name: string;
}

export interface NewCampaignBotsState {
  bots: NewCampaignBotState[];
  accountId: string;
}

const initialState: NewCampaignState = {
  bots: [] as NewCampaignBotState[],
  name: "",
  accountId: "",
}

export const campaignSlice = createSlice({
  name: 'newCampaign',
  initialState,
  reducers: {
    addBot: (state, action) => {
      state.bots = [...state.bots, action.payload];
    },
    removeBot: (state, action) => {
      state.bots = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setBots: (state, action) => {
      state.bots = [] as NewCampaignBotState[];
    },
  },
});

export const { addBot, removeBot, setName, setBots } = campaignSlice.actions;


export const selectBots = (state: RootState) => state.newCampaign.bots;
export const selectName = (state: RootState) => state.newCampaign.name;

export default campaignSlice.reducer;
