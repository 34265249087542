import React from 'react';
import { Main } from './features/main/Main';
import Connect  from './features/connect/Connect';
import Section  from './features/section/Section';
import Profile  from './features/profile/Profile';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

function App() {
  return (
    <React.Fragment>
      <CssBaseline/>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Connect />} />
        <Route path="/dashboard" element={<Section />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
