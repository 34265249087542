import { useState, useEffect } from "react";
import { VariableSizeList } from "react-window";
import {
  Typography,
  Skeleton,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import AutoSizer from "react-virtualized-auto-sizer";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Box from "@mui/material/Box";
import { selectedAccount, user } from "../profile/slice/profile";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { RenderRow } from "./Row";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  accountUsersStatus,
  deletedAccountUserId,
  getAccountUsers,
  addedUser,
  clearAddedUser,
  clearDeletedUser,
  AccountUser,
} from "./slice/users";

const LoadMore = (getBots: () => void, loading: boolean) => {
  return (
    <LoadingButton variant="text" loading={loading} onClick={() => getBots()}>
      Load more...
    </LoadingButton>
  );
};

const Loading = (b: boolean) => {
  if (b) {
    return (
      <TableContainer style={{ height: "calc(68vh)", marginBottom: 37  }}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
        </Box>
      </TableContainer>
    );
  }
  return (
    <TableContainer style={{ height: "calc(68vh)", marginBottom: 37 }}>
      <Box
        sx={{
          height: "100%",
          flexGrow: 1,
          mx: "2px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1" align={"center"}>
          <Box
            sx={{
              marginTop: 10,
            }}
          />
          <GroupAddIcon
            sx={{
              fontSize: 120,
              color: (theme) => theme.palette.text.secondary,
            }}
          />

          <Box
            sx={{
              fontWeight: "bold",
              margin: 4,
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            User list is empty. Please add use action button below to add new
            user.
          </Box>
        </Typography>
      </Box>
    </TableContainer>
  );
};

export default function UsersList() {
  const dispatch = useAppDispatch();

  const [usersData, setUsersData] = useState([] as AccountUser[]);
  const status = useAppSelector(accountUsersStatus);
  const currentUser = useAppSelector(user);
  const addedUserToAccount = useAppSelector(addedUser);
  const deletedAccountUserIdFromAccount = useAppSelector(deletedAccountUserId);
  const currentAccount = useAppSelector(selectedAccount);

  const loadMore = () => {
    const startFetching = async () => {
      const { payload } = await dispatch(getAccountUsers(usersData.length));
      if ((payload as AccountUser[]).length > 0) {
        setUsersData([...usersData, ...payload]);
      }
    };

    startFetching();
  };

  useEffect(() => {
    let ignore = false;

    const startFetching = async () => {
      const { payload } = await dispatch(getAccountUsers(0));
      if (!ignore) {
        if ((payload as AccountUser[]).length > 0) {
          setUsersData(payload);
        }
      }
    };

    startFetching();

    return () => {
      ignore = true;
    };
  }, [dispatch]);

  useEffect(() => {
    if (addedUserToAccount?.id) {
      setUsersData([...usersData, addedUserToAccount]);
    }
    dispatch(clearAddedUser(null));
  }, [addedUserToAccount?.id, dispatch]);

  useEffect(() => {
    if (deletedAccountUserIdFromAccount) {
      const newArr = usersData.filter(
        (v) => v?.id !== deletedAccountUserIdFromAccount
      );
      const startFetching = async () => {
        const { payload } = await dispatch(getAccountUsers(newArr.length));
        setUsersData([...newArr, ...payload as AccountUser[]]);
      };

      startFetching();
    }
    dispatch(clearDeletedUser(""));
  }, [deletedAccountUserIdFromAccount]);

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        mx: "2px",
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {usersData.length ? (
        <TableContainer style={{ height: "calc(68vh)", marginBottom: usersData.length < 10 ? 37 : 0  }}>
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                itemData={usersData}
                height={height}
                width={width}
                itemSize={() => 75}
                itemCount={usersData.length}
                overscanCount={5}
              >
                {(props) => (
                  <RenderRow
                    props={props}
                    account={currentAccount}
                    currentUser={currentUser}
                  />
                )}
              </VariableSizeList>
            )}
          </AutoSizer>
        </TableContainer>
      ) : (
        Loading(status === "loading")
      )}
      {usersData.length >= 20
        ? LoadMore(loadMore, status === "loading" ? true : false)
        : ""}
    </Box>
  );
}
