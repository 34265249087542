import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { deletedCampaignStatus } from "./slice/campaign";
import {
  deleteCampaign,
} from "./slice/campaign";
import { BootstrapDialogTitle } from "./DeleteBotsDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { Campaign } from "../campaign/slice/campaign";
import { DisableButton } from "./Dashboard";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const DeleteCampaignDialog = ({ campaign }: { campaign: Campaign }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const deletedCampaignLoading = useAppSelector(deletedCampaignStatus);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <LoadingButton
        style={{ maxHeight: "40px" }}
        variant="outlined"
        color="error"
        sx={{
          fontWeight: "bold",
        }}
        loading={deletedCampaignLoading === "loading" ? true : false}
        loadingPosition="end"
        disabled={DisableButton(
          deletedCampaignLoading === "loading" ? true : false,
          campaign?.status === "pending" ? true : false
        )}
        onClick={handleClickOpen}
      >
        delete campaign
      </LoadingButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Delete Campaign?
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Warning, all campaign records will be removed permanently!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="error"
            autoFocus
            onClick={() => dispatch(deleteCampaign(campaign?.id))}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
