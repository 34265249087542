import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { deletePayload, Payload } from "./slice/payload";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../dashboard/DeleteBotsDialog";

export const DeletePayloadDialog = ({ payload }: { payload: Payload }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} aria-label="delete" color="error">
        <DeleteIcon />
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Delete payload {payload?.name}?
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Warning, Payload <strong>{payload?.id}</strong> will be removed
            permanently!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="error"
            autoFocus
            onClick={() => {
              dispatch(deletePayload(payload?.id));
              handleClose();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
