import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Toolbar,
  Typography,
  Container,
  Stack,
} from "@mui/material";
import StarIcon from "@mui/icons-material/StarBorder";
import { Link as RouterLink } from "react-router-dom";
import Copyright from "../copyright/Copyright";
import Footer from "./Footer";
import { getUser, userError, user, clearError } from "../profile/slice/profile";
import { About } from "./About";
import { useNavigate } from "react-router-dom";
import { Feature } from "./Feature";
import { ContactSales } from "../contact/SalesDialog";
import CookieConsent from "react-cookie-consent";
import { PrivacyDialog } from "../policy/PrivacyDialog";

const tiers = [
  {
    title: "Developer",
    price: "79",
    description: [
      "Up to 200 virtual users",
      "Up to 2 concurrent campaigns",
      "Up to 2 bots",
      "15 minutes max duration",
      "Up to 100 virtual users per bot",
      "2 region",
      "2 TB outgoing traffic per month included (additional traffic $0.02 GB)",
      "Unlimited team members",
    ],
    buttonText: "Contact sales",
    buttonVariant: "outlined",
  },
  {
    title: "Pro",
    subheader: "Most popular",
    price: "395",
    description: [
      "Up to 1000 virtual users",
      "Up to 5 concurrent campaigns",
      "Up to 5 bots",
      "Up to 200 virtual users per bot",
      "IPv6 support",
      "30 minutes max duration",
      "4 regions",
      "Test Scheduler",
      "WebSocket support (beta testing)",
      "5 TB outgoing traffic per month included (additional traffic $0.02 GB)",
      "Unlimited team members",
    ],
    buttonText: "Contact sales",
    buttonVariant: "contained",
  },
  {
    title: "Premium",
    price: "1185",
    description: [
      "Up to 3000 virtual users",
      "Up to 10 concurrent campaigns",
      "Up to 15 bots",
      "Up to 200 virtual users per bot",
      "IPv6 support",
      "60 minutes max duration",
      "8 regions",
      "Test Scheduler",
      "WebSocket support (beta testing)",
      "15 TB outgoing traffic per month included (additional traffic $0.02 GB)",
      "Unlimited team members",
    ],
    buttonText: "Contact sales",
    buttonVariant: "outlined",
  },
  {
    title: "Enterprise",
    price: "-",
    description: [
      "Custom virtual users amount",
      "Custom campaigns amount",
      "Custom amount of bots",
      "Up to 200 virtual users per bot",
      "IPv6 support",
      "60 minutes max duration",
      "20+ campaign concurrently",
      "12+ regions",
      "Test Scheduler",
      "WebSocket support (beta testing)",
      "Custom traffic",
      "Unlimited team members",
    ],
    buttonText: "Contact sales",
    buttonVariant: "outlined",
  },
];

export function Main() {
  const dispatch = useAppDispatch();
  const aboutRef = useRef<HTMLDivElement>(null);
  const featureRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const userData = useAppSelector(user);
  const [init, setInit] = useState(true);
  const uError = useAppSelector(userError);

  const executeScrollToAbout = () => aboutRef?.current?.scrollIntoView();
  const executeScrollToFeature = () => featureRef?.current?.scrollIntoView();
  const executeScrollToPricing = () => pricingRef?.current?.scrollIntoView();

  useEffect(() => {
    if (init) {
      dispatch(getUser());
    }
    setInit(false);
  }, [init, dispatch]);

  useEffect(() => {
    if (userData?.id) {
      navigate("/dashboard");
    }
  }, [userData?.id, dispatch]);

  useEffect(() => {
    if (uError) {
      dispatch(clearError(""));
    }
  }, [uError, dispatch]);

  return (
    <Container maxWidth="xl">
      <React.Fragment>
        <AppBar
          elevation={0}
          position="absolute"
          sx={{
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: "#fafafa",
          }}
        >
          <Toolbar sx={{ flexWrap: "wrap" }}>
            <Typography noWrap sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  fontWeight: "bold",
                  m: 1,
                  letterSpacing: 3,
                  fontSize: 30,
                  color: "black",
                }}
              >
                Boty
              </Box>
            </Typography>
            <nav>
              <Button
                onClick={executeScrollToAbout}
                sx={{
                  my: 1,
                  mx: 1.5,
                  fontWeight: "bold",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                About
              </Button>
              <Button
                onClick={executeScrollToFeature}
                sx={{
                  my: 1,
                  mx: 1.5,
                  fontWeight: "bold",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Features
              </Button>
              <Button
                onClick={executeScrollToPricing}
                sx={{
                  my: 1,
                  mx: 1.5,
                  fontWeight: "bold",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Pricing
              </Button>
            </nav>
            <Button
              to={userData.id ? "/dashboard" : "/login"}
              component={RouterLink}
              variant="outlined"
              sx={{ my: 1, mx: 1.5, fontWeight: "bold" }}
            >
              Connect
            </Button>
          </Toolbar>
        </AppBar>
        {About(aboutRef)}
        {Feature(featureRef)}
        <Container
          ref={pricingRef}
          maxWidth="lg"
          component="main"
          sx={{ pt: 18, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
            id="pricing"
          >
            Pricing
          </Typography>
        </Container>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={3}
              sm={tier.title === "Enterprise" ? 6 : 3}
              md={3}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                    color: "#fff",
                  }}
                  sx={{
                    color: "#fff",
                    backgroundColor: (theme) =>
                      tier.subheader
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main,
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    {tier.title === "Enterprise" ? (
                      <Stack>
                        <Typography
                          component="h2"
                          variant="h5"
                          color="text.primary"
                        >
                          Custom price
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          component="h2"
                          variant="h3"
                          color="text.primary"
                        >
                          ${tier.price}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "baseline",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography variant="h6" color="text.secondary">
                            /mo
                          </Typography>
                        </Box>
                      </Stack>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle1" color="green">
                      Save 15% with an annual plan
                    </Typography>
                  </Box>

                  {tier.description.map((line) => (
                    <Typography variant="subtitle1" align="left" key={line}>
                      {line}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Box
                    sx={{ display: "flex", flex: 1, flexDirection: "column" }}
                  >
                    <ContactSales
                      title={"Contact sales"}
                      location={"pricing"}
                      text={tier.buttonText}
                      btnVariant={
                        tier.buttonVariant as
                          | "outlined"
                          | "contained"
                          | "text"
                          | undefined
                      }
                    />
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Footer />
        <Copyright sx={{ mt: 5 }} />
        <CookieConsent
          location="bottom"
          buttonText="ACCEPT"
          cookieName="cookieAccept"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          We use cookies to ensure you the best experience. By using our website
          you agree to our Cookie Policy (please check <PrivacyDialog color={"#fff"} />  for more information )
        </CookieConsent>
      </React.Fragment>
    </Container>
  );
}
