import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';

export interface SectionState {
  step: string;
}

const initialState: SectionState = {
    step: "campaigns",
};

export const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    switchStep: (state, action) => {
      state.step = action.payload;
    },
  },
});

export const { switchStep } = sectionSlice.actions;

export const selectedStep = (state: RootState) => state.section.step;

export default sectionSlice.reducer;
