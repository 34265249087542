import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { HTTP } from '../../../helper/http';

export interface Stat {
    campaignId: string;
    data: Data[];
    time: number;
}

export interface Rps {
    campaignId: string;
    avgRt: string;
    avgRps: string;
    errors: string;
    requests: string;
}

export interface Data {
    requestTime: string;
    requests: Number;
    errors: Number;
    httpErrors: Number;
    httpCodeErrors: Number;
    wsMsgTimeoutErrors: Number;
    missingMsgErrors: Number;
}

export interface CampaignStat {
    statsStatus: 'idle' | 'loading' | 'failed';
    stat: Stat;
    rps: Rps;
    statsError: string;
}

const initialState: CampaignStat = {
    statsStatus: 'idle',
    stat: {} as Stat,
    rps: {} as Rps,
    statsError: "",
};

export const getStats = createAsyncThunk(
    'dashboard/getStats',
    async (payload: { id: string; from?: number; to?: number }) => {
        const response = await HTTP(
            new Request(
                payload?.to ? 
                `${process.env.REACT_APP_URL}/campaign/${payload.id}/stats?from=${payload.from}&to=${payload.to}` : 
                `${process.env.REACT_APP_URL}/campaign/${payload.id}/stats?from=${payload.from}`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);

export const getRPSStats = createAsyncThunk(
    'dashboard/getRpsStats',
    async (payload: { id: string }) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/${payload.id}/rps`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);


export const statsSlice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
        clearError: (state, action) => {
            state.statsError = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStats.pending, (state) => {
                state.statsStatus = 'loading';
            })
            .addCase(getStats.fulfilled, (state, action) => {
                if (action.payload) {
                    state.stat = action.payload;
                }
                state.statsStatus = 'idle';
                state.statsError = "";
            })
            .addCase(getStats.rejected, (state, action) => {
                state.statsStatus = 'failed';
                state.statsError = `Unable to get campaign. Error:  ${action.error.message}`;
            })
            .addCase(getRPSStats.pending, (state) => {
                state.statsStatus = 'loading';
            })
            .addCase(getRPSStats.fulfilled, (state, action) => {
                if (action.payload) {
                    state.rps = action.payload;
                }
                state.statsStatus = 'idle';
                state.statsError = "";
            })
            .addCase(getRPSStats.rejected, (state, action) => {
                state.statsStatus = 'failed';
                state.statsError = `Unable to get campaign. Error:  ${action.error.message}`;
            })
    },
});

export const { clearError } = statsSlice.actions;

export const statsError = (state: RootState) => state.stats.statsError;
export const stats = (state: RootState) => state.stats.stat;
export const rps = (state: RootState) => state.stats.rps;
export const statsStatus = (state: RootState) => state.stats.statsStatus;


export default statsSlice.reducer;