import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Snackbar, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import ChartSection from "./ChartSection";
import { BotSection } from "./BotSection";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { campaign } from "../campaign/slice/campaign";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { switchStep } from "../section/slice/section";
import {
  deleteCampaignError,
  deletedCampaign,
  clearError as clearDeleteError,
} from "./slice/campaign";
import { getRPSStats, getStats } from "./slice/stats";
import { clearCampaigns } from "../campaign/slice/campaign";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import LoadingButton from "@mui/lab/LoadingButton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import {
  launchCampaignError,
  launchedCampaign,
  launchCampaign,
  clearLaunchedCampaign,
  launchCampaignStatus,
  clearError as clearLaunchCampaignError,
} from "./slice/launch";
import {
  statsError,
  stats,
  clearError as clearStatsError,
} from "./slice/stats";
import { DeleteCampaignDialog } from "./DeleteCampaignDialog";
import { TestMsgDialog } from "./TestDialog";
import {
  createCampaignResult,
  createCampaignError,
  clearError,
} from "../campaign/slice/create";
import { botsError, clearError as clearBotsError } from "./slice/bots";

export const DisableButton = (launched: boolean, pending: boolean) => {
  if (launched || pending) {
    return true;
  }
  return false;
};

export default function Dashboard() {
  const dispatch = useAppDispatch();

  const sectionHandler = (event: string) => {
    const selectedName = event;
    dispatch(switchStep(selectedName));
  };
  const [init, setInit] = useState(false);
  const launchLoading = useAppSelector(launchCampaignStatus);
  const dcError = useAppSelector(deleteCampaignError);
  const lcError = useAppSelector(launchCampaignError);
  const sError = useAppSelector(statsError);
  const bError = useAppSelector(botsError);
  //   const aError = useAppSelector(accountError);
  const dc = useAppSelector(deletedCampaign);
  const [errorOpen, setErrorOpen] = useState(false);
  const campaignData = useAppSelector(campaign);
  const [currentCampaign, setCampaign] = useState(campaignData);
  const campaignState = useAppSelector(launchedCampaign);
  const stat = useAppSelector(stats);
  const resultOnCreate = useAppSelector(createCampaignResult);
  const ccError = useAppSelector(createCampaignError);

  useEffect(() => {
    if (resultOnCreate?.referenceId) {
      if (
        resultOnCreate?.data?.id === currentCampaign.id &&
        currentCampaign?.status === "pending"
      ) {
        setCampaign((currentCampaign) => {
          const newCampaign = { ...currentCampaign };
          newCampaign.status = "created";
          return newCampaign;
        });
      }
      if (
        resultOnCreate?.data?.id === currentCampaign.id &&
        currentCampaign?.status !== "pending"
      ) {
        setCampaign((currentCampaign) => {
          const newCampaign = { ...currentCampaign };
          newCampaign.status = "pending";
          return newCampaign;
        });
      }
    }
  }, [dispatch, resultOnCreate?.referenceId]);

  useEffect(() => {
    if (currentCampaign?.status !== "running" && !init) {
      dispatch(
        getStats({
          id: currentCampaign?.id,
          from: currentCampaign?.startedAt
            ? new Date(currentCampaign?.startedAt).getTime()
            : 0,
        })
      );
    }
    setInit(true);
  }, [dispatch, currentCampaign?.status, init]);

  useEffect(() => {
    if (dc) {
      dispatch(clearCampaigns(""));
      dispatch(switchStep("campaigns"));
    }
  }, [dc, dispatch]);

  useEffect(() => {
    if (dcError || lcError || sError || ccError || bError) {
      setErrorOpen(true);
    }
  }, [dcError, lcError, sError, ccError, bError]);

  useEffect(() => {
    if (campaignState?.status && currentCampaign?.id === campaignState?.id) {
      setCampaign((currentCampaign) => {
        const newCampaign = { ...currentCampaign };
        newCampaign.status = campaignState?.status;
        newCampaign.startedBy = campaignState?.startedBy;
        newCampaign.startedAt = campaignState?.startedAt;
        return newCampaign;
      });
      dispatch(clearLaunchedCampaign(""));
    }
  }, [dispatch, campaignState?.status, campaignState?.id]);

  useEffect(() => {
    if (currentCampaign?.status === "running" && stat?.data?.length) {
      setTimeout(() => {
        dispatch(
          getStats({
            id: currentCampaign?.id,
            from: currentCampaign?.startedAt
              ? new Date(currentCampaign?.startedAt).getTime()
              : 0,
          })
        );
      }, 10000);
    }
  }, [dispatch, currentCampaign?.status, stat?.data?.length]);

  useEffect(() => {
    if (currentCampaign?.status === "running" && stat?.data?.length) {
      setTimeout(() => {
        dispatch(
          getRPSStats({
            id: currentCampaign?.id,
          })
        );
      }, 5000);
    }
  }, [dispatch, currentCampaign?.status, stat?.data?.length]);

  const handleErrorClose = () => {
    setErrorOpen(false);
    dispatch(clearLaunchCampaignError(""));
    dispatch(clearError(0));
    dispatch(clearDeleteError(""));
    dispatch(clearStatsError(""));
    dispatch(clearBotsError(""));
  };

  return (
    <Box sx={{ flexGrow: 1, marginTop: 7 }}>
      <Grid item xs={12}>
        <Typography>
          <Box
            sx={{
              color: (theme) => theme.palette.text.disabled,
              fontWeight: "bold",
            }}
          >
            {currentCampaign.name}
          </Box>
        </Typography>
      </Grid>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => handleErrorClose()}
      >
        <Alert
          onClose={() => handleErrorClose()}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {dcError || lcError || sError || ccError || bError}
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ marginTop: 5 }}
      >
        <Grid item display="flex" flex="0.1" xs={12}>
          <Grid item display="flex" xs={12}>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => sectionHandler("campaigns")}
                style={{ maxHeight: "40px" }}
                variant="outlined"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
                startIcon={<ArrowBackIcon />}
              >
                campaigns
              </Button>
            </Stack>
          </Grid>
          <Grid item display="flex" justifyContent="flex-end" xs={12}>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                variant="outlined"
                color="secondary"
                style={{ maxHeight: "40px" }}
                sx={{ fontWeight: "bold" }}
                loading={launchLoading === "loading" ? true : false}
                loadingPosition="end"
                disabled={DisableButton(
                  launchLoading === "loading" ? true : false,
                  currentCampaign?.status === "pending" ? true : false
                )}
                onClick={() =>
                  dispatch(
                    launchCampaign({
                      id: currentCampaign?.id,
                      start:
                        currentCampaign?.status === "running" ? false : true,
                    })
                  )
                }
              >
                {currentCampaign?.status === "running"
                  ? "stop campaign"
                  : "run campaign"}
              </LoadingButton>
              <DeleteCampaignDialog campaign={currentCampaign} />
            </Stack>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <Box
            sx={{
              display: "flex",
              textTransform: "uppercase",
              textAlign: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: () => {
                  switch (currentCampaign?.status) {
                    case "created":
                      return "#4078b7";
                    case "running":
                      return "#4a9c07";
                    case "pending":
                      return "rgba(2,2,2,0.38)";
                    default:
                      return "#d32f2f";
                  }
                },
              }}
            >
              <FiberManualRecordOutlinedIcon sx={{ fontSize: 14 }} />
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: () => {
                  switch (currentCampaign?.status) {
                    case "created":
                      return "#4078b7";
                    case "running":
                      return "#4a9c07";
                    case "pending":
                      return "rgba(2,2,2,0.38)";
                    default:
                      return "#d32f2f";
                  }
                },
              }}
            >
              {currentCampaign?.status}
            </Typography>
          </Box>
          <Box
            sx={{
              marginLeft: 2,
              display: "flex",
              textAlign: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              <Person2RoundedIcon sx={{ fontSize: 14 }} />
            </Typography>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
                fontSize: 15,
              }}
            >
              {currentCampaign?.status === "created"
                ? `Created by: ${currentCampaign?.started_by_email}`
                : `Started by: ${currentCampaign?.started_by_email}`}
            </Typography>
          </Box>
          <Box
            sx={{
              marginLeft: 2,
              display: "flex",
              textAlign: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              <AccessTimeIcon sx={{ fontSize: 14 }} />
            </Typography>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
                fontSize: 15,
              }}
            >
              Started at:{" "}
              {currentCampaign?.startedAt
                ? new Date(currentCampaign?.startedAt).toLocaleString()
                : "N/A"}
            </Typography>
          </Box>
        </Box>
        <Grid item display="flex" flex="1" justifyContent="center" xs={12}>
          <ChartSection />
        </Grid>
        <Grid item display="flex" flex="1" justifyContent="center" xs={12}>
          <BotSection campaignData={currentCampaign} />
        </Grid>
        <TestMsgDialog />
      </Grid>
    </Box>
  );
}
