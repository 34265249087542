import { useState } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectCurrentPayload,
  setCurrentPayload,
} from "./slice/new";

export const DelayTab = () => {
  const dispatch = useAppDispatch();
  const currentPayload = useAppSelector(selectCurrentPayload);
  const [radioValue, setRadio] = useState("custom");

  const handleRadioChange = (v: string) => {
    setRadio(v);
    if (v === "random") {
      updatePayload("random");
    } else {
      updatePayload("0");
    }
  };

  const updatePayload = (v: string) => {
    const newPayload = JSON.parse(JSON.stringify(currentPayload));
    newPayload.delay = (v === "random" ? "random" : `${v}s`);
    dispatch(setCurrentPayload(newPayload));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <FormLabel id="delay-radio-buttons-group-label">Delay time</FormLabel>
      <RadioGroup
        aria-labelledby="delay-radio-buttons-group-label"
        defaultValue="custom"
        name="radio-buttons-group"
        row
        value={radioValue}
        onChange={(e) => handleRadioChange(e.target.value)}
      >
        <FormControlLabel value="random" control={<Radio />} label="Random" />
        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
      </RadioGroup>
      {radioValue === "random" ? (
        <Typography
          sx={{ display: "flex", flex: 1, justifyContent: "center",alignItems: "center" }}
          variant="body2"
          align={"center"}
        >
          <Box
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            Random values from 1 to 10 seconds will be used{" "}
          </Box>
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignContent: "center",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Typography
            sx={{ display: "flex", flex: 1 }}
            variant="body2"
            align={"center"}
          >
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              Delay before the request
            </Box>
          </Typography>
          <TextField
            sx={{ display: "flex", flex: 0.5, justifyContent: "flex-end" }}
            size="small"
            id="value"
            variant="outlined"
            label="Time(seconds)"
            color="info"
            placeholder="1"
            onChange={(e) => updatePayload(`${e.target.value}`)}
          />
        </Box>
      )}
    </Box>
  );
};
