import React, { useState, useEffect, ReactChild, ReactElement } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  addPayload,
  PayloadCatchState,
  PayloadState,
  selectCurrentPayload,
  setCurrentPayload,
} from "./slice/new";
import { RenderHeaders } from "./RenderHeader";

export const BodyTab = () => {
  const dispatch = useAppDispatch();
  const currentPayload = useAppSelector(selectCurrentPayload);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        maxHeight: 350,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <TextField
          fullWidth
          id="datetime-local"
          variant="outlined"
          value={currentPayload?.data}
          multiline
          placeholder="{'password':'123'}"
          onChange={(e) => {
            const newPayload = JSON.parse(JSON.stringify(currentPayload));
            newPayload!.data = e.target.value;
            dispatch(setCurrentPayload(newPayload));
          }}
        />
      </Box>
    </Box>
  );
};
