import { useCallback } from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { auth } from '../firebase/init';
import {
    EmailAuthProvider,
    GoogleAuthProvider,
    reauthenticateWithCredential,
    linkWithPopup,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    updatePassword,
    updateEmail,
    UserCredential,
} from "firebase/auth";
import { HTTP } from '../../../helper/http';

export interface User {
    email: string;
    UUID: string;
}

export interface ConnectState {
    isLoggedIn: boolean;
    isLoggedOut: boolean;
    isCredentialsChange: boolean;
    status: 'idle' | 'loading' | 'failed';
    user: User | {};
    error: string;
}

const initialState: ConnectState = {
    isLoggedIn: false,
    isLoggedOut: false,
    isCredentialsChange: false,
    status: 'idle',
    user: {},
    error: "",
};

const logout = async () => {
    const response = await HTTP(
        new Request(
            `${process.env.REACT_APP_URL}/logout`,
            {
                method: "POST",
                redirect: "follow",
                mode: "cors",
                credentials: "include",
            }
        )
    );
    return await response
}


export const firebaseSignout = createAsyncThunk(
    'connect/firebaseSignout',
    async () => {
        const user = async () => {

            try {
                const user = {}
                await auth.signOut()

                await logout()
                return user
            } catch (err) {
                // error handling
                console.error(err)
                throw err

            }
        }

        return user()
    }
);

const login = async (jwt: string) => {
    const response = await HTTP(
        new Request(
            `${process.env.REACT_APP_URL}/sso`,
            {
                method: "POST",
                redirect: "follow",
                mode: "cors",
                credentials: "include",
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            }
        )
    );
    return await response.json()
}

const reauthenticate = (currentPassword: string) => {
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(user?.email ?? "", currentPassword);
    if (!user) {
        throw "Unauthorized"
    }
    return reauthenticateWithCredential(user, cred);
}


export const googleSignUpAsync = createAsyncThunk(
    'connect/googleSignUp',
    async () => {
        const user = async () => {
            const provider = new GoogleAuthProvider()

            try {
                const currentUser = await auth.currentUser

                if (currentUser) {
                    try {
                        const res = await linkWithPopup(currentUser, provider)

                        const idToken = await res.user?.getIdToken()
                        if (!idToken) {
                            console.warn('[Firebase error]: idToken is not provided')
                            return
                        }

                        const user = await login(idToken)

                        return user
                    } catch (error: any) {
                        if (error.toString().includes("auth/credential-already-in-use")) {

                            const idToken = await currentUser.getIdToken()
                            if (!idToken) {
                                console.warn('[Firebase error]: idToken is not provided')
                                return
                            }

                            const user = await login(idToken)
                            return user
                        }

                    }

                } else {
                    const res = await signInWithPopup(auth, provider)

                    const idToken = await res.user?.getIdToken()
                    if (!idToken) {
                        console.warn('[Firebase error]: idToken is not provided')
                        return
                    }

                    const user = await login(idToken)
                    return user
                }
            } catch (err) {
                throw err
            }
        }

        return await user()
    }
);

export const firebaseRegistration = createAsyncThunk(
    'connect/fbRegistration',
    async (creds: any) => {

        const user = async (email: string, password: string) => {

            try {

                const res = await createUserWithEmailAndPassword(auth, email, password)

                const idToken = await res.user?.getIdToken()
                if (!idToken) {
                    console.warn('Unable to register')
                    return
                }

                if (res.user) {
                    const user = await login(idToken)
                    return user
                }
            } catch (err) {
                // error handling
                console.error(err)
                throw err
            }
        }

        return user(creds.email, creds.password)
    }
);

export const firebaseLogin = createAsyncThunk(
    'connect/fbLogin',
    async (creds: any) => {

        const user = async (email: string, password: string) => {

            try {

                const res = await signInWithEmailAndPassword(auth, email, password)

                const idToken = await res.user?.getIdToken()
                if (!idToken) {
                    console.warn('Unable to register')
                    return
                }

                if (res.user) {
                    const user = await login(idToken)
                    return user
                }
            } catch (err) {
                // error handling
                console.error(err)
                throw err
            }
        }

        return user(creds.email, creds.password)
    }
);

export const firebaseForgotPassword = createAsyncThunk(
    'connect/fbForgotPassword',
    async (email: string) => {

        try {
            await sendPasswordResetEmail(auth, email)
        } catch (err) {
            console.error(err)
            throw err
        }
    }
);

export const firebaseUpdatePassword = createAsyncThunk(
    'connect/fbResetPassword',
    async (p: { old: string, new: string }) => {

        try {
            await reauthenticate(p.old)
            const currentUser = await auth.currentUser
            if (!currentUser) {
                throw "Unauthorized"
            }
            await updatePassword(currentUser, p.new)
        } catch (err) {
            console.error(err)
            throw err
        }
    }
);

export const firebaseUpdateEmail = createAsyncThunk(
    'connect/fbUpdatePassword',
    async (p: { pass: string, email: string }) => {

        try {
            await reauthenticate(p.pass)
            const currentUser = await auth.currentUser
            if (!currentUser) {
                throw "Unauthorized"
            }
            await updateEmail(currentUser, p.email)
        } catch (err) {
            console.error(err)
            throw err
        }
    }
);


export const connectSlice = createSlice({
    name: 'connect',
    initialState,
    reducers: {
        clearError: (state, action) => {
            state.error = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(googleSignUpAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(googleSignUpAsync.fulfilled, (state, action) => {
                if (action.payload.email) {
                    state.isLoggedIn = true;
                    state.isLoggedOut = false;
                    state.user = action.payload;
                }
                state.status = 'idle';
                state.error = "";
            })
            .addCase(googleSignUpAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to login. Error:  ${action.error.message}`;
            })
            .addCase(firebaseRegistration.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(firebaseRegistration.fulfilled, (state, action) => {
                if (action.payload.email) {
                    state.isLoggedIn = true;
                    state.isLoggedOut = false;
                    state.user = action.payload;
                }
                state.status = 'idle';
                state.error = "";
            })
            .addCase(firebaseRegistration.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to register. Error:  ${action.error.message}`;
            })
            .addCase(firebaseLogin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(firebaseLogin.fulfilled, (state, action) => {
                if (action.payload.email) {
                    state.isLoggedIn = true;
                    state.isLoggedOut = false;
                    state.isCredentialsChange = false;
                    state.user = action.payload;
                }
                state.status = 'idle';
                state.error = "";
            })
            .addCase(firebaseLogin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to Login. Error:  ${action.error.message}`;
            })
            .addCase(firebaseSignout.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(firebaseSignout.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.isLoggedOut = true;
                state.status = 'idle';
                state.user = {};
                state.error = "";
            })
            .addCase(firebaseSignout.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to logout. Error:  ${action.error.message}`;
            })
            .addCase(firebaseForgotPassword.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(firebaseForgotPassword.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.status = 'idle';
                state.error = "";
            })
            .addCase(firebaseForgotPassword.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to logout. Error:  ${action.error.message}`;
            })
            .addCase(firebaseUpdatePassword.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(firebaseUpdatePassword.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.isCredentialsChange = true;
                state.status = 'idle';
                state.error = "";
            })
            .addCase(firebaseUpdatePassword.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to logout. Error:  ${action.error.message}`;
            })
            .addCase(firebaseUpdateEmail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(firebaseUpdateEmail.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.isCredentialsChange = true;
                state.status = 'idle';
                state.error = "";
            })
            .addCase(firebaseUpdateEmail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to logout. Error:  ${action.error.message}`;
            });
    },
});

export const { clearError } = connectSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.connect.isLoggedIn;
export const selectIsLoggedOut = (state: RootState) => state.connect.isLoggedOut;
export const selectIsCredentialsChanged = (state: RootState) => state.connect.isCredentialsChange;
export const connectError = (state: RootState) => state.connect.error;
export const signedUsed = (state: RootState) => state.connect.user;

export default connectSlice.reducer;
