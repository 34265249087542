import React, { useState, useEffect } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { Button, Typography, Snackbar, Stack, IconButton } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import AutoSizer from "react-virtualized-auto-sizer";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { switchStep } from "../section/slice/section";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import InfiniteLoader from "react-window-infinite-loader";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteUserDialog } from "./DeleteUserDialog";
import { Account, User } from "../profile/slice/profile";
import { AccountUser } from "./slice/users";

const AccountHandler = ({
  user,
  account,
  setDeleteUserDialog,
  currentUser,
}: {
  user: AccountUser;
  account: Account;
  setDeleteUserDialog: (b: boolean) => void;
  currentUser: User;
}) => {
  if (currentUser?.id === account.ownerId && user.userId === account.ownerId) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          flex: 1,
        }}
      >
        <Typography variant="subtitle2" align={"center"}>
          <Box
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.success.main,
            }}
          >
            owner
          </Box>
          <Box
            sx={{
              color: "#bdbdbd",
              fontWeight: "bold",
              fontSize: 10,
            }}
          >
            current user is owner of the account
          </Box>
        </Typography>
      </Box>
    );
  }

  if (currentUser?.id !== account.ownerId) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          flex: 1,
        }}
      >
        <Typography variant="subtitle2" align={"center"}>
          <Box
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {account.owner_email}
          </Box>
          <Box
            sx={{
              color: "#bdbdbd",
              fontWeight: "bold",
              fontSize: 10,
            }}
          >
            is owner of the account
          </Box>
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
        flex: 1,
      }}
      direction="row"
      spacing={2}
    >
      <Button
        onClick={() => setDeleteUserDialog(true)}
        style={{ height: "30px", width: "120px" }}
        variant="outlined"
        color="error"
        sx={{
          fontWeight: "bold",
          margin: 1,
        }}
        endIcon={<DeleteIcon />}
      >
        delete
      </Button>
    </Stack>
  );
};

export const RenderRow = ({
  props,
  account,
  currentUser,
}: {
  props: ListChildComponentProps;
  account: Account;
  currentUser: User;
}) => {
  const { data, index, style } = props;

  const e = data[index];

  const [deleteUserDialog, setDeleteUserDialog] = useState(false);

  const handleClose = () => {
    setDeleteUserDialog(false);
  };

  return (
    <Box key={index} style={{ ...style }}>
      <Box
        sx={{
          borderRadius: 3,
          border: "0.5px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          maxHeight: 70,
          padding: 1.8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
            flex: 1,
          }}
        >
          <Typography variant="h5" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e.email}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              ID: {e.id}
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
            flex: 1,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {new Date(e.created).toLocaleString()}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              added
            </Box>
          </Typography>
        </Box>
        <AccountHandler
          account={account}
          user={e}
          setDeleteUserDialog={setDeleteUserDialog}
          currentUser={currentUser}
        />
        <DeleteUserDialog
          opened={deleteUserDialog}
          handleClose={handleClose}
          user={e}
          account={account}
        />
      </Box>
    </Box>
  );
};
