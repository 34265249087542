import { useCallback } from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { HTTP } from '../../../helper/http';


export interface Payload {
  id: string;
  created: string;
  updated: string;
  name: string;
  accountId: string;
  data: string
}

export interface BotPayload {
  botId: string;
  data: Payload[];
}

export interface PayloadsState {
  status: 'idle' | 'loading' | 'failed';
  payloads: Payload[];
  deletedPayload: string;
  error: string;
}

const initialState: PayloadsState = {
  status: 'idle',
  payloads: [] as Payload[],
  deletedPayload: "",
  error: "",
};

export const getPayloads = createAsyncThunk(
  'payload/getPayloads',
  async (offset: Number) => {
    const response = await HTTP(
      new Request(
        `${process.env.REACT_APP_URL}/payloads?offset=${offset}`,
        {
          method: "GET",
          redirect: "follow",
          mode: "cors",
          credentials: "include"
        }
      )
    );
    if (response.status !== 200) {
      let err = await response.json()
      if (response.status === 401 || response.status === 403) {
        err = "Please re-login"
      }
      throw err
    }
    return await response.json()
  }
);

export const deletePayload = createAsyncThunk(
  'payload/deletePayload',
  async (id: string) => {
    const response = await HTTP(
      new Request(
        `${process.env.REACT_APP_URL}/payload/${id}`,
        {
          method: "DELETE",
          redirect: "follow",
          mode: "cors",
          credentials: "include"
        }
      )
    );
    if (response.status !== 204) {
      let err = await response.json()
      if (response.status === 401 || response.status === 403) {
        err = "Please re-login"
      }
      throw err
    }
    return id
  }
);

export const payloadSlice = createSlice({
  name: 'payload',
  initialState,
  reducers: {
    clearPayloads: (state, action) => {
      state.payloads = [] as Payload[];
    },
    clearError: (state, action) => {
      state.error = action.payload;
    },
    clearDeletedPayload: (state, action) => {
      state.deletedPayload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayloads.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPayloads.fulfilled, (state, action) => {
        if (action.payload) {
          state.payloads = action.payload;
        }
        state.status = 'idle';
        state.error = "";
      })
      .addCase(getPayloads.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Unable to get payloads. Error:  ${action.error.message}`;
      })
      .addCase(deletePayload.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePayload.fulfilled, (state, action) => {
        state.deletedPayload = action.payload;
        state.status = 'idle';
        state.error = "";
      })
      .addCase(deletePayload.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Unable to get payloads. Error:  ${action.error.message}`;
      })
  },
});

export const { clearPayloads, clearError, clearDeletedPayload } = payloadSlice.actions;

export const payloadsError = (state: RootState) => state.payload.error;
export const payloads = (state: RootState) => state.payload.payloads;
export const payloadStatus = (state: RootState) => state.payload.status;
export const deletedPayload = (state: RootState) => state.payload.deletedPayload;

export default payloadSlice.reducer;
