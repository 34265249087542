import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography, Snackbar, Alert, Badge } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { FormControl, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RenderTab } from "./RenderTab";
import { MethodSelector } from "./MethodSelector";
import { switchStep } from "../section/slice/section";
import {
  getAccounts,
  accounts,
  selectedAccount,
} from "../profile/slice/profile";
import AddedPayloads from "./AddedPayloads";
import {
  addPayload,
  PayloadCatchState,
  selectCurrentPayload,
  setCurrentPayload,
  setName,
  initialState,
  setPayload,
  selectWsUrl,
  setWSUrl,
  selectEditId,
  selectName,
  InitPayloadState,
} from "./slice/new";
import {
  createPayloadError,
  clearError,
  selectCreatePayloadResult,
  clearCreatedPayload,
} from "./slice/create";
import { HowToDialog } from "./HowToDialog";
import { VariableseDialog } from "./VariablesDialog";

export default function PayloadCard() {
  const dispatch = useAppDispatch();
  const [rType, setRequestType] = useState("http");
  const [mType, setMethodType] = useState("GET");
  const [HTTPReusable, setHTTPReusable] = useState(true);
  const [url, setUrl] = useState("");
  const [tab, setTab] = useState("headers");
  const [payloadName, setPayloadName] = useState("");
  const accountsData = useAppSelector(accounts);
  const currentAccount = useAppSelector(selectedAccount);
  const currentPayload = useAppSelector(selectCurrentPayload);
  const editId = useAppSelector(selectEditId);
  const lastCreated = useAppSelector(selectCreatePayloadResult);
  const name = useAppSelector(selectName);
  const wsUrl = useAppSelector(selectWsUrl);
  const payloadError = useAppSelector(createPayloadError);
  const [errorOpen, setErrorOpen] = useState(false);
  const [createPayloadErrorInternal, setCreatePayloadErrorInternal] =
    useState("");

  const sectionHandler = (event: string) => {
    const selectedName = event;
    dispatch(switchStep(selectedName));
  };

  const accountHandler = () => {
    const acc = accountsData.filter((v) => v.id === currentAccount?.id)[0];
    if (acc?.type === "developer") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!accountsData.length) {
      dispatch(getAccounts());
    }
  }, [dispatch, accountsData.length]);

  useEffect(() => {
    if (!payloadName && name) {
      setPayloadName(name);
    }
  }, [name]);

  useEffect(() => {
    if (payloadError) {
      setCreatePayloadErrorInternal(payloadError);
      setErrorOpen(true);
    }
  }, [payloadError]);

  useEffect(() => {
    if (!payloadError && lastCreated?.referenceId) {
      dispatch(clearCreatedPayload(null));
      dispatch(setName(""));
      dispatch(setCurrentPayload(initialState.currentPayload));
      dispatch(setPayload(initialState.data));
      dispatch(switchStep("payloads"));
    }
  }, [dispatch, payloadError, lastCreated?.referenceId]);

  const handleRequestType = (t: string) => {
    setRequestType(t);
    if (t === "websocket") {
      if (wsUrl) {
        setUrl(wsUrl.trim());
      }
    } else {
      setUrl("");
    }
  };

  const handleMethod = (t: string) => {
    setMethodType(t);
  };


  const handleUrl = (t: string) => {
    setUrl(t.trim());
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
    setCreatePayloadErrorInternal("");
    dispatch(clearError(0));
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: 7,
      }}
    >
      <Typography>
        <Box
          sx={{
            color: (theme) => theme.palette.text.disabled,
            fontWeight: "bold",
          }}
        >
          {editId ? "edit" : "new"}
        </Box>
      </Typography>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          handleErrorClose();
        }}
      >
        <Alert
          onClose={() => handleErrorClose()}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {createPayloadErrorInternal}
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ marginTop: 5 }}
      >
        <Grid item display="flex" flex="0.1" xs={12}>
          <Grid item display="flex" xs={12}>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => sectionHandler("payloads")}
                style={{ maxHeight: "40px" }}
                variant="outlined"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
                startIcon={<ArrowBackIcon />}
              >
                payloads
              </Button>
            </Stack>
          </Grid>
          <Grid item display="flex" justifyContent="flex-end" xs={12}>
            <Stack direction="row" spacing={2}>
              <VariableseDialog />
            </Stack>
          </Grid>
        </Grid>
        <Paper
          elevation={5}
          sx={{
            marginTop: 5,
            backgroundColor: "#fff",
            display: "flex",
            flex: 1,
            alignItems: "stretch",
            flexDirection: "row",
            borderRadius: 5,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "stretch",
              alignContent: "stretch",
              flex: 0.5,
              flexDirection: "column",
              borderRight: "1px solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 0.95,
                flexDirection: "column",
                alignItems: "stretch",
                alignContent: "stretch",
              }}
            >
              <Box
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    margin: 3,
                    flexGrow: 1,
                    display: "flex",
                    flex: 0.4,
                    alignItems: "stretch",
                  },
                }}
              >
                <Box
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => handleRequestType("http")}
                    style={{ height: "30px", width: "120px" }}
                    variant={rType === "http" ? "contained" : "outlined"}
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    http
                  </Button>

                  <Badge
                    badgeContent={"beta"}
                    overlap="circular"
                    color="secondary"
                  >
                    <Button
                      onClick={() => handleRequestType("websocket")}
                      style={{ height: "30px", width: "120px" }}
                      variant={rType === "websocket" ? "contained" : "outlined"}
                      color="primary"
                      disabled={accountHandler()}
                      sx={{
                        fontWeight: "bold",
                        marginLeft: 1,
                      }}
                    >
                      websocket
                    </Button>
                  </Badge>
                  <FormGroup
                    sx={{
                      display: "flex",
                      marginLeft: 3,
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={HTTPReusable}
                          disabled={rType === "websocket" ? true : false}
                          onChange={() => setHTTPReusable(!HTTPReusable)}
                        />
                      }
                      label={
                        <Typography sx={{ color: "rgba(2,2,2,0.38)" }}>
                          reuse connection
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flex: 0.6,
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <Box
                  sx={{
                    flexDirection: "row",
                    flexGrow: 1,
                    display: "flex",
                    marginLeft: 3,
                    marginRight: 3,
                    flex: 0.2,
                  }}
                >
                  {rType === "websocket" ? (
                    ""
                  ) : (
                    <FormControl>
                      <InputLabel id="method-label">Methods</InputLabel>
                      <Select
                        sx={{ width: 140, borderRadius: 0 }}
                        required
                        size="small"
                        labelId="method-label"
                        value={mType}
                        label="Method"
                        onChange={(e) => handleMethod(e.target.value)}
                      >
                        {MethodSelector([
                          "GET",
                          "POST",
                          "DELETE",
                          "PUT",
                          "HEAD",
                          "OPTIONS",
                        ])}
                      </Select>
                    </FormControl>
                  )}
                  {rType === "websocket" && wsUrl ? (
                    <Typography>{url}</Typography>
                  ) : (
                    <TextField
                      required
                      sx={{
                        flexGrow: 1,
                        "& fieldset": {
                          borderRadius: "0px",
                        },
                      }}
                      size="small"
                      id="campaign_name"
                      variant="outlined"
                      color="info"
                      label="URL/WS"
                      value={url}
                      placeholder={
                        rType === "websocket"
                          ? "wss://www.example.com"
                          : "https://www.example.com?id=12"
                      }
                      onChange={(e) => handleUrl(e.target.value)}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    flex: 0.1,
                    alignItems: "stretch",
                  }}
                >
                  <Tabs
                    centered
                    variant="fullWidth"
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <Tab value="headers" label="Headers" />
                    <Tab value="body" label="Body" />
                    <Tab value="catch" label="Catch" />
                    <Tab value="delay" label="Delay" />
                  </Tabs>
                </Box>
                <Box
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    flex: 0.7,
                    margin: 2,
                    alignItems: "stretch",
                    alignContent: "stretch"
                  }}
                >
                  <RenderTab tab={tab} rType={rType} wsUrl={wsUrl} />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                mx: "2px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                alignContent: "stretch",
                margin: 1,
                flex: 0.05,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 0.1,
                  justifyContent: "flex-start",
                }}
              >
                <HowToDialog />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: 0.9,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    const newPayload = JSON.parse(
                      JSON.stringify(currentPayload)
                    );
                    newPayload.url = url;
                    newPayload.httpReusable = HTTPReusable;
                    newPayload.method = mType;
                    newPayload.type = rType;
                    newPayload.delay = newPayload.delay ?? "0s";
                    newPayload.catch =
                      (newPayload?.catch as PayloadCatchState[]).length > 0
                        ? newPayload?.catch
                        : [
                          {
                            wsReferenceTime: "10s",
                          },
                        ];
                    if (rType === "websocket") {
                      dispatch(setWSUrl(url));
                    }
                    dispatch(addPayload(newPayload));
                    dispatch(setCurrentPayload(InitPayloadState));
                  }}
                >
                  Add Configuration
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              minHeight: 660,
              maxHeight: 660,
              display: "flex",
              flex: 0.5,
              alignItems: "stretch",
              flexDirection: "column",
            }}
          >
            <AddedPayloads />
          </Box>

          {/* <Divider
            orientation="vertical"
            sx={{ marginTop: 3, marginLeft: 3, marginRight: 3 }}
            flexItem
          />
          <Box
            sx={{
              flexGrow: 1,
              marginTop: 3,
              borderRadius: 5,
              minHeight: 660,
              maxHeight: 660,
              display: "flex",
              flex: 0.8,
              flexDirection: "column",
            }}
          >
            <AddedPayloads />
          </Box> */}
        </Paper>
      </Grid>
    </Box>
  );
}
