import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";

export const TermsDialog = () => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Link
        onClick={handleClickOpen}
        variant="subtitle1"
        style={{color: "rgba(0,0,0,0.54)"}}
        underline="hover"
      >
        Terms
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Services agreement</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <Typography
            variant="body1"
            color="text.disabled"
            sx={{ marginBottom: 2 }}
          >
            Effective January 1, 2023
          </Typography>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                General
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                This Agreement shall apply to all offers and agreements under
                which "Boty" provides Services to the Customer. No deviations
                from the Agreement shall be valid unless explicitly agreed in
                writing. This "Boty" Services agreement, together with the
                Description of Services and, as applicable, the DPA (defined
                below), if applicable, constitute the “Agreement”. By purchasing
                or signing up for a subscription to the Services provided by
                "Boty", Customer accept that Customer is bound by the terms and
                conditions of this Agreement.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Service provider obligations
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                "Boty" offers the Services on a fixed agreed term, a
                subscription basis (i.e. monthly or annually) and on a trial or
                other promotional basis. "Boty" agrees to provide the Services
                during the term agreed between the Parties. The Services will be
                provided in accordance with the Description of Services, which
                may be found at https://boty.io/pricing/, unless otherwise
                agreed between the Parties.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Customer obligations
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                Customer agrees to comply with all provisions of the Agreement,
                as well as any laws, regulations and governmental decisions
                applicable to the use of the Services. Customer agrees to keep
                Customer account password confidential/SSO credentials, and only
                allow trusted people within Customer organization access to
                Customer account. Customer agrees to keep all account and
                billing information up to date and accurate, and to submit
                payments when due.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Use of the service
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                "Boty" will not be held responsible for any unauthorized use of
                the Services. Customer is responsible for the use of the
                Services by any Customer employee, any person authorized by
                Customer to use the Services, any person to whom Customer has
                given access to the Services, and any person who gains access to
                the Services as a result of Customer’s failure to use reasonable
                security precautions, even if such use was not authorized by
                Customer. Customer must not: (i) sublicense, sell, resell,
                transfer or assign (except to a successor-in-interest as
                permitted hereunder), distribute or otherwise commercially
                exploit or make available to any third-party the Services; (ii)
                modify, copy (other than a reasonable number for Customer’s
                operation of the Services), or make derivative works of or
                compilations based upon the Services; (iii) decompile,
                disassemble, reverse engineer, or otherwise attempt to derive
                source code of the Services; (iv) use or access the Services for
                the purposes of building a competitive product or service, or
                copying its features, functions, or user interface; or (v)
                resell any marketing, training or other materials such as
                slides, advice, guidance, or frameworks provided by "Boty".
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Fees
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                Customer will pay all fees, and other charges, as specified on
                the order. All fees are due and payable within thirty (30) days
                of Customer’s receipt of the invoice unless otherwise agreed.
                All fees shall be paid in U.S. Dollars, via electronic/wire
                transfer or credit card, to an account designated by "Boty"
                unless otherwise agreed between the Parties. All payments are
                non-cancelable and non-refundable.
              </Typography>
              <br />
              <Typography variant="subtitle1" color="text.primary">
                "Boty" may increase fees at any time by notifying the Customer.
                The fee increase will take effect from and including the month
                immediately following "Boty" notification of the fee increase.
                For Services that are provided for a term longer than one month,
                "Boty" may increase the fees upon the expiration of the current
                term. Any such pricing increase will take effect from and
                including the term immediately following the notification of the
                fee increase and will not exceed 7% of the pricing for the
                Services purchased in the immediately prior subscription term,
                unless the pricing was designated in the relevant Agreement as
                being promotional or one-time.
              </Typography>
              <br />
              <Typography variant="subtitle1" color="text.primary">
                In addition to the above, "Boty" may increase the fees at any
                time if the Customer requests any modification to the Services,
                including but not limited to increased capacity or additional
                concurrent tests.
              </Typography>
              <br />
              <Typography variant="subtitle1" color="text.primary">
                The Customer will pay all sales, use, and other taxes imposed by
                any applicable laws and regulations as a result of the payments
                under this Agreement, unless Customer is able to provide
                sufficient evidence of Customer’s exemption from such tax.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Limitation of Liability
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                <strong>
                  "Boty" shall not be liable for any direct, indirect,
                  incidental, special or consequential damages or loss of profit
                  resulting from the use or inability to use any of its Services
                  or for the cost of procurement of substitute services.
                </strong>
                <br />
                <strong>
                  It is strictly prohibited to use "Boty" services in malicious
                  activity (i.e. DDOS, fraud, etc) against 3-rd parties.
                </strong>
                <br />
                <strong>
                  Customer accounts may be blocked and all bots may be
                  stopped/deactivated in case of malicious activity.
                </strong>
              </Typography>
              <br />
              <Typography variant="subtitle1" color="text.primary">
                <strong>
                  {" "}
                  EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN THIS AGREEMENT,
                  THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS.
                </strong>
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Termination
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                Upon expiration of the Agreement, Customer will no longer be
                enabled to use the Services to perform any tests, create
                campaign, payloads or view past campaign results. The customer
                will not be granted continuous access to the Services in order
                to access its data after subscription termination. Thus,
                customers may get access, and view test results only during
                active subscription.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Force majeure
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                Neither Party shall be held responsible for any delay or failure
                in performance of any part of the Agreement to the extent such
                delay or failure is caused by fire, flood, explosion, war,
                embargo, government requirement, civil or military authority,
                riot, strikes or labour disputes, acts of terrorism, natural
                disaster, or other similar causes beyond its control and without
                the fault or negligence of the delayed or non-performing Party.
              </Typography>
            </Stack>
            <br />
            <Stack>
              <Typography component="h2" variant="h5" color="text.primary">
                Intellectual property
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                Any Intellectual Property Rights developed, created, or invented
                by "Boty", or any of its representatives, individually,
                cooperatively or collectively before, during, or after the term
                of the Agreement (including the Services) shall belong to
                "Boty".
              </Typography>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
