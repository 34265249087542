import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Fab, Snackbar, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Collapse } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { campaign } from "../campaign/slice/campaign";
import { switchStep } from "../section/slice/section";
import UsersList from "./List";
import AddIcon from "@mui/icons-material/Add";
import { AddUserDialog } from "./AddUserDialog";
import {
  accountUsersError,
  accountUsers,
  accountUsersStatus,
  deletedAccountUserId,
  addedUser,
  clearError,
} from "./slice/users";
import { selectedAccount, user } from "../profile/slice/profile";

const loading = (status: string[]) => {
  if (status.some((i) => i === "loading")) {
    return <LinearProgress />;
  }
};

export default function Users() {
  const dispatch = useAppDispatch();

  const [newUserDialog, setNewUserDialog] = useState(false);
  const campaignData = useAppSelector(campaign);
  const userError = useAppSelector(accountUsersError);
  const currentUser = useAppSelector(user);
  const currentAccount = useAppSelector(selectedAccount);
  const [errorOpen, setErrorOpen] = useState(false);

  const handleClose = () => {
    setNewUserDialog(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
    dispatch(clearError(""));
  };

  useEffect(() => {
    if (userError) {
      setErrorOpen(true);
    }
  }, [userError]);

  return (
    <Box sx={{ flexGrow: 1, marginTop: 10 }}>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          handleErrorClose();
        }}
      >
        <Alert
          onClose={() => handleErrorClose()}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {userError}
        </Alert>
      </Snackbar>
      <Grid container direction="column" justifyContent="center">
        <Grid
          item
          display="flex"
          flex="1"
          justifyContent="center"
          xs={12}
        >
          <UsersList />
        </Grid>
      </Grid>
      {currentUser.id === currentAccount.ownerId ? (
        <Fab
          color="primary"
          aria-label="add"
          size="large"
          onClick={() => setNewUserDialog(true)}
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(7),
            right: (theme) => theme.spacing(5),
          }}
        >
          <AddIcon />
        </Fab>
      ) : (
        ""
      )}
      <AddUserDialog opened={newUserDialog} handleClose={handleClose} />
    </Box>
  );
}
