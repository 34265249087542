import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Snackbar, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { getRegions, regions } from "./slice/region";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { switchStep } from "../section/slice/section";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  setName,
  addBot,
  setBots,
} from "./slice/new";
import { v4 as uuidv4 } from "uuid";
import {
  getAccounts,
  accounts,
  selectedAccount,
} from "../profile/slice/profile";
import {
  createCampaignError,
  createCampaignStatus,
  clearError,
  createCampaignResult,
} from "./slice/create";
import CampaignBots from "./CampaignBots";

export const RegionSelector = (data: Array<any>) => {
  if (!data.length) {
    return <MenuItem value="None">Region list is Empty</MenuItem>;
  }
  return data.map((v, i) => {
    return (
      <MenuItem key={i} value={v.id}>
        {v.name}
      </MenuItem>
    );
  });
};

export default function CampaignCard() {
  const dispatch = useAppDispatch();
  const data = useAppSelector(regions);
  const [regionId, selectRegionId] = useState("");
  const [region, selectRegion] = useState("");
  const [botAmount, setBotAmount] = useState(0);
  const [botUsers, setBotUsers] = useState(0);
  const [botDuration, setBotDuration] = useState("60s");
  const [botStartAt, setBotStartAt] = useState("");
  const [botRampUp, setBotRampUp] = useState("0s");
  const accountsData = useAppSelector(accounts);
  const currentAccount = useAppSelector(selectedAccount);
  const ccError = useAppSelector(createCampaignError);
  const ccStatus = useAppSelector(createCampaignStatus);
  const resultOnCreate = useAppSelector(createCampaignResult);
  const [errorOpen, setErrorOpen] = useState(false);
  const [createCampaignErrorInternal, setCreateCampaignErrorInternal] =
    useState("");
  const [infoOpen, setInfoOpen] = useState(true);

  const sectionHandler = (event: string) => {
    const selectedName = event;
    dispatch(switchStep(selectedName));
  };

  const handleChange = (event: SelectChangeEvent) => {
    selectRegionId(event.target.value as string);
    data.forEach((v) => {
      if (v.id === event.target.value) {
        selectRegion(v.name as string);
      }
    });
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
    setCreateCampaignErrorInternal("");
    dispatch(clearError(0));
  };

  useEffect(() => {
    if (!accountsData) {
      dispatch(getAccounts());
    }
  }, [dispatch, accountsData]);

  useEffect(() => {
    if (currentAccount.id) {
      dispatch(getRegions(currentAccount.id));
    }
  }, [dispatch, currentAccount]);

  useEffect(() => {
    if (data.length) {
      selectRegionId(data[0].id);
      selectRegion(data[0].name);
    }
  }, [data]);

  useEffect(() => {
    if (ccError) {
      setCreateCampaignErrorInternal(ccError);
      setErrorOpen(true);
    }
  }, [ccError, dispatch]);

  useEffect(() => {
    if (!ccError && ccStatus === "idle") {
      dispatch(setName(""));
      dispatch(setBots([]));
    }
  }, [ccError, ccStatus, dispatch]);

  useEffect(() => {
    if (!ccError && resultOnCreate?.referenceId) {
      dispatch(switchStep("campaigns"));
    }
  }, [ccError, resultOnCreate?.referenceId, dispatch]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: 10,
      }}
    >
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={16000}
        onClose={() => {
          handleErrorClose();
        }}
      >
        <Alert
          onClose={() => handleErrorClose()}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {createCampaignErrorInternal}
        </Alert>
      </Snackbar>
      <Snackbar
        open={infoOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setInfoOpen(false);
        }}
      >
        <Alert
          onClose={() => setInfoOpen(false)}
          severity="info"
          variant="filled"
        >
          <AlertTitle>Info</AlertTitle>
          Boty is using several cloud providers. However,{" "}
          <strong>some regions may be rerouted or be busy</strong> due to the
          high load! In most cases, instance creation and bot deployment may
          take <strong>up to 15 minutes</strong>.
        </Alert>
      </Snackbar>
      <Grid container direction="column" justifyContent="center">
        <Grid item display="flex" flex="0.1" xs={12}>
          <Grid item display="flex" xs={12}>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => sectionHandler("campaigns")}
                style={{ maxHeight: "40px" }}
                variant="outlined"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
                startIcon={<ArrowBackIcon />}
              >
                campaigns
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          display="flex"
          flex="1"
          justifyContent="center"
          xs={12}
          sx={{ marginTop: 5 }}
        >
          <Paper
            elevation={5}
            sx={{
              mx: "2px",
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              border: "0.5px solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexGrow: 1,
                flexDirection: "row",
              }}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    margin: 3,
                    flexGrow: 1,
                    display: "flex",
                    maxWidth: 800,
                    flex: 1,
                  },
                }}
                noValidate
                autoComplete="off"
                alignItems={"stretch"}
              >
                {/* <InputLabel sx={{ fontWeight: "bold" }} id="campaign_name">
                  Name
                </InputLabel> */}
                <TextField
                  required
                  id="campaign_name"
                  variant="outlined"
                  color="info"
                  label="Name"
                  placeholder="Friday tests"
                  onChange={(e) => dispatch(setName(e.target.value))}
                />
                <Divider />
                {/* <InputLabel sx={{ fontWeight: "bold" }} id="campaign_config">
                  Configuration
                </InputLabel> */}
                <Box sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Regions
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      value={regionId}
                      label="Regions"
                      onChange={handleChange}
                    >
                      {RegionSelector(data)}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", flex: 1 }}
                      variant="body2"
                      align={"center"}
                    >
                      <Box
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        How many bots run per region?
                      </Box>
                    </Typography>
                    <FormControl sx={{ marginLeft: 5 }}>
                      <TextField
                        required
                        size="small"
                        id="bots"
                        label="Bots"
                        placeholder={`max ${
                          accountsData.filter(
                            (v) => v.id === currentAccount?.id
                          )[0]?.bots ?? 0
                        }`}
                        variant="outlined"
                        type="number"
                        onChange={(e) => setBotAmount(parseInt(e.target.value))}
                      />
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: 3,
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", flex: 1 }}
                      variant="body2"
                      align={"center"}
                    >
                      <Box
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        How many virtual users run per bot?
                      </Box>
                    </Typography>
                    <FormControl sx={{ marginLeft: 5 }}>
                      <TextField
                        required
                        size="small"
                        id="outlined-basic"
                        label="Virtual Users"
                        placeholder={`max ${
                          accountsData.filter(
                            (v) => v.id === currentAccount?.id
                          )[0]?.vUsers ?? 0
                        }`}
                        variant="outlined"
                        type="number"
                        onChange={(e) => setBotUsers(parseInt(e.target.value))}
                      />
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: 3,
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", flex: 1 }}
                      variant="body2"
                      align={"center"}
                    >
                      <Box
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        At what time start the bot?
                      </Box>
                    </Typography>
                    <FormControl sx={{ marginLeft: 5 }}>
                      <TextField
                        disabled={
                          !accountsData.filter(
                            (v) => v.id === currentAccount?.id
                          )[0]?.scheduler
                        }
                        id="datetime-local"
                        size="small"
                        label="Start At"
                        variant="outlined"
                        type="datetime-local"
                        defaultValue="2017-05-24T10:30"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setBotStartAt(new Date(e.target.value).toISOString())
                        }
                      />
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: 3,
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", flex: 1 }}
                      variant="body2"
                      align={"center"}
                    >
                      <Box
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        What is the bot duration?
                      </Box>
                    </Typography>
                    <FormControl sx={{ marginLeft: 5 }}>
                      <TextField
                        required
                        size="small"
                        id="outlined-basic"
                        label="Duration(i.e. 1s, 1m, 1h)"
                        placeholder="min 2s"
                        variant="outlined"
                        onChange={(e) => setBotDuration(e.target.value)}
                      />
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: 3,
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", flex: 1 }}
                      variant="body2"
                      align={"center"}
                    >
                      <Box
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        What is the ramp-up duration per bot?
                      </Box>
                    </Typography>
                    <FormControl sx={{ marginLeft: 5 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Duration(i.e. 1s, 1m, 1h)"
                        placeholder="default 0s"
                        onChange={(e) => setBotRampUp(e.target.value)}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ justifyContent: "flex-end" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      dispatch(
                        addBot({
                          regionId: regionId,
                          regionName: region,
                          amount: botAmount,
                          vUsers: botUsers,
                          duration: botDuration,
                          rampUp: botRampUp,
                          ...(botStartAt && { startAt: botStartAt }),
                          referenceId: uuidv4(),
                        })
                      )
                    }
                  >
                    Add Configuration
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Divider
            orientation="vertical"
            sx={{ marginTop: 3, marginLeft: 3, marginRight: 3 }}
            flexItem
          />
          <Box
            sx={{
              flexGrow: 1,
              marginTop: 3,
              borderRadius: 5,
              minHeight: 660,
              maxHeight: 660,
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <CampaignBots />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
