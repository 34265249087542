import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { HTTP } from '../../../helper/http';

export interface User {
    id: string;
    created: string;
    updated: string;
    email: string;
    iss: string;
}

export interface Account {
    id: string;
    created: string;
    updated: string;
    ownerId: string;
    bots: Number;
    scheduler: boolean;
    duration: boolean;
    vUsers: Number;
    campaigns: Number;
    traffic: string;
    lock: boolean;
    type: string;
    owner_email: string;
    subscriptionDate: string;
}
export interface ProfileState {
    userStatus: 'idle' | 'loading' | 'failed';
    accountsStatus: 'idle' | 'loading' | 'failed';
    user: User;
    accounts: Account[];
    selectedAccount: Account;
    userError: string;
    accountsError: string;
    sentTraffic: string;

}

export const initialState: ProfileState = {
    userStatus: 'idle',
    accountsStatus: 'idle',
    user: {} as User,
    accounts: [] as Account[],
    selectedAccount: {} as Account,
    userError: "",
    accountsError: "",
    sentTraffic: ""
};

export const getUser = createAsyncThunk(
    'profile/getUser',
    async () => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/user`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);

export const sentTraffic = createAsyncThunk(
    'profile/sentTraffic',
    async (accountId: string) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/account/${accountId}/traffic`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.text()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.text()
    }
);

export const getAccounts = createAsyncThunk(
    'profile/getAccounts',
    async () => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/accounts`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);


export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        selectAccount: (state, action) => {
            state.selectedAccount = action.payload;
        },
        resetProfile: (state, action) => {
            state.accounts = action.payload.accounts;
            state.user = action.payload.user;
        },
        clearError: (state, action) => {
            state.userError = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.userStatus = 'loading';
            })
            .addCase(getUser.fulfilled, (state, action) => {
                if (action.payload) {
                    state.user = action.payload;
                }
                state.userStatus = 'idle';
                state.userError = "";
            })
            .addCase(getUser.rejected, (state, action) => {
                state.userStatus = 'failed';
                state.userError = `Unable to get user. Error:  ${action.error.message}`;
            })
            .addCase(getAccounts.pending, (state) => {
                state.accountsStatus = 'loading';
            })
            .addCase(getAccounts.fulfilled, (state, action) => {
                if (action.payload) {
                    state.accounts = action.payload;
                }
                state.accountsStatus = 'idle';
                state.accountsError = "";
            })
            .addCase(getAccounts.rejected, (state, action) => {
                state.accountsStatus = 'failed';
                state.accountsError = `Unable to get user. Error:  ${action.error.message}`;
            })
            .addCase(sentTraffic.pending, (state) => {
                state.accountsStatus = 'loading';
            })
            .addCase(sentTraffic.fulfilled, (state, action) => {
                if (action.payload) {
                    state.sentTraffic = action.payload;
                }
                state.accountsStatus = 'idle';
                state.accountsError = "";
            })
            .addCase(sentTraffic.rejected, (state, action) => {
                state.accountsStatus = 'failed';
                state.accountsError = `Unable to get accoount traffic. Error:  ${action.error.message}`;
            })
    },
});

export const { selectAccount, resetProfile, clearError } = profileSlice.actions;

export const userError = (state: RootState) => state.profile.userError;
export const accountError = (state: RootState) => state.profile.accountsError;
export const traffic = (state: RootState) => state.profile.sentTraffic;
export const accounts = (state: RootState) => state.profile.accounts;
export const user = (state: RootState) => state.profile.user;
export const userStatus = (state: RootState) => state.profile.userStatus;
export const accountsStatus = (state: RootState) => state.profile.accountsStatus;
export const selectedAccount = (state: RootState) => state.profile.selectedAccount;


export default profileSlice.reducer;