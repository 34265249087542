import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { List, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { PayloadState, selectGlobalVariables, setGlobalVariables } from "./slice/new";

export const RenderVariables = () => {
  
  const dispatch = useAppDispatch();
  const currentVariables = useAppSelector(selectGlobalVariables);
  
  return (
    <List
      sx={{
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      {Object.entries(currentVariables as { [key: string]: string }).map(([key, value]) => (
        <ListItem key={key} disableGutters>
          <Box
            sx={{
              display: "flex",
              borderRadius: 3,
              border: "0.5px solid rgba(0, 0, 0, 0.12)",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#fafafa",
              maxHeight: 50,
              padding: 1.8,
              margin: 1,
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flexGrow: 1,
              }}
            >
              <Typography variant="body2" align={"center"}>
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {key}
                </Box>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flexGrow: 1,
              }}
            >
              <Typography variant="body2" align={"center"}>
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {value}
                </Box>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                align={"center"}
                sx={{ margin: 1 }}
              >
                <IconButton
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    const newVariables = JSON.parse(JSON.stringify(currentVariables));
                    delete (newVariables as { [key: string]: string })?.[`${key}`];
                    dispatch(setGlobalVariables(newVariables));
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};
