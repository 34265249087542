import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "./BootstrapDialog";
import TextField from "@mui/material/TextField";
import { deleteUserFromAccount, AccountUser } from "./slice/users";
import { Account } from "../profile/slice/profile";

export const DeleteUserDialog = ({
  opened,
  handleClose,
  user,
  account,
}: {
  opened: boolean;
  handleClose: () => void;
  user: AccountUser;
  account: Account;
}) => {
  const dispatch = useAppDispatch();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Delete user from the account?
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Warning, user <strong>{user.email}</strong> will be removed from the
            account permanently!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="secondary"
            autoFocus
            onClick={() => {
              dispatch(
                deleteUserFromAccount({
                  id: user?.userId,
                })
              );
              handleClose();
            }}
          >
            delete
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
