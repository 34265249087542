import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Route, Routes, Link as RouterLink, Navigate } from 'react-router-dom';

const mdTheme = createTheme();


export default function Copyright(props: any) {
    return (
        <Typography variant="body2" sx={{marginTop: 10}} color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href={process.env.REACT_APP_URL}>
                Boty
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}