import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { deleteBot } from "./slice/bots";
import { BootstrapDialog, BootstrapDialogTitle } from "./DeleteBotsDialog";

export const DeleteBotDialog = ({
  campaignId,
  botId,
  disabled,
}: {
  campaignId: string;
  botId: string;
  disabled: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{ height: "30px", width: "120px" }}
        variant="outlined"
        color="error"
        disabled={disabled}
        sx={{
          margin: 1
        }}
        endIcon={<DeleteIcon />}
      >
        delete
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Delete Bot {botId} from Campaign?
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Warning, Bot <strong>{botId}</strong> will be removed permanently!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="error"
            autoFocus
            onClick={() =>
              {
                dispatch(deleteBot({ campaignId: campaignId, botId: botId }));
                handleClose();
              }
            }
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
