import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';

export interface PayloadState {
    type: string;
    method: string;
    delay: string;
    status: number;
    headers: { [key: string]: string };
    url: string;
    httpReusable: boolean;
    data: string;
    catch: PayloadCatchState[];
}

export const InitPayloadState = {
    type: "http",
    method: "GET",
    delay: "0s",
    status: 200,
    headers: {},
    httpReusable: false,
    url: "",
    data: "",
    catch: [],
}

export interface PayloadCatchState {
    location: string;
    name: string;
    path: string;
    wsReference: string;
    wsReferenceId: string;
    wsReferenceTime: string;
}

export interface NewPayloadState {
    data: PayloadState[];
    name: string;
    accountId: string;
    currentPayload?: PayloadState;
    globalVariables?: { [key: string]: string };
    wsUrl: string;
    editId: string;
}

export const initialState: NewPayloadState = {
    data: [] as PayloadState[],
    name: "",
    accountId: "",
    currentPayload: InitPayloadState,
    globalVariables: {},
    wsUrl: "",
    editId: ""
}

export const payloadSlice = createSlice({
    name: 'newPayload',
    initialState,
    reducers: {
        addPayload: (state, action) => {
            state.data = [...state.data, action.payload];
        },
        setPayload: (state, action) => {
            state.data = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setCurrentPayload: (state, action) => {
            state.currentPayload = action.payload;
        },
        setGlobalVariables: (state, action) => {
            state.globalVariables = action.payload;
        },
        setWSUrl: (state, action) => {
            state.wsUrl = action.payload;
        },
        setEditId: (state, action) => {
            state.editId = action.payload;
        },
    },
});

export const { addPayload, setPayload, setName, setCurrentPayload, setGlobalVariables, setWSUrl, setEditId } = payloadSlice.actions;


export const selectPayloads = (state: RootState) => state.newPayload.data;
export const selectWsUrl = (state: RootState) => state.newPayload.wsUrl;
export const selectName = (state: RootState) => state.newPayload.name;
export const selectAccountId = (state: RootState) => state.newPayload.accountId;
export const selectCurrentPayload = (state: RootState) => state.newPayload.currentPayload;
export const selectGlobalVariables = (state: RootState) => state.newPayload.globalVariables;
export const selectEditId = (state: RootState) => state.newPayload.editId;

export default payloadSlice.reducer;
