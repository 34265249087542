import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import TagIcon from "@mui/icons-material/Tag";
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';

import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../dashboard/DeleteBotsDialog";
import { Avatar, Box, IconButton, Link } from "@mui/material";
import { firebaseSignout, firebaseUpdateEmail, firebaseUpdatePassword } from "../connect/slice/connect";

export const ChangeEmailDialog = () => {
  const [open, setOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography variant="subtitle1">
        <Box
          sx={{
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 15,
              height: 15,
              backgroundColor: "#fff",
            }}
          >
            <UnsubscribeIcon
              sx={{
                fontSize: 15,
                textAlign: "center",
                color: (theme) => theme.palette.text.secondary,
              }}
            />
          </Avatar>
          <Button
            onClick={handleClickOpen}
            size="small"
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Change e-mail
          </Button>
        </Box>
      </Typography>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          E-mail change
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Please enter password and new e-mail.
            Changing your e-mail will sign you out on your devices, with some
            exceptions.
          </Typography>
          <TextField
            fullWidth
            id="old_password"
            variant="outlined"
            color="info"
            type={"password"}
            label="password"
            size="small"
            onChange={(e) => setOldPassword(e.target.value)}
          />
          &nbsp;
          <TextField
            fullWidth
            id="new_email"
            variant="outlined"
            color="info"
            type={"email"}
            label="new email"
            size="small"
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="success"
            autoFocus
            onClick={() => {
              dispatch(firebaseUpdateEmail({pass: oldPassword, email: newEmail}));
              handleClose();
            }}
          >
            change
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
