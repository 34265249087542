import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {
  selectCurrentPayload,
  setCurrentPayload,
} from "./slice/new";
import { RenderHeaders } from "./RenderHeader";

export const HeadersTab = () => {
  const dispatch = useAppDispatch();
  const currentPayload = useAppSelector(selectCurrentPayload);
  const [headersKey, setHeadersKey] = useState("");
  const [headersValue, setHeadersValue] = useState("");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 0.1,
        }}
      >
        <TextField
          sx={{
            flex: 0.25,
            "& fieldset": {
              borderRadius: "0px",
            },
          }}
          id="key"
          size="small"
          variant="outlined"
          color="info"
          value={headersKey}
          placeholder="Cookie"
          onChange={(e) => setHeadersKey(e.target.value)}
        />
        <TextField
          size="small"
          sx={{
            flex: 0.7,
            "& fieldset": {
              borderRadius: "0px",
            },
          }}
          id="value"
          variant="outlined"
          color="info"
          value={headersValue}
          placeholder="JSESSIONID=123"
          onChange={(e) => setHeadersValue(e.target.value)}
        />
        <Button
          sx={{ flex: 0.05, fontSize: 25 }}
          aria-label="add"
          variant="outlined"
          color="info"
          size="large"
          onClick={() => {
            const newPayload = JSON.parse(JSON.stringify(currentPayload));
            const newHeaders = newPayload?.headers ?? {};
            newHeaders[`${headersKey}`] = headersValue;
            newPayload.headers = newHeaders;
            dispatch(setCurrentPayload(newPayload));
            setHeadersValue("");
            setHeadersKey("");
          }}
        >
          <AddIcon fontSize="inherit" />
        </Button>
      </Box>
      <Box
        sx={{
          maxHeight: 350,
          maxWidth: 685,
          display: "flex",
          flex: 0.9,
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {RenderHeaders()}
      </Box>
    </Box>
  );
};
