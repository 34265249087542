import React, { useState, useEffect } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { GoogleLoginButton } from "react-social-login-buttons";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  googleSignUpAsync,
  selectIsLoggedIn,
  connectError,
  firebaseRegistration,
  firebaseForgotPassword,
  firebaseLogin,
  clearError,
} from "./slice/connect";

import {
  Box,
  Button,
  Avatar,
  TextField,
  CssBaseline,
  Grid,
  Typography,
  Link,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Navigate } from "react-router-dom";
import Copyright from "../copyright/Copyright";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Image from "./bot.jpg";
import { TermsDialog } from "../policy/TermsDialog";
import { PrivacyDialog } from "../policy/PrivacyDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  or: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },

  github: {
    width: "100% !important",
    margin: "0 !important",
  },

  githubText: {
    fontSize: "14px !important",
    fontWeight: "bold",
  },
}));

export default function Connect() {
  const classes = useStyles();
  const [isLogin, toLogin] = useState(false);
  const [fEmail, forgotEmail] = useState("");
  const loggedIn = useAppSelector(selectIsLoggedIn);
  const error = useAppSelector(connectError);
  const [errorOpen, setErrorOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    dispatch(
      firebaseLogin({
        email: data.get("email"),
        password: data.get("password"),
      })
    );
  };

  const handleRegisterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    dispatch(
      firebaseRegistration({
        email: data.get("email"),
        password: data.get("password"),
      })
    );
  };

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  const handleErrorClose = () => {
    setErrorOpen(false);
    dispatch(clearError(""));
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <React.Fragment>
      {error ? (
        <Snackbar
          open={errorOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            handleErrorClose();
          }}
        >
          <Alert
            onClose={() => handleErrorClose()}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          lg={7}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          lg={5}
          component={Paper}
          elevation={0}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Connect
            </Typography>
            {isLogin ? (
              <Box
                component="form"
                noValidate
                onSubmit={handleLoginSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Link onClick={handleClickOpen}>
                  Forgot password
                </Link>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Forgot password</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      To reset password, please enter your email address here.
                      We will send reset link.
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Email Address"
                      type="email"
                      fullWidth
                      variant="outlined"
                      onChange={(v) =>
                        forgotEmail(v.currentTarget.value as string)
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      onClick={() => {
                        dispatch(firebaseForgotPassword(fEmail));
                        handleClose();
                      }}
                    >
                      Reset
                    </Button>
                  </DialogActions>
                </Dialog>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Log In
                </Button>

                <Grid
                  container
                  sx={{
                    my: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Grid>
                    <Grid>
                      <Link onClick={() => toLogin(!isLogin)} variant="body2">
                        Do not have an account? Sign Up
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                component="form"
                noValidate
                onSubmit={handleRegisterSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant="body2" color="text.secondary">
                    By clicking "Sign up/in", you agree to our policies:
                  </Typography>
                  &nbsp;&nbsp;
                  <TermsDialog /> &nbsp;&nbsp; <Typography variant="body2" color="text.secondary">
                    and
                  </Typography> &nbsp;&nbsp; <PrivacyDialog color={"rgba(0,0,0,0.54)"} />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>

                <Grid item xs={12}>
                  <div className={classes.or}>or</div>
                </Grid>

                <Grid item xs={12}>
                  <GoogleLoginButton
                    className={classes.githubText}
                    onClick={() => dispatch(googleSignUpAsync())}
                    text={"Sign in with Google"}
                  />
                </Grid>

                <Grid
                  container
                  sx={{
                    my: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Grid>
                    <Grid>
                      <Link onClick={() => toLogin(!isLogin)} variant="body2">
                        Already have an account? Log in
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
