import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { campaign } from "../campaign/slice/campaign";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ReferenceArea,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Box from "@mui/material/Box";
import { getStats, stats, Stat } from "./slice/stats";

interface ChartState {
  left: string;
  right: string;
  refAreaLeft: string;
  refAreaRight: string;
  top: string;
  bottom: string;
  animation: boolean;
}

const initialState: ChartState = {
  left: "min",
  right: "max",
  refAreaLeft: "",
  refAreaRight: "",
  top: "max",
  bottom: "min",
  animation: true,
};

export default function Chart() {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const campaignData = useAppSelector(campaign);
  const stat = useAppSelector(stats);
  const [currentStat, setCurrentStat] = useState({} as Stat);
  const [chartState, setChartState] = useState(initialState);

  const zoom = () => {
    if (stat?.time >= 60000000000) {
      let { refAreaLeft, refAreaRight } = chartState;

      if (refAreaLeft === refAreaRight || refAreaRight === "") {
        setChartState((s) => {
          const chart = { ...s };
          chart.refAreaLeft = "";
          chart.refAreaRight = "";

          return chart;
        });
        return;
      }

      if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

      dispatch(
        getStats({
          id: campaignData?.id,
          from: new Date(refAreaLeft).getTime(),
          to: new Date(refAreaRight).getTime(),
        })
      );

      setChartState(initialState);
    }
  };

  useEffect(() => {
    if (campaignData?.id === stat?.campaignId && stat?.data?.length) {
      setCurrentStat(stat);
    }
  }, [dispatch, campaignData?.status, stat]);

  return (
    <Box
      style={{ userSelect: "none", width: "100%" }}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 280,
      }}
    >
      <ResponsiveContainer>
        <AreaChart
          data={currentStat?.data}
          margin={{
            top: 16,
            right: 16,
            bottom: 30,
            left: 2,
          }}
          onMouseDown={(e: any) =>
            stat?.time >= 60000000000 &&
            setChartState((s) => {
              const chart = { ...s };
              chart.refAreaLeft = e?.activeLabel;
              return chart;
            })
          }
          onMouseMove={(e: any) =>
            chartState?.refAreaLeft &&
            stat?.time >= 60000000000 &&
            setChartState((s) => {
              const chart = { ...s };
              chart.refAreaRight = e?.activeLabel;
              return chart;
            })
          }
          onMouseUp={zoom}
        >
          <defs>
            <linearGradient id="colorRequest" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgb(27, 94, 32)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgb(27, 94, 32)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorError" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(198, 40, 40)"
                stopOpacity={0.8}
              />
              <stop offset="95%" stopColor="rgb(198, 40, 40)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorHttpError" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(239, 83, 80)"
                stopOpacity={0.8}
              />
              <stop offset="95%" stopColor="rgb(239, 83, 80)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorHttpCodeError" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="rgb(81, 45, 168)"
                stopOpacity={0.8}
              />
              <stop offset="95%" stopColor="rgb(81, 45, 168)" stopOpacity={0} />
            </linearGradient>
            <linearGradient
              id="colorMissingMsgError"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor="rgb(27, 58, 87)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgb(27, 58, 87)" stopOpacity={0} />
            </linearGradient>
            <linearGradient
              id="colorWsMsgTimeoutErrors"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor="rgb(149, 117, 205)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgb(149, 117, 205)" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="requestTime"
            interval={"preserveStartEnd"}
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            domain={[chartState?.left, chartState?.right]}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            domain={[chartState?.bottom, chartState?.top]}
            yAxisId="1"
          />
          <Tooltip />
          <Legend />
          <Area
            yAxisId="1"
            isAnimationActive={true}
            type="monotone"
            dataKey="requests"
            stroke={theme.palette.success.dark}
            dot={true}
            name="Requests"
            fillOpacity={1}
            fill="url(#colorRequest)"
          />
          <Area
            yAxisId="1"
            isAnimationActive={false}
            type="monotone"
            dataKey="errors"
            stroke={theme.palette.error.dark}
            dot={true}
            name="Common Errors"
            fillOpacity={1}
            fill="url(#colorError)"
          />
          <Area
            yAxisId="1"
            isAnimationActive={false}
            type="monotone"
            dataKey="httpErrors"
            stroke={theme.palette.error.light}
            dot={true}
            name="HTTP Errors"
            fillOpacity={1}
            fill="url(#colorHttpError)"
          />
          <Area
            yAxisId="1"
            isAnimationActive={false}
            type="monotone"
            dataKey="httpCodeErrors"
            stroke={theme.palette.secondary.dark}
            dot={true}
            name="HTTP Code Errors"
            fillOpacity={1}
            fill="url(#colorHttpCodeError)"
          />
          <Area
            yAxisId="1"
            isAnimationActive={false}
            type="monotone"
            dataKey="wsMsgTimeoutErrors"
            stroke={theme.palette.secondary.light}
            dot={true}
            name="WS Msg Timeout Errors"
            fillOpacity={1}
            fill="url(#colorWsMsgTimeoutErrors)"
          />
          <Area
            yAxisId="1"
            isAnimationActive={false}
            type="monotone"
            dataKey="missingMsgErrors"
            stroke={theme.palette.primary.dark}
            dot={true}
            name="Missing Payload Errors"
            fillOpacity={1}
            fill="url(#colorMissingMsgError)"
          />
          {stat?.time >= 60000000000 &&
          chartState?.refAreaLeft &&
          chartState?.refAreaRight ? (
            <ReferenceArea
              yAxisId="1"
              x1={chartState?.refAreaLeft}
              x2={chartState?.refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}
