import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import NotStartedIcon from '@mui/icons-material/NotStarted';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Bot } from "./slice/bots";
import { launchBot } from "./slice/launch";
import { BootstrapDialog, BootstrapDialogTitle } from "./DeleteBotsDialog";

export const StartStopBotDialog = ({
  campaignId,
  bot,
  disabled,
}: {
  campaignId: string;
  bot: Bot;
  disabled: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{ height: "30px", width: "120px" }}
        variant="outlined"
        color="secondary"
        disabled={disabled}
        sx={{
          margin: 1
        }}
        endIcon={<NotStartedIcon />}
      >
        {bot?.status === "running" ? "stop" : "start"}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Would you like to {bot?.status === "running" ? "stop" : "start"} Bot - {bot?.id} ?
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Bot <strong>{bot?.id}</strong> will be {bot?.status === "running" ? "stopped" : "started"} soon!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="error"
            autoFocus
            onClick={() =>
              {
                dispatch(launchBot({ id: campaignId, botId: bot?.id, start: bot?.status === "running" ? false : true }));
                handleClose();
              }
            }
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
