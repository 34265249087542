import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import {
    BootstrapDialog,
    BootstrapDialogTitle,
} from "../dashboard/DeleteBotsDialog";
import { Box, IconButton, Link, Stack } from "@mui/material";
import {
    selectGlobalVariables,
    setGlobalVariables,
} from "./slice/new";
import { RenderVariables } from "./RenderVariables";

export const VariableseDialog = () => {
    const dispatch = useAppDispatch();
    const currentVariables = useAppSelector(selectGlobalVariables);

    const [open, setOpen] = useState(false);
    const [variablesKey, setVariablesKey] = useState("");
    const [variablesValue, setVariablesValue] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                onClick={handleClickOpen}
                style={{ maxHeight: "40px" }}
                variant="outlined"
                color="secondary"
                sx={{
                    fontWeight: "bold",
                }}
            >
                global variables
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Global variables
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        You can add global variables and reuse them within the current suite.
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            marginTop: 2
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                flex: 0.1,
                            }}
                        >
                            <TextField
                                sx={{
                                    flex: 0.25,
                                    "& fieldset": {
                                        borderRadius: "0px",
                                    },
                                }}
                                id="key"
                                size="small"
                                variant="outlined"
                                color="info"
                                value={variablesKey}
                                placeholder="Name"
                                onChange={(e) => setVariablesKey(e.target.value)}
                            />
                            <TextField
                                size="small"
                                sx={{
                                    flex: 0.7,
                                    "& fieldset": {
                                        borderRadius: "0px",
                                    },
                                }}
                                id="value"
                                variant="outlined"
                                color="info"
                                value={variablesValue}
                                placeholder="JSESSIONID=123"
                                onChange={(e) => setVariablesValue(e.target.value)}
                            />
                            <Button
                                sx={{ flex: 0.05, fontSize: 25 }}
                                aria-label="add"
                                variant="outlined"
                                color="info"
                                size="large"
                                onClick={() => {
                                    const newVariables = JSON.parse(JSON.stringify(currentVariables));
                                    newVariables[`${variablesKey}`] = variablesValue;
                                    dispatch(setGlobalVariables(newVariables));
                                    setVariablesValue("");
                                    setVariablesKey("");
                                }}
                            >
                                <AddIcon fontSize="inherit" />
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                maxHeight: 350,
                                maxWidth: 685,
                                minHeight: 350,
                                display: "flex",
                                flex: 0.9,
                                flexDirection: "column",
                                overflow: "auto",
                            }}
                        >
                            {RenderVariables()}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        cancel
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};
