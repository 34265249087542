import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../app/hooks";
import { wsTestRunMsg } from "../connect/slice/ws";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../dashboard/DeleteBotsDialog";
import { Box, Link } from "@mui/material";
import { Global } from "@emotion/react";

export const HowToDialog = () => {
  const [open, setOpen] = useState(false);
  const testMsg = useAppSelector(wsTestRunMsg);
  const [msg, setMsg] = useState(testMsg);

  const handleClick = (b: boolean) => {
    setOpen(b);
  };

  useEffect(() => {
    if (testMsg?.referenceId !== msg?.referenceId) {
      setMsg(testMsg);
      setOpen(true);
    }
  }, [testMsg?.referenceId, msg?.referenceId]);

  return (
    <div>
      <IconButton onClick={() => handleClick(true)} aria-label="delete">
        <HelpOutlineIcon
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        />
      </IconButton>
      <BootstrapDialog
        onClose={() => handleClick(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClick(false)}
        >
          Payload Configuration
        </BootstrapDialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="subtitle1">
              You may create several test cases. Each test case will be added as
              a sequential step in your test suite in the right section.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Request type
            </Typography>
            <Typography variant="subtitle1">
              You may choose between <strong>HTTP</strong> and{" "}
              <strong>WebSocket</strong>. Address is mandatory and must contain
              schema and full path. Example:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                my: 2,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "grey.100",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              https://www.example.com
              <br />
              https://www.example.com/data
              <br />
              {"https://www.example.com/data/${user.id}"}
            </Typography>
            <Typography variant="subtitle2" fontWeight={"bold"}>
              NOTE!!! WebSocket is defined per suite. Currently, you can't
              redefine the WebSocket connection if it is already defined within
              the suite.
              <br />
              <br />
              Content-Type is required for POST, PUT, PATCH
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Headers
            </Typography>
            <Typography variant="subtitle1">
              You may use any standard headers within your HTTP or WebSocket
              Request. Example:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                my: 2,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "grey.100",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              Cookie: JSESSIONID=123
              <br />
              Content-Type: application/json
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Body
            </Typography>
            <Typography variant="subtitle1">
              Body does not require any specific format. However, it was tested
              only for JSON and standard URL encoded forms so far. Example:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                my: 2,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "grey.100",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              password=test&email=test@example.com
              <br />
              &#123;"userId": "12345"&#125;
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Catch
            </Typography>
            <Typography variant="subtitle1">
              You may catch response data, status codes, and reference Ids.
              Caught response data may be reused as a variable in{" "}
              <strong>headers</strong> and/or <strong>body</strong> in the next
              cases. The request will be marked as an error if status code does
              not match. However, inserted data will be an{" "}
              <i>empty string("")</i> if data path is wrong.
            </Typography>
            <br />
            <Typography variant="subtitle2" fontWeight={"bold"}>
              NOTE!!! Variable names must be unique
            </Typography>
            <br />
            <Typography variant="subtitle1">
              The data value may be accessed by using dot-like notation{" "}
              <i>{`
              {
                "name": {"first": "Tom", "last": "Anderson"},
                "age":37,
                "children": ["Sara","Alex","Jack"],
                "fav.movie": "Deer Hunter",
                "friends": [
                  {"first": "Dale", "last": "Murphy", "age": 44, "nets": ["ig", "fb", "tw"]},
                  {"first": "Roger", "last": "Craig", "age": 68, "nets": ["fb", "tw"]},
                  {"first": "Jane", "last": "Murphy", "age": 47, "nets": ["ig", "tw"]}
                ],
                "data": "{\"name\": \"John\"}"
              }
              `}</i>
              :
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                my: 2,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "grey.100",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              {`name.last          >> "Anderson"`}
              <br />
              {`age                >> 37`}
              <br />
              {`children          >> ["Sara","Alex","Jack"]`}
              <br />
              {`children.#         >> 3`}
              <br />
              {`children.1         >> "Alex"`}
              <br />
              {`child*.2           >> "Jack"`}
              <br />
              {`fav\.movie         >> "Deer Hunter"`}
              <br />
              {`friends.#.first    >> ["Dale","Roger","Jane"]`}
              <br />
              {`friends.1.last     >> "Craig"`}
            </Typography>
            <Typography variant="subtitle2" fontWeight={"bold"}>
              There are currently the following built-in modifiers:
            </Typography>
            <Typography variant="subtitle2">
              <ul>
                <li>@reverse: Reverse an array or the members of an object.</li>
                <li>@ugly: Remove all whitespace from a json document.</li>
                <li>@fromstr: Converts a string from json. Unwraps a json string.</li>
              </ul>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                my: 2,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "grey.100",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              {`children|@reverse           >> ["Jack","Alex","Sara"]`}
              <br />
              {`data|@fromstr|name          >> "John"`}
            </Typography>
            <Typography variant="subtitle2">
              You can find more about JSON retrieval <Link href="https://github.com/tidwall/gjson">
                {'here.'}
              </Link>
            </Typography>
            <br />
            <Typography variant="subtitle2" fontWeight={"bold"}>
              Reuse examples:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                my: 2,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "grey.100",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              {"userId=${<YOUR_PREVIOUSLY_STORED_VARIABLE_NAME>}"}
              <br />
              &#123;"userId": "$&#123;&lt;YOUR_PREVIOUSLY_STORED_VARIABLE_NAME&gt;&#125;"&#125;
              <br />
              {"Cookie: ${<YOUR_PREVIOUSLY_STORED_VARIABLE_NAME>}"}
            </Typography>
            <Typography variant="subtitle2" fontWeight={"bold"}>
              WebSocket(in beta) Note:
            </Typography>
            <Typography variant="subtitle2">
              You may catch a particular WebSocket message within a particular
              time: Caught response data may be reused as a variable in{" "}
              <strong>headers</strong> and/or <strong>body</strong> in the next
              cases.
              <br />
              <strong>Response reference path</strong> - will be used as an
              reference for the ID
              <br />
              <strong>Response reference ID</strong> - ID value to match (there
              will be an error if ID does not match )
              <br />
              <strong>Response Time</strong> - eventual time to wait for the
              message (10 seconds is a default time)
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Delay
            </Typography>
            <Typography variant="subtitle1">
              You may setup a delay before each request. Currently, supported two
              options, random - will generate a delay 1-10 seconds and custom.
              There is no default delay, all cases will be executed one by one.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Global variables
            </Typography>
            <Typography variant="subtitle1">
              You may set up global variables per suite. Click on <i>Global Variables</i> button, and set the appropriate variable name and value.
              Global variables may be resued in headers, body or URL. Example:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                my: 2,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "grey.100",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              {"userId=${var.<VARIABLE_NAME>}"}
              <br />
              &#123;"userId": "$&#123;var.&#x3c;VARIABLE_NAME&#x3e;&#125;"&#125;
              <br />
              {"Cookie: ${var.<VARIABLE_NAME>}"}
            </Typography>
            <Typography variant="subtitle2" fontWeight={"bold"}>
              NOTE!!! Variable names must be unique
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Built in functions
            </Typography>
            <Typography variant="subtitle1">
            <ul>
                <li><i>UUID</i> - will generate random Universal Unique Identifier v4</li>
                <li><i>rngString</i> - will generate random string 1-16</li>
                <li><i>sha256</i> - will generate sha256 checksum for particular value</li>
              </ul>
              Built functions may used as variables in next cases, example:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: "normal",
                my: 2,
                p: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "#101010" : "grey.100",
                color: (theme) =>
                  theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                  theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              {"userId=${func(UUID)}"}
              <br />
              &#123;"userId": "$&#123;func(UUID)&#125;"&#125;
              <br />
              {"Cookie: ${func(rngString)}"}
              <br />
              {"Cookie: ${func(sha256(<any_value>))}"}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClick(false)}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
