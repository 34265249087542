import React, { useState, useEffect } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import {
  Button,
  Typography,
  Snackbar,
  Stack,
  IconButton,
  List,
} from "@mui/material";
import Box from "@mui/material/Box";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import { TestRunMsg } from "../connect/slice/ws";

export const TestResultList = ({ msg }: { msg: TestRunMsg | null }) => {
  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        mx: "2px",
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap"
      }}
    >
      {msg?.data.length ? (
        <List sx={{ bgcolor: "background.paper" }}>
          {msg?.data.map((e, index) => (
            <Box
              sx={{
                borderRadius: 3,
                border: "0.5px solid rgba(0, 0, 0, 0.12)",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flex: 1,
                margin: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flex: 1,
                  margin: 2,
                }}
              >
                <Typography gutterBottom variant="h6">
                  Case {index + 1} <strong>{e.msgType}</strong>
                </Typography>
                <Box
                  sx={{
                    whiteSpace: "normal",
                    my: 2,
                    p: 1,
                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#101010" : "grey.100",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                    border: "1px solid",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                    borderRadius: 2,
                    fontSize: "0.875rem",
                    fontWeight: "700",
                    maxWidth: 500,
                    wordBreak: "break-word"
                  }}
                >
                  <Typography variant="subtitle2">request</Typography>
                  {e?.request ? atob(e?.request) : "empty"}
                </Box>
                <Box
                  sx={{
                    whiteSpace: "normal",
                    my: 2,
                    p: 1,
                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#101010" : "grey.100",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                    border: "1px solid",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                    borderRadius: 2,
                    fontSize: "0.875rem",
                    fontWeight: "700",
                    maxWidth: 500,
                    wordBreak: "break-word"
                  }}
                >
                  <Typography variant="subtitle2">response</Typography>
                  <Stack>
                    {Object.entries(e?.headers ?? {}).map(([key, value]) => (
                      <Typography variant="body2">{`${key}: ${value}`}</Typography>
                    ))}
                  </Stack>
                  {e?.response ? atob(e?.response) : "empty"}
                </Box>
                <Box
                  sx={{
                    whiteSpace: "normal",
                    my: 2,
                    p: 1,
                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#101010" : "grey.100",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                    border: "1px solid",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                    borderRadius: 2,
                    fontSize: "0.875rem",
                    fontWeight: "700",
                    maxWidth: 500,
                    wordBreak: "break-word"
                  }}
                >
                  <Typography variant="subtitle2">error</Typography>
                  {e?.error ? e.error : "empty"}
                </Box>
              </Box>
            </Box>
          ))}
        </List>
      ) : (
        <Box
          sx={{
            width: "100%",
            minHeight: 550,
            flexGrow: 1,
            mx: "2px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle1" align={"center"}>
            <Box
              sx={{
                marginTop: 10,
              }}
            />
            <AccountTreeRoundedIcon
              sx={{
                fontSize: 120,
                color: (theme) => theme.palette.text.secondary,
              }}
            />

            <Box
              sx={{
                fontWeight: "bold",
                margin: 4,
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              Test result list is empty
            </Box>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
