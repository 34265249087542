import { MenuItem } from "@mui/material";

export const MethodSelector = (data: Array<any>) => {
  if (!data.length) {
    return <MenuItem value="None">Method list is Empty</MenuItem>;
  }
  return data.map((v, i) => {
    return (
      <MenuItem key={i} value={v}>
        {v}
      </MenuItem>
    );
  });
};
