import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import SearchIcon from "@mui/icons-material/Search";

import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../dashboard/DeleteBotsDialog";
import { Box, IconButton, Link, Stack } from "@mui/material";
import { PayloadState, selectPayloads, setPayload } from "./slice/new";
import { MethodColor } from "./AddedPayloads";

export const ExpandCaseDialog = ({
  payload,
  caseNumber,
}: {
  payload: PayloadState;
  caseNumber: number;
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={handleClickOpen}
      >
        <SearchIcon fontSize="inherit" />
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <strong>Case {caseNumber + 1}</strong>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flex: 1,
              margin: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                flex: 1,
                margin: 2
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", color: MethodColor(payload?.method) }}
              >
                {payload?.method}{" "}
              </Typography>
              &nbsp;&nbsp;
              <Typography sx={{
                maxWidth: 500,
                wordBreak: "break-word"
              }}>{payload?.url}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flex: 1,
                margin: 2,
              }}
            >
              <Box
                sx={{
                  whiteSpace: "normal",
                  my: 2,
                  p: 1,
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#101010" : "grey.100",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                  borderRadius: 2,
                  fontSize: "0.875rem",
                  fontWeight: "700",
                }}
              >
                <Typography variant="subtitle2">headers</Typography>
                {Object.entries(payload?.headers).map((v, k) => (
                  <Typography
                    key={k}
                    variant="subtitle1"
                  >{`${k}: ${v}`}</Typography>
                ))}
              </Box>
              <Box
                sx={{
                  whiteSpace: "normal",
                  my: 2,
                  p: 1,
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#101010" : "grey.100",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                  borderRadius: 2,
                  fontSize: "0.875rem",
                  fontWeight: "700",
                  maxWidth: 500,
                  wordBreak: "break-word"
                }}
              >
                <Typography variant="subtitle2">body</Typography>
                {payload?.data}
              </Box>
              <Box
                sx={{
                  whiteSpace: "normal",
                  my: 2,
                  p: 1,
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#101010" : "grey.100",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                  borderRadius: 2,
                  fontSize: "0.875rem",
                  fontWeight: "700",
                }}
              >
                <Typography variant="subtitle2">catch</Typography>
                {payload?.catch?.map((v) => (
                  <Stack>
                    {v?.name ? (
                      <Typography variant="subtitle1">{`Name: ${v?.name}`}</Typography>
                    ) : (
                      ""
                    )}
                    {v?.location ? (
                      <Typography variant="subtitle1">{`Location: ${v?.location}`}</Typography>
                    ) : (
                      ""
                    )}
                    {v?.path ? (
                      <Typography variant="subtitle1">{`Path: ${v?.path}`}</Typography>
                    ) : (
                      ""
                    )}
                    {payload?.type === "websocket" ? (
                      <Stack>
                        {v?.wsReference ? (
                          <Typography variant="subtitle1">{`WS Reference: ${v?.wsReference}`}</Typography>
                        ) : (
                          ""
                        )}
                        {v?.wsReferenceId ? (
                          <Typography variant="subtitle1">{`WS Reference ID: ${v?.wsReferenceId}`}</Typography>
                        ) : (
                          ""
                        )}
                        {v?.wsReferenceTime ? (
                          <Typography variant="subtitle1">{`WS Reference Time: ${v?.wsReferenceTime}`}</Typography>
                        ) : (
                          ""
                        )}
                      </Stack>
                    ) : (
                      ""
                    )}
                  </Stack>
                ))}
                {payload?.type === "websocket" ? (
                  ""
                ) : (
                  <Typography variant="subtitle1">{`Status Code: ${payload?.status}`}</Typography>
                )}
              </Box>
              <Box
                sx={{
                  whiteSpace: "normal",
                  my: 2,
                  p: 1,
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#101010" : "grey.100",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                  borderRadius: 2,
                  fontSize: "0.875rem",
                  fontWeight: "700",
                }}
              >
                <Typography variant="subtitle2">delay</Typography>
                <Typography variant="subtitle1">
                  {payload?.delay ? (payload?.delay === "random" ? "random value from 1-10 seconds" : payload?.delay) : "No delay"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
