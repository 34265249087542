import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import connectReducer from '../features/connect/slice/connect';
import wsReducer from '../features/connect/slice/ws';
import campaignReducer from '../features/campaign/slice/campaign';
import salesReducer from '../features/contact/slice/sales';
import supportReducer from '../features/contact/slice/support';
import regionsReducer from '../features/campaign/slice/region';
import sectionReducer from '../features/section/slice/section';
import newCampaignReducer from '../features/campaign/slice/new';
import createCampaignReducer from '../features/campaign/slice/create';
import launchCampaignReducer from '../features/dashboard/slice/launch';
import profileReducer from "../features/profile/slice/profile";
import botsReducer from "../features/dashboard/slice/bots";
import payloadReducer from "../features/payloads/slice/payload";
import newPayloadReducer from "../features/payloads/slice/new";
import createPayloadReducer from "../features/payloads/slice/create";
import deleteCampaignReducer from "../features/dashboard/slice/campaign";
import statsReducer from "../features/dashboard/slice/stats";
import accountUsersReducer from "../features/users/slice/users";



export const store = configureStore({
  reducer: {
    counter: counterReducer,
    connect: connectReducer,
    regions: regionsReducer,
    campaign: campaignReducer,
    sales: salesReducer,
    support: supportReducer,
    section: sectionReducer,
    ws: wsReducer,
    newCampaign: newCampaignReducer,
    newPayload: newPayloadReducer,
    createPayload: createPayloadReducer,
    createCampaign: createCampaignReducer,
    launchCampaign: launchCampaignReducer,
    profile: profileReducer,
    bots: botsReducer,
    payload: payloadReducer,
    deleteCampaign: deleteCampaignReducer,
    stats: statsReducer,
    accountUsers: accountUsersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
