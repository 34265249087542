import * as React from "react";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ListItemButton } from "@mui/material";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { selectedStep, switchStep } from "./slice/section";
import { useAppSelector, useAppDispatch } from "../../app/hooks";


type CampaignProps = {
  changeView: (view: string) => void;
  changeCapmaign: (id: string) => void;
};

const selected = (b: boolean) => {
  if (b) {
    const style = {
      color: "rgb(138,180,248)",
      fontWeight: "bold",
    }
    return style
  }
  const style = {
    color: "rgba(255, 255, 255, 0.5)",
  }
  return style
}

export const MainListItems = () => {
  const dispatch = useAppDispatch();
  const name = useAppSelector(selectedStep);

  const sectionHandler = (event: string) => {
    const selectedName = event;
    dispatch(switchStep(selectedName));
  };

  return (
    <List>
      <ListItemButton
        selected={name === "campaigns" ? true : false}
        sx={{ mb: 1, paddingLeft: 5 }}
        onClick={() => sectionHandler("campaigns")}
      >
        <ListItemIcon>
          <DashboardRoundedIcon sx={selected(name === "campaigns")}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={selected(name === "campaigns")}  primary="Campaigns" />
      </ListItemButton>
      <ListItemButton
        selected={name === "payloads" ? true : false}
        sx={{ mb: 1, paddingLeft: 5 }}
        onClick={() => sectionHandler("payloads")}
      >
        <ListItemIcon>
          <AccountTreeRoundedIcon sx={selected(name === "payloads")} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={selected(name === "payloads")}  primary="Payloads" />
      </ListItemButton>
      <ListItemButton
        selected={name === "account Users" ? true : false}
        sx={{ mb: 1, paddingLeft: 5 }}
        onClick={() => sectionHandler("account Users")}
      >
        <ListItemIcon>
          <GroupAddIcon sx={selected(name === "account Users")} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={selected(name === "account Users")} primary="Account Users" />
      </ListItemButton>
      <ListItemButton
        selected={name === "profile" ? true : false}
        sx={{ mb: 1, paddingLeft: 5 }}
        onClick={() => sectionHandler("profile")}
      >
        <ListItemIcon>
          <Person2RoundedIcon sx={selected(name === "profile")} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={selected(name === "profile")} primary="Profile" />
      </ListItemButton>
    </List>
  );
};

export const SecondaryListItems = ({
  changeCapmaign,
  changeView,
}: CampaignProps) => (
  <div>
    <ListSubheader inset>Campaigns</ListSubheader>
    <ListItemButton
      onClick={() => {
        changeView("profile");
        changeCapmaign("123");
      }}
    >
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
