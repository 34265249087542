import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "../../app/hooks";
import Fab from "@mui/material/Fab";
import SupportAgentTwoToneIcon from "@mui/icons-material/SupportAgentTwoTone";
import Link from "@mui/material/Link";

import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../dashboard/DeleteBotsDialog";
import { contactSupport } from "./slice/support";

export const SupportDialog = ({ location }: { location: string }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderLocation = (location: string) => {
    switch (location) {
      case "footer":
        return (
          <Link
            onClick={handleClickOpen}
            variant="subtitle1"
            style={{ color: "rgba(0,0,0,0.54)" }}
            underline="hover"
          >
            Support
          </Link>
        );
      default:
        return (
          <Fab
            sx={{
              top: 300,
              right: 0,
              position: "fixed",
              borderRadius: 1,
            }}
            onClick={handleClickOpen}
          >
            <SupportAgentTwoToneIcon color="disabled" />
          </Fab>
        );
    }
  };

  return (
    <div>
      {renderLocation(location)}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Support
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Hello there. We are glad to help you. Please fill in the fields and
            we will reach you as soon as possible{" "}
          </Typography>
          &nbsp;
          <TextField
            fullWidth
            id="title"
            variant="outlined"
            color="info"
            type={"title"}
            label="Title"
            size="small"
            onChange={(e) => setTitle(e.target.value)}
          />
          &nbsp;
          <TextField
            fullWidth
            id="email"
            variant="outlined"
            color="info"
            type={"email"}
            label="User email"
            size="small"
            onChange={(e) => setEmail(e.target.value)}
          />
          &nbsp;
          <TextField
            fullWidth
            id="email"
            variant="outlined"
            color="info"
            label="Your message to us"
            multiline
            rows={5}
            maxRows={40}
            size="small"
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="success"
            autoFocus
            onClick={() => {
              dispatch(
                contactSupport({
                  email: email,
                  title: title,
                  message: message,
                })
              );
              handleClose();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
