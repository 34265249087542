import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Skeleton, Snackbar } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import ProfileCard from "./ProfileCard";
import LinearProgress from "@mui/material/LinearProgress";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  userError,
  accountError,
  clearError,
} from "./slice/profile";
import {
  connectError,
  firebaseSignout,
  selectIsCredentialsChanged,
  clearError as clearConnectError,
} from "../connect/slice/connect";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Profile() {
  const uError = useAppSelector(userError);
  const aError = useAppSelector(accountError);
  const cError = useAppSelector(connectError);
  const credentialsChanged = useAppSelector(selectIsCredentialsChanged);
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (aError || uError || cError) {
      setOpen(true);
    }
  }, [uError, aError, cError]);

  useEffect(() => {
    if (credentialsChanged) {
      dispatch(firebaseSignout());
    }
  }, [dispatch, credentialsChanged]);

  const handleErrorClose = () => {
    setOpen(false);
    dispatch(clearError(""));
    dispatch(clearConnectError(""));
  };

  return (
    <Box sx={{ flexGrow: 1, marginTop: 10 }}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          handleErrorClose();
        }}
      >
        <Alert
          onClose={() => handleErrorClose()}
          severity="error"
          sx={{ width: "100%" }}
        >
          {uError || aError || cError}
        </Alert>
      </Snackbar>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item display="flex" flex="1" justifyContent="center" xs={12}>
          <ProfileCard />
        </Grid>
      </Grid>
    </Box>
  );
}
