import React, { useState, useRef, PureComponent } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Button,
  Grid,
  Toolbar,
  Typography,
  Link,
  GlobalStyles,
  Container,
  ImageListItem,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ChartImage from "./chart.png";
import PayloadImage from "./payload.png";
import BotsImage from "./bots.png";
import CampaignsImage from "./campaigns.png";
import Paper from "@mui/material/Paper";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fafafa",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

interface CarouselItem {
  name: string;
  image: any;
  description: string;
}

const items: CarouselItem[] = [
  {
    name: "Realtime charts",
    image: ChartImage,
    description:
      "All Boty requests are happening in real-time and concurrently. Thus, all performance metrics are available during the test run and may provide rapid feedback.",
  },
  {
    name: "Realtime bot interactions",
    image: BotsImage,
    description:
      "You may start/stop campaigns/bots whenever you want. Boty provides full-scope bot orchestration, that is very close to real users or even botnet attacks.",
  },
  {
    name: "Payload constructor",
    image: PayloadImage,
    description:
      "Boty provides a super simple payload constructor. You may create sequential test cases, catch result and reuse it within the next requests. Every request has delay functionality which is useful if you want to mimic real users.",
  },
  {
    name: "Concurrency model by default",
    image: CampaignsImage,
    description:
      "All bots are running concurrently (and in parallel). Even a Developer subscription has two campaigns by default. You may run two campaigns in parallel and at the same time orchestrate (start/stop) bots without any limitations.",
  },
];

const Item = ({ i, e }: { i: number; e: CarouselItem }) => {
  return (
    <Box
      key={i}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ImageListItem key={i}>
        <img src={`${e?.image}?w=348&fit=crop&auto=format`} alt={"chart"} />
      </ImageListItem>
      <Box
        sx={{
          borderRadius: 3,
          marginTop: 2,
          border: "1px solid #4078b7",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="body1" sx={{ padding: 2 }}>
          {e?.description}
        </Typography>
      </Box>
    </Box>
  );
};

export const Feature = (
  aboutRef:
    | ((instance: HTMLElement | null) => void)
    | React.RefObject<HTMLElement>
    | null
    | undefined
) => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Container
      ref={aboutRef}
      maxWidth="lg"
      component="main"
      sx={{ pt: 18, pb: 6 }}
    >
      <Carousel
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: 600,
        }}
      >
        {items.map((item, i) => (
          <Stack>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
              id="about"
            >
              {item?.name}
            </Typography>
            <Item i={i} e={item} />
          </Stack>
        ))}
      </Carousel>
    </Container>
  );
};
