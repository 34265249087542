import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import NotStartedIcon from '@mui/icons-material/NotStarted';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Bot } from "./slice/bots";
import { launchTestRun, isTestRun } from "./slice/launch";
import BugReportIcon from '@mui/icons-material/BugReport';
import { log } from "console";

export const TestRun = ({
  bot,
}: {
  bot: Bot;
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const testRun = useAppSelector(isTestRun);
  const [testRunning, setTestRunning] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTestRunning(testRun)
  }, [testRun]);

  const handleStatus = (b: Bot) => {

    if (b?.status === "stopped" && b?.payload?.length > 0 && !testRunning) {
      return false
    }

    if (b?.status === "ready" && b?.payload?.length > 0 && !testRunning) {
      return false
    }

    return true
  }

  return (
    <div>
      <Button
        onClick={() => dispatch(launchTestRun({ botId: bot?.id }))}
        style={{ height: "30px", width: "120px" }}
        variant="outlined"
        color="success"
        disabled={handleStatus(bot)}
        sx={{
          margin: 1
        }}
        endIcon={<BugReportIcon />}
      >
        {testRunning ? "testing" : "test"}
      </Button>
    </div>
  );
};
