import React, { useState, useEffect } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { Button, Typography, Snackbar, Stack, IconButton } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import AutoSizer from "react-virtualized-auto-sizer";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { switchStep } from "../section/slice/section";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import InfiniteLoader from "react-window-infinite-loader";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  payloads,
  payloadStatus,
  payloadsError,
  getPayloads,
  clearPayloads,
  Payload,
} from "../payloads/slice/payload";
import { setPayload, selectedPayload } from "./slice/bots";
import { createPayloadError } from "../payloads/slice/create";
import { log } from "console";

const RenderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;

  const dispatch = useAppDispatch();
  const currentPayload = useAppSelector(selectedPayload);

  const e = data[index];

  return (
    <Box key={index} style={{ ...style }}>
      <Box
        sx={{
          borderRadius: 3,
          border: "0.5px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          maxHeight: 70,
          padding: 1.8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography variant="h5" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: `${currentPayload?.some((i: Payload) => i.id === e.id) ? "#673ab7" : "rgba(0,0,0,0.54)"
                  }`,
              }}
            >
              {e.name}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              ID: {e.id}
            </Box>
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            style={{
              color: `${currentPayload?.some((i: Payload) => i.id === e.id) ? "#673ab7" : "rgba(0,0,0,0.54)"
                }`,
              fontWeight: "bold",
            }}
            onClick={() => {

              if (currentPayload.filter(value => value?.id === e.id).length > 0) {
                const newPayload = (JSON.parse(JSON.stringify(currentPayload)) as Payload[]).filter(value => value?.id !== e.id);
                dispatch(setPayload(newPayload))
              } else {
                const newPayload = (JSON.parse(JSON.stringify(currentPayload)) as Payload[])
                newPayload.push(e);
                dispatch(setPayload(newPayload))
              }
            }}
            variant="outlined"
          >
            {currentPayload?.some((i: Payload) => i.id === e.id) ? "remove" : "select"}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

const LoadMore = (getBots: () => void, loading: boolean) => {
  return (
    <LoadingButton variant="text" loading={loading} onClick={() => getBots()}>
      Load more...
    </LoadingButton>
  );
};

export default function PayloadList() {
  const dispatch = useAppDispatch();

  const payloadsLoading = useAppSelector(payloadStatus);
  const pError = useAppSelector(payloadsError);
  const [errorOpen, setErrorOpen] = useState(false);
  const status = useAppSelector(payloadStatus);
  const [currentPayloads, setPayloads] = useState([] as Payload[]);
  const [open, setDialogOpen] = useState(false);
  const newPayloadError = useAppSelector(createPayloadError);
  const [newPayloadAlertOpen, setNewPayloadAlertOpen] = useState(false);

  const loadMore = () => {

    const startFetching = async () => {

      const { payload } = await dispatch(getPayloads(currentPayloads.length))
      if ((payload as Payload[]).length > 0) {
        setPayloads([...currentPayloads, ...payload]);
      }
    }

    startFetching();
  };

  useEffect(() => {
    let ignore = false

    const startFetching = async () => {

      const { payload } = await dispatch(getPayloads(0))
      if (!ignore) {
        if ((payload as Payload[]).length > 0) {
          setPayloads(payload);
        }
      }
    }

    startFetching();

    return () => { ignore = true }

  }, [dispatch]);


  useEffect(() => {
    if (newPayloadError) {
      setNewPayloadAlertOpen(true);
    }
  }, [newPayloadError]);

  useEffect(() => {
    if (pError) {
      setErrorOpen(true);
    }
  }, [pError]);

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        mx: "2px",
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {currentPayloads.length ? (
        <Box>
          <TableContainer style={{ height: "calc(50vh)" }}>
            <AutoSizer>
              {({ height, width }) => (
                <VariableSizeList
                  itemData={currentPayloads}
                  height={height}
                  width={width}
                  itemSize={() => 75}
                  itemCount={currentPayloads.length}
                  overscanCount={5}
                >
                  {RenderRow}
                </VariableSizeList>
              )}
            </AutoSizer>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {currentPayloads.length >= 10
              ? LoadMore(loadMore, status === "loading" ? true : false)
              : ""}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            minHeight: 550,
            flexGrow: 1,
            mx: "2px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle1" align={"center"}>
            <Box
              sx={{
                marginTop: 10,
              }}
            />
            <AccountTreeRoundedIcon
              sx={{
                fontSize: 120,
                color: (theme) => theme.palette.text.secondary,
              }}
            />

            <Box
              sx={{
                fontWeight: "bold",
                margin: 4,
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              Payloads list is empty. Please add payloads first.
            </Box>
          </Typography>
        </Box>
      )}
    </Box>
  );
}
