import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { List } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import {
  selectBots,
  selectName,
  removeBot,
  NewCampaignBotState,
} from "./slice/new";
import { createCampaignStatus } from "./slice/create";
import { create } from "./slice/create";
import { selectedAccount } from "../profile/slice/profile";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const listItems = (
  data: Array<any>,
  remove: (bot: NewCampaignBotState) => void
) => {
  if (!data.length) {
    return (
      <Typography variant="subtitle1" align={"center"}>
        <Box
          sx={{
            marginTop: 10,
          }}
        />
        <ListAltRoundedIcon
          sx={{ fontSize: 120, color: (theme) => theme.palette.text.secondary }}
        />
        <Box
          sx={{
            fontWeight: "bold",
            margin: 4,
            color: (theme) => theme.palette.text.disabled,
          }}
        >
          Bot list is empty. Please add campaign Name and bot configuration
          first.
        </Box>
      </Typography>
    );
  }

  return data.map((e, i) => (
    <Paper
      key={i}
      elevation={2}
      sx={{
        margin: 1,
        borderRadius: 3,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#fafafa",
        minWidth: 210,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          flex: 0.8,
        }}
      >
        <Typography variant="h6" align={"center"}>
          <Box
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            {e.regionName}
          </Box>
          <Box
            sx={{
              color: "#bdbdbd",
              fontWeight: "bold",
              fontSize: 10,
            }}
          >
            region
          </Box>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          flex: 1,
          borderLeft: "0.5px solid",
          borderColor: "rgba(0, 0, 0, 0.12)",
          backgroundColor: "#fff",
          borderRadius: 3,
          alignItems: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            align={"center"}
            sx={{
              margin: 1,
              display: "flex",
              flex: 0.5,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
              }}
            >
              {e.amount}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              bots
            </Box>
          </Typography>
          <Typography
            variant="subtitle2"
            align={"center"}
            sx={{
              margin: 1,
              display: "flex",
              flex: 0.5,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
              }}
            >
              {e.vUsers}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              users
            </Box>
          </Typography>
          <Typography
            variant="subtitle2"
            align={"center"}
            sx={{
              margin: 1,
              display: "flex",
              flex: 0.5,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
              }}
            >
              {e.duration}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              duration
            </Box>
          </Typography>
          <Typography
            variant="subtitle2"
            align={"center"}
            sx={{
              margin: 0.5,
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
              }}
            >
              {e.rampUp}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              rump-up
            </Box>
          </Typography>
          <Typography
            variant="subtitle2"
            align={"center"}
            sx={{
              margin: 1,
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              {e?.startAt
                ? new Date(e?.startAt).toLocaleString()
                : "Scheduler is not defined"}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              start at
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              remove(e);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  ));
};

export default function CampaignBots() {
  const dispatch = useAppDispatch();

  const bots = useAppSelector(selectBots);
  const name = useAppSelector(selectName);
  const currentAccount = useAppSelector(selectedAccount);
  const isLoading = useAppSelector(createCampaignStatus);

  const removeBotFromList = (bot: NewCampaignBotState) => {
    const newBots = bots.filter((e) => e !== bot);
    dispatch(removeBot(newBots));
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        flexGrow: 1,
        mx: "2px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: 550,
          flexGrow: 1,
          mx: "2px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5">
          <Box
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.dark,
            }}
          >
            Instances
          </Box>
        </Typography>
        <List
          sx={{
            overflow: "auto",
          }}
        >
          {listItems(bots, removeBotFromList)}
        </List>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 10,
          mx: "2px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isLoading === "loading" ? true : false}
          loadingPosition="end"
          disabled={bots.length ? false : true}
          onClick={() =>
            dispatch(
              create({
                bots: bots,
                name: name,
                accountId: currentAccount?.id,
              })
            )
          }
        >
          Create {name} Campaign
        </LoadingButton>
      </Box>
    </Box>
  );
}
