import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import VirtualizedList from "./CampaignList";
import OverAllCard from "./OverAllCard";
import RunningCard from "./RunningCard";
import FinishedCard from "./FinishedCard";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Snackbar } from "@mui/material";
import { clearError, getCampaignsCount } from "./slice/campaign";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { campaignsError } from "./slice/campaign";
import { switchStep } from "../section/slice/section";
import TrafficCard from "./TrafficCard";
import { sentTraffic, selectedAccount } from "../profile/slice/profile";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Campaigns() {
  const dispatch = useAppDispatch();
  const [init, setInit] = useState(false);
  const currentAccount = useAppSelector(selectedAccount);
  const currentCampaignError = useAppSelector(campaignsError);

  const [open, setOpen] = useState(false);

  const sectionHandler = (event: string) => {
    const selectedName = event;
    dispatch(switchStep(selectedName));
  };

  useEffect(() => {
    if (!init) {
      dispatch(getCampaignsCount());
    }
    setInit(true);
  }, [dispatch, init]);

  useEffect(() => {
    if (currentAccount?.id) {
      dispatch(sentTraffic(currentAccount?.id));
    }
  }, [dispatch, currentAccount?.id]);

  useEffect(() => {
    if (currentCampaignError) {
      setOpen(true);
    }
  }, [currentCampaignError]);

  const handleErrorClose = () => {
    setOpen(false);
    dispatch(clearError(""));
  };

  return (
    <Box sx={{ flexGrow: 1, marginTop: 10 }}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          handleErrorClose();
        }}
      >
        <Alert
          onClose={() => handleErrorClose()}
          severity="error"
          sx={{ width: "100%" }}
        >
          {currentCampaignError}
        </Alert>
      </Snackbar>
      <Grid container flex={1} spacing={2}>
        <Grid item display={"flex"} xs={12}>
          <OverAllCard></OverAllCard>
          <RunningCard></RunningCard>
          <FinishedCard></FinishedCard>
          <TrafficCard></TrafficCard>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"} xs={12}>
          <VirtualizedList />
        </Grid>
      </Grid>
      <Fab
        color="primary"
        aria-label="add"
        size="large"
        onClick={() => sectionHandler("campaign")}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(7),
          right: (theme) => theme.spacing(5),
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}
