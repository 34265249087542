import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { List, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { PayloadState, selectCurrentPayload, setCurrentPayload } from "./slice/new";

export const RenderHeaders = () => {
  
  const dispatch = useAppDispatch();
  const currentPayload = useAppSelector(selectCurrentPayload);
  
  return (
    <List
      sx={{
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      {Object.entries((currentPayload as PayloadState).headers).map(([key, value]) => (
        <ListItem key={key} disableGutters>
          <Box
            sx={{
              display: "flex",
              borderRadius: 3,
              border: "0.5px solid rgba(0, 0, 0, 0.12)",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#fafafa",
              maxHeight: 50,
              padding: 1.8,
              margin: 1,
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flexGrow: 1,
              }}
            >
              <Typography variant="body2" align={"center"}>
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {key}
                </Box>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flexGrow: 1,
              }}
            >
              <Typography variant="body2" align={"center"}>
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {value}
                </Box>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                align={"center"}
                sx={{ margin: 1 }}
              >
                <IconButton
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    const newPayload = JSON.parse(JSON.stringify(currentPayload));
                    delete (newPayload as PayloadState)?.headers[`${key}`];
                    dispatch(setCurrentPayload(newPayload));
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};
