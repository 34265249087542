import { useState, useEffect } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import {
  Typography,
  Stack,
  IconButton,
  Skeleton,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import AutoSizer from "react-virtualized-auto-sizer";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { switchStep } from "../section/slice/section";
import Box from "@mui/material/Box";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  payloadStatus,
  payloadsError,
  getPayloads,
  deletedPayload,
  Payload,
  clearDeletedPayload,
} from "./slice/payload";
import {
  createPayloadError,
  selectCreatePayloadResult,
} from "./slice/create";
import { DeletePayloadDialog } from "./DeletePayloadDialog";
import {
  PayloadState,
  setPayload,
  setName,
  setEditId,
  setWSUrl,
  setGlobalVariables,
} from "./slice/new";

const RenderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dispatch = useAppDispatch();

  const e = data[index];

  return (
    <Box key={index} style={{ ...style }}>
      <Box
        sx={{
          borderRadius: 3,
          border: "0.5px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          maxHeight: 70,
          padding: 1.8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography variant="h5" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e.name}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              ID: {e.id}
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {new Date(e.created).toLocaleString()}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              created at
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {new Date(e.updated).toLocaleString()}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              updated at
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e.accountId}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              account ID
            </Box>
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <IconButton
            onClick={() => {
              const data = JSON.parse(atob(e?.data)) as PayloadState[];
              data.forEach((v) => {
                if (v?.type === "websocket") {
                  dispatch(setWSUrl(v?.url));
                  return;
                }
              });
              const vars = e?.variables ? JSON.parse(atob(e?.variables)) as { [key: string]: string } : {}
              dispatch(setPayload(data));
              dispatch(setGlobalVariables(vars));
              dispatch(setName(e?.name));
              dispatch(setEditId(e?.id));
              dispatch(switchStep("payload"));
            }}
            aria-label="payload"
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <DeletePayloadDialog payload={e} />
        </Stack>
      </Box>
    </Box>
  );
};

const LoadMore = (getBots: () => void, loading: boolean) => {
  return (
    <LoadingButton variant="text" loading={loading} onClick={() => getBots()}>
      Load more...
    </LoadingButton>
  );
};

const Loading = (b: boolean) => {
  if (b) {
    return (
      <TableContainer style={{ height: "calc(68.3vh)", marginBottom: 37 }}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
        </Box>
      </TableContainer>
    );
  }
  return (
    <TableContainer style={{ height: "calc(68.3vh)", marginBottom: 37 }}>
      <Box
        sx={{
          flex: 1,
          flexGrow: 1,
          height: "100%",
          mx: "2px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1" align={"center"}>
          <Box
            sx={{
              marginTop: 10,
            }}
          />
          <AccountTreeRoundedIcon
            sx={{
              fontSize: 120,
              color: (theme) => theme.palette.text.secondary,
            }}
          />

          <Box
            sx={{
              fontWeight: "bold",
              margin: 4,
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            Payloads list is empty. Please add by using action button below.
          </Box>
        </Typography>
      </Box>
    </TableContainer>
  );
};

export default function PayloadsList() {
  const dispatch = useAppDispatch();

  const pError = useAppSelector(payloadsError);
  const [errorOpen, setErrorOpen] = useState(false);
  const status = useAppSelector(payloadStatus);
  const lastCreated = useAppSelector(selectCreatePayloadResult);
  const lastDeletedPayload = useAppSelector(deletedPayload);
  const [currentPayloads, setPayloads] = useState([] as Payload[]);
  const newPayloadError = useAppSelector(createPayloadError);
  const [newPayloadAlertOpen, setNewPayloadAlertOpen] = useState(false);

  const loadMore = () => {
    const startFetching = async () => {
      const { payload } = await dispatch(getPayloads(currentPayloads.length));
      if ((payload as Payload[]).length > 0) {
        setPayloads([...currentPayloads, ...payload]);
      }
    };

    startFetching();
  };

  useEffect(() => {
    let ignore = false;

    const startFetching = async () => {
      const { payload } = await dispatch(getPayloads(0));
      if (!ignore) {
        if ((payload as Payload[]).length > 0) {
          setPayloads(payload);
        }
      }
    };

    startFetching();

    return () => {
      ignore = true;
    };
  }, [dispatch]);

  useEffect(() => {
    if (newPayloadError) {
      setNewPayloadAlertOpen(true);
    }
  }, [newPayloadError]);

  useEffect(() => {
    if (pError) {
      setErrorOpen(true);
    }
  }, [pError]);

  useEffect(() => {
    if (lastCreated?.referenceId) {
      const startFetching = async () => {
        const { payload } = await dispatch(getPayloads(0));
        if ((payload as Payload[]).length > 0) {
          setPayloads(payload);
        }
      };

      startFetching();
    }
  }, [dispatch, lastCreated?.referenceId]);

  useEffect(() => {
    if (lastDeletedPayload) {
      const newArr = currentPayloads.filter((v) => v.id !== lastDeletedPayload);
      const startFetching = async () => {
        const { payload } = await dispatch(getPayloads(newArr.length));
        setPayloads([...newArr, ...payload as Payload[]]);
      };

      startFetching();
      dispatch(clearDeletedPayload(""));
    }
  }, [dispatch, lastDeletedPayload]);

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        mx: "2px",
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {currentPayloads.length ? (
        <TableContainer style={{ height: "calc(68.3vh)", marginBottom: currentPayloads.length < 10 ? 37 : 0 }}>
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                itemData={currentPayloads}
                height={height}
                width={width}
                itemSize={() => 75}
                itemCount={currentPayloads.length}
                overscanCount={5}
              >
                {RenderRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        </TableContainer>
      ) : (
        Loading(status === "loading")
      )}
      {currentPayloads.length >= 10
        ? LoadMore(loadMore, status === "loading" ? true : false)
        : ""}
    </Box>
  );
}
