import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Chart from "./Chart";
import Divider from "@mui/material/Divider";
import { campaign } from "../campaign/slice/campaign";
import { getRPSStats, getStats, statsStatus, rps, Rps } from "./slice/stats";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import LoadingButton from "@mui/lab/LoadingButton";

const formatNumber = (v: string) => {
  const n = Number(v);
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export default function ChartSection() {
  const dispatch = useAppDispatch();
  const [init, setInit] = useState(false);
  const stat = useAppSelector(rps);
  const [currentStat, setCurrentStat] = useState({} as Rps);
  const campaignData = useAppSelector(campaign);
  const loading = useAppSelector(statsStatus);

  useEffect(() => {
    let ignore = false;

    if (!init && campaignData?.status !== "running") {
      if (ignore) return;
      dispatch(
        getRPSStats({
          id: campaignData?.id,
        })
      );
      setInit(true);
    }

    return () => {
      ignore = true;
    };

  }, [init, dispatch, campaignData?.id]);

  useEffect(() => {
    if (
      campaignData?.id === stat?.campaignId
    ) {
      setCurrentStat(stat);
    }
  }, [dispatch, campaignData?.id, stat]);

  return (
    <Paper
      elevation={2}
      sx={{
        flexGrow: 1,
        mx: "2px",
        marginBottom: 5,
        display: "flex",
        flexDirection: "row",
        marginTop: 0,
      }}
    >
      <Box
        sx={{
          mx: "2px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            flexDirection: "column",
            display: "flex",
            flex: 1,
          }}
        >
          <Box sx={{}}>
            <Box sx={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
              <Typography
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  alignContent: "center",
                }}
                variant="h6"
              >
                <Box
                  sx={{
                    fontWeight: "bold",
                    margin: 2,
                    color: (theme) => theme.palette.primary.dark,
                    textTransform: "uppercase",
                  }}
                >
                  Performance overview
                </Box>
              </Typography>
              <Typography
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  alignContent: "center",
                }}
                variant="h6"
              >
                <Box
                  sx={{
                    fontWeight: "bold",
                    margin: 2,
                    color: (theme) => theme.palette.primary.dark,
                    textTransform: "uppercase",
                  }}
                >
                  <LoadingButton
                    variant="outlined"
                    color="secondary"
                    style={{ maxHeight: "40px" }}
                    sx={{ fontWeight: "bold" }}
                    loading={loading === "loading" ? true : false}
                    loadingPosition="end"
                    disabled={loading === "loading" ? true : false}
                    onClick={() => {
                      dispatch(
                        getStats({
                          id: campaignData?.id,
                          from: campaignData?.startedAt
                            ? new Date(campaignData?.startedAt).getTime()
                            : 0,
                        })
                      );
                      dispatch(
                        getRPSStats({
                          id: campaignData?.id,
                        })
                      );
                    }}
                  >
                    <CachedOutlinedIcon
                      sx={{ color: (theme) => theme.palette.primary.dark }}
                    />
                  </LoadingButton>
                </Box>
              </Typography>
            </Box>
            <Divider />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
              margin: 2,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                borderRight: "0.5px solid",
                borderColor: "#5e6e82",
              }}
            >
              <Typography variant="subtitle2">
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.primary.dark,
                    textTransform: "uppercase",
                  }}
                >
                  REQUESTS MADE
                </Box>
              </Typography>
              <Typography variant="h5">
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.secondary.main,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                >
                  <Typography variant="h4">
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.secondary.main,
                      }}
                    >
                      {formatNumber(currentStat?.requests ?? 0)}
                    </Box>
                  </Typography>
                  <Typography align="justify">
                    <Box
                      sx={{
                        color: (theme) => theme.palette.text.disabled,
                      }}
                    >
                      &nbsp;reqs
                    </Box>
                  </Typography>
                </Box>
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                borderRight: "0.5px solid",
                borderColor: "#5e6e82",
                marginLeft: 2,
              }}
            >
              <Typography variant="subtitle2">
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.primary.dark,
                    textTransform: "uppercase",
                  }}
                >
                  FAILURES
                </Box>
              </Typography>
              <Typography variant="h5">
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.secondary.main,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                >
                  <Typography variant="h4">
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.secondary.main,
                      }}
                    >
                      {formatNumber(currentStat?.errors ?? 0)}
                    </Box>
                  </Typography>
                  <Typography align="justify">
                    <Box
                      sx={{
                        color: (theme) => theme.palette.text.disabled,
                      }}
                    >
                      &nbsp;reqs
                    </Box>
                  </Typography>
                </Box>
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                borderRight: "0.5px solid",
                borderColor: "#5e6e82",
                marginLeft: 2,
              }}
            >
              <Typography variant="subtitle2">
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.primary.dark,
                    textTransform: "uppercase",
                  }}
                >
                  AVG RPS
                </Box>
              </Typography>
              <Typography variant="h5">
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.secondary.main,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                >
                  <Typography variant="h4">
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.secondary.main,
                      }}
                    >
                      {formatNumber(currentStat?.avgRps ?? 0)}
                    </Box>
                  </Typography>
                  <Typography align="justify">
                    <Box
                      sx={{
                        color: (theme) => theme.palette.text.disabled,
                      }}
                    >
                      &nbsp;rps
                    </Box>
                  </Typography>
                </Box>
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                marginLeft: 2,
              }}
            >
              <Typography variant="subtitle2">
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.primary.dark,
                    textTransform: "uppercase",
                  }}
                >
                  AVG RESPONSE TIME
                </Box>
              </Typography>
              <Typography variant="h5">
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: (theme) => theme.palette.secondary.main,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                >
                  <Typography variant="h4">
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.secondary.main,
                      }}
                    >
                      {currentStat?.avgRt ? currentStat?.avgRt : 0}
                    </Box>
                  </Typography>
                  <Typography align="justify">
                    <Box
                      sx={{
                        color: (theme) => theme.palette.text.disabled,
                      }}
                    >
                      &nbsp;ms
                    </Box>
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Chart />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
