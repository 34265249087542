import { useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import PayloadList from "./PayloadList";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "./DeleteBotsDialog";
import {
  applyCampaignPayload,
  selectedPayload,
} from "./slice/bots";
import { campaign } from "../campaign/slice/campaign";

export const CampaignPayloadDialog = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const currentPayload = useAppSelector(selectedPayload);
  const campaignData = useAppSelector(campaign);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{ maxHeight: "40px" }}
        variant="outlined"
        color="primary"
        disabled={disabled}
        sx={{
          fontWeight: "bold",
        }}
      >
        campaign payload
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Campaign Payload
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Please select particular payload and press apply. Selected payload
            will be applied for all bots in the campaign.
          </Typography>
          <PayloadList />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="secondary"
            onClick={() => {
              const ids = currentPayload.map(i => i?.id);

              dispatch(
                applyCampaignPayload({
                  campaignId: campaignData.id,
                  payload: ids,
                })
              );
              handleClose();
            }}
            autoFocus
          >
            Apply
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
