import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { HTTP } from '../../../helper/http';
import { NewPayloadState } from "./new";

export interface NewPayloadResponseState {
    data: NewPayloadOutState | null;
    status: 'idle' | 'loading' | 'failed';
    error: string;
}

export interface NewPayloadOutState {
    type: string;
    referenceId: string;
    data: NewPayloadOutDataState | null;
}

export interface NewPayloadOutDataState {
    id: string;
    created: string;
    updated: string;
    name: string;
    accountId: string;
    payloads: NewPayloadOutPayloadsState[];
}

export interface NewPayloadOutPayloadsState {
    type: string;
    method: string;
    headers: {};
    url: string;
    data: string;
    catch: NewPayloadOutPayloadsCatchState[];
}

export interface NewPayloadOutPayloadsCatchState {
    location: string;
    status: string;
    name: string;
    path: string;
    wsReferenceId: string;
    wsReferenceTime: string;
}

const initialState: NewPayloadResponseState = {
    data: null,
    status: "idle",
    error: ""
}


export const create = createAsyncThunk(
    'payload/Create',
    async (p: NewPayloadState) => {
        const payload =
        {
            "type": "payload",
            "data": {
                "accountId": p.accountId,
                "variables": p.globalVariables,
                "name": p.name,
                "payloads": p.data
            }
        }

        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/payload`,
                {
                    method: "POST",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        'Content-Type': `application/json`
                    },
                    body: JSON.stringify(payload)
                }
            )
        );
        if (response.status !== 201) {
            throw await response.json()
        }
        return await response.json()
    }
);

export const update = createAsyncThunk(
    'payload/Update',
    async (p: NewPayloadState) => {
        const payload =
        {
            "type": "payload",
            "data": {
                "accountId": p.accountId,
                "variables": p.globalVariables,
                "name": p.name,
                "payloads": p.data
            }
        }

        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/payload/${p.editId}`,
                {
                    method: "PUT",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        'Content-Type': `application/json`
                    },
                    body: JSON.stringify(payload)
                }
            )
        );
        if (response.status !== 200) {
            throw await response.json()
        }
        return await response.json()
    }
);

export const createPayloadSlice = createSlice({
    name: 'create/Payload',
    initialState,
    reducers: {
        clearError: (state, action) => {
            state.error = action.payload;
        },
        clearCreatedPayload: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(create.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = 'idle';
                state.error = "";
            })
            .addCase(create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to create payload. Error:  ${action.error.message}`;
            })
            .addCase(update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(update.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = 'idle';
                state.error = "";
            })
            .addCase(update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to create payload. Error:  ${action.error.message}`;
            })
    }
});

export const { clearError, clearCreatedPayload } = createPayloadSlice.actions;

export const selectCreatePayloadResult = (state: RootState) => state.createPayload.data;
export const createPayloadError = (state: RootState) => state.createPayload.error;
export const createPayloadStatus = (state: RootState) => state.createPayload.status;


export default createPayloadSlice.reducer;
