import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';
import { RootState, AppThunk } from '../../../app/store';
import { HTTP } from '../../../helper/http';
import { Campaign } from "../../campaign/slice/campaign";
import payload from '../../payloads/slice/payload';

export interface LaunchCampaignState {
    status: 'idle' | 'loading' | 'failed';
    test: boolean;
    start: StartState;
    error: string;
    campaign: Campaign | undefined;
}

export interface StartState {
    id: string;
    start: boolean;
}


const initialState: LaunchCampaignState = {
    status: "idle",
    test: false,
    start: {} as StartState,
    campaign: undefined,
    error: ""
}


export const launchCampaign = createAsyncThunk(
    'campaign/launchCampaign',
    async (p: StartState) => {

        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/${p.id}?start=${p.start}`,
                {
                    method: "POST",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                }
            )
        );
        if (response.status !== 200 && response.status !== 201) {
            const err = await response.json()
            throw err
        }
        return await response.json()
    }
);

export const launchBot = createAsyncThunk(
    'campaign/launchBot',
    async (p: {id:string, start:boolean, botId: string}) => {

        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/${p.id}/bot/${p.botId}?start=${p.start}`,
                {
                    method: "POST",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                }
            )
        );
        if (response.status !== 200 && response.status !== 201) {
            const err = await response.json()
            throw err
        }
        return await response.json()
    }
);

export const launchTestRun = createAsyncThunk(
    'campaign/launchTestRun',
    async (p: {botId: string}) => {

        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/bot/${p.botId}/test`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                }
            )
        );
        if (response.status !== 200 && response.status !== 201) {
            let err = await response.text()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.text()
    }
);

export const campaignLaunchSlice = createSlice({
    name: 'launchCampaign',
    initialState,
    reducers: {
        clearLaunchedCampaign: (state, action) => {
            state.campaign = undefined;
        },
        setLaunchedCampaign: (state, action) => {
            state.campaign = action.payload;
        },
        clearError: (state, action) => {
            state.error = "";
        },
        runTest: (state, action) => {
            state.test = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(launchCampaign.pending, (state) => {
                state.status = 'loading';
                state.test = false;
            })
            .addCase(launchCampaign.fulfilled, (state, action) => {
                state.status = 'idle';
                state.campaign = action.payload;
                state.error = "";
                state.test = false;
            })
            .addCase(launchCampaign.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to launch campaign. Error:  ${action.error.message}`;
                state.test = false;
            })
            .addCase(launchBot.pending, (state) => {
                state.status = 'loading';
                state.test = false;
            })
            .addCase(launchBot.fulfilled, (state, action) => {
                state.status = 'idle';
                state.campaign = action.payload;
                state.error = "";
                state.test = false;
            })
            .addCase(launchBot.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to launch the Bot. Error:  ${action.error.message}`;
                state.test = false;
            })
            .addCase(launchTestRun.pending, (state) => {
                state.status = 'loading';
                state.test = true;
            })
            .addCase(launchTestRun.fulfilled, (state, action) => {
                state.status = 'idle';
                state.error = "";
            })
            .addCase(launchTestRun.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to launch test run. Error:  ${action.error.message}`;
            })
    }
});

export const { clearLaunchedCampaign, setLaunchedCampaign, clearError, runTest } = campaignLaunchSlice.actions;

export const launchCampaignError = (state: RootState) => state.launchCampaign.error;
export const launchedCampaign = (state: RootState) => state.launchCampaign.campaign;
export const launchCampaignStatus = (state: RootState) => state.launchCampaign.status;
export const isTestRun = (state: RootState) => state.launchCampaign.test;




export default campaignLaunchSlice.reducer;
