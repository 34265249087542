import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import { traffic } from "../profile/slice/profile";
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import { useAppSelector, useAppDispatch } from "../../app/hooks";

export const FormatBytes = (bytes: number, decimals = 2) => {
  
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


export default function TrafficCard() {
  
  const data = useAppSelector(traffic);
  
  return (
    <Paper
      elevation={2}
      sx={{
        flexGrow: 1,
        mx: "2px",
        backgroundColor: "#fff",
        marginTop: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        borderRadius: 5
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          mx: "2px",
          backgroundColor: "#fff",
          display: "flex",
          margin: 2,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: 10,
          }}
          color={(theme) => theme.palette.text.primary}
        >
          account traffic
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
          }}
          variant="h4"
          color={(theme) => theme.palette.text.primary}
        >
          {FormatBytes(parseInt(data ?? 0))}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          mx: "2px",
          display: "flex",
          margin: 2,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Avatar
          sx={{
            width: 60,
            height: 60,
            backgroundColor: (theme) => theme.palette.secondary.dark,
          }}
        >
          <RouteOutlinedIcon
            sx={{
              fontSize: 40,
              textAlign: "center",
              color: "#fff",
            }}
          />
        </Avatar>
      </Box>
    </Paper>
  );
}
