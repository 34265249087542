import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Snackbar, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import PayloadsList from "./List";
import {
  payloads,
  payloadStatus,
  payloadsError,
  clearError,
} from "./slice/payload";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { switchStep } from "../section/slice/section";
import { setEditId, setGlobalVariables, setName, setPayload } from "./slice/new";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Payloads() {
  const dispatch = useAppDispatch();

  const pError = useAppSelector(payloadsError);
  const [errorOpen, setErrorOpen] = useState(false);

  const handleErrorClose = () => {
    setErrorOpen(false);
    dispatch(clearError(""));
  };

  const sectionHandler = (event: string) => {
    const selectedName = event;
    dispatch(setPayload([]));
    dispatch(setName(""));
    dispatch(setEditId(""));
    dispatch(switchStep(selectedName));
  };

  useEffect(() => {
    if (pError) {
      setErrorOpen(true);
    }
  }, [pError]);

  return (
    <Box sx={{ flexGrow: 1, marginTop: 10}}>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          handleErrorClose();
        }}
      >
        <Alert
          onClose={() => handleErrorClose()}
          severity="error"
          sx={{ width: "100%" }}
        >
          {pError}
        </Alert>
      </Snackbar>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid
          item
          display="flex"
          flex="1"
          justifyContent="center"
          xs={12}
        >
          <PayloadsList />
        </Grid>
      </Grid>
      <Fab
        color="primary"
        aria-label="add"
        size="large"
        onClick={() => {
          sectionHandler("payload")
          dispatch(setGlobalVariables({}));
        }
        }
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(7),
          right: (theme) => theme.spacing(5),
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}
