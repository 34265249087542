import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { HTTP } from '../../../helper/http';
import { NewCampaignBotsState, NewCampaignState } from "./new";

export interface NewCampaignResponseState {
  data: NewCampaignOutState | null;
  status: 'idle' | 'loading' | 'failed';
  error: string;
}


export interface NewCampaignOutState {
  type: string;
  referenceId: string;
  data: NewCampaignOutDataState | null;
}

export interface NewCampaignOutDataState {
  id: string;
  created: string;
  updated: string;
  name: string;
  ownerId: string;
  status: string;
  deployments: NewCampaignOutDeploymentsState[];
}

export interface NewCampaignOutDeploymentsState {
  type: string;
  referenceId: string;
  data: NewCampaignOutDeploymentsDataState[];
  error: string;
}

export interface NewCampaignOutDeploymentsDataState {
  id: string;
  created: string
  updated: string;
  campaignId: string;
  accountId: string;
  userId: string;
  instanceId: string;
  regionId: string;
  ip: string;
  startAt: string;
  vUsers: Number;
  status: string;
}

const initialState: NewCampaignResponseState = {
  data: null,
  status: "idle",
  error: ""
}


export const create = createAsyncThunk(
  'campaign/Create',
  async (p: NewCampaignState) => {
    const payload =
    {
      "type": "campaign",
      "data": {
        "accountId": p.accountId,
        "name": p.name,
        "bots": p.bots
      }
    }

    const response = await HTTP(
      new Request(
        `${process.env.REACT_APP_URL}/campaign`,
        {
          method: "POST",
          redirect: "follow",
          mode: "cors",
          credentials: "include",
          headers: {
            'Content-Type': `application/json`
          },
          body: JSON.stringify(payload)
        }
      )
    );
    if (response.status !== 201) {
      throw await response.json()
    }
    return await response.json()
  }
);

export const addBotsToCampaign = createAsyncThunk(
  'campaign/addBotsToCampaign',
  async (p: {id:string, data: NewCampaignBotsState}) => {
    const payload =
    {
      "type": "campaign",
      "data": {
        "accountId": p.data.accountId,
        "bots": p.data.bots
      }
    }

    const response = await HTTP(
      new Request(
        `${process.env.REACT_APP_URL}/campaign/${p.id}/bots`,
        {
          method: "POST",
          redirect: "follow",
          mode: "cors",
          credentials: "include",
          headers: {
            'Content-Type': `application/json`
          },
          body: JSON.stringify(payload)
        }
      )
    );
    if (response.status !== 201) {
      throw await response.json()
    }
    return await response.json()
  }
);

export const createCampaignSlice = createSlice({
  name: 'createCampaign',
  initialState,
  reducers: {
    clearResult: (state, action) => {
      state.data = null;
    },
    clearError: (state, action) => {
      state.error = "";
    },
    setResult: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(create.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'idle';
        state.error = "";
      })
      .addCase(create.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Unable to create campaign. Error:  ${action.error.message}`;
      })
      .addCase(addBotsToCampaign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addBotsToCampaign.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'idle';
        state.error = "";
      })
      .addCase(addBotsToCampaign.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Unable to update campaign bots. Error:  ${action.error.message}`;
      })
  }
});

export const { clearResult, clearError, setResult } = createCampaignSlice.actions;


export const createCampaignResult = (state: RootState) => state.createCampaign.data;
export const createCampaignError = (state: RootState) => state.createCampaign.error;
export const createCampaignStatus = (state: RootState) => state.createCampaign.status;


export default createCampaignSlice.reducer;
