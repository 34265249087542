import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { HTTP } from '../../../helper/http';

export interface NewSalesState {
  data: SalesData | null;
  status: 'idle' | 'loading' | 'failed';
  error: string;
}

export interface SalesData {
    email: string;
    name: string;
    company: string;
    message: string;
  }


const initialState: NewSalesState = {
  data: null,
  status: "idle",
  error: ""
}


export const contactSales = createAsyncThunk(
  'campaign/contactSales',
  async (p: SalesData) => {
    const payload =
    {
        "email": p.email,
        "name": p.name,
        "company": p.company,
        "message": p.message
    }

    const response = await HTTP(
      new Request(
        `${process.env.REACT_APP_URL}/sales`,
        {
          method: "POST",
          redirect: "follow",
          mode: "cors",
          credentials: "include",
          headers: {
            'Content-Type': `application/json`
          },
          body: JSON.stringify(payload)
        }
      )
    );
    if (response.status !== 200) {
      throw await response.json()
    }
    return await response.json()
  }
);

export const createSalesSlice = createSlice({
  name: 'createSales',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactSales.pending, (state) => {
        state.status = 'loading';
        state.error = "";
      })
      .addCase(contactSales.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'idle';
        state.error = "";
      })
      .addCase(contactSales.rejected, (state, action) => {
        state.status = 'failed';
        state.error = `Unable to contact sales please use email address. Error:  ${action.error.message}`;
      })
  }
});


export const contactSalesError = (state: RootState) => state.sales.error;
export const contactSalesStatus = (state: RootState) => state.sales.status;


export default createSalesSlice.reducer;
