import { useCallback } from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { HTTP } from '../../../helper/http';


export interface AccountUser {
    id: string;
    created: string;
    updated: string;
    accountId: string;
    userId: string;
    email: string;
}

export interface AccountUserState {
    status: 'idle' | 'loading' | 'failed';
    users: AccountUser[];
    error: string;
    deletedAccountUserId: string;
    addedUser: AccountUser | null
}

const initialState: AccountUserState = {
    status: 'idle',
    users: [] as AccountUser[],
    error: "",
    deletedAccountUserId: "",
    addedUser: {} as AccountUser
};

export const getAccountUsers = createAsyncThunk(
    'payload/getAccountUsers',
    async (offset: number) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/account/users?offset=${offset}`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);

export const addUserToAccount = createAsyncThunk(
    'payload/addUserToAccount',
    async (email: string) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/account/user?email=${encodeURIComponent(email)}`,
                {
                    method: "POST",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);

export const deleteUserFromAccount = createAsyncThunk(
    'payload/deleteUserFromAccount',
    async (payload: { id: string}) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/account/user/${payload.id}`,
                {
                    method: "DELETE",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 204) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return payload.id
    }
);

export const accountUsersSlice = createSlice({
    name: 'payload',
    initialState,
    reducers: {
        clearError: (state, action) => {
            state.error = "";
        },
        clearDeletedUser: (state, action) => {
            state.addedUser = action.payload;
        },
        clearAddedUser: (state, action) => {
            state.deletedAccountUserId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccountUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAccountUsers.fulfilled, (state, action) => {
                if (action.payload) {
                    state.users = action.payload;
                }
                state.status = 'idle';
                state.error = "";
            })
            .addCase(getAccountUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to get account users. Error:  ${action.error.message}`;
            })
            .addCase(addUserToAccount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addUserToAccount.fulfilled, (state, action) => {
                if (action.payload) {
                    state.addedUser = action.payload;
                }
                state.status = 'idle';
                state.error = "";
            })
            .addCase(addUserToAccount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to add user to account. Error:  ${action.error.message}`;
            })
            .addCase(deleteUserFromAccount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteUserFromAccount.fulfilled, (state, action) => {
                if (action.payload) {
                    state.deletedAccountUserId = action.payload;
                }
                state.status = 'idle';
                state.error = "";
            })
            .addCase(deleteUserFromAccount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = `Unable to delete account users. Error:  ${action.error.message}`;
            })
    },
});

export const { clearError, clearAddedUser, clearDeletedUser } = accountUsersSlice.actions;

export const accountUsersError = (state: RootState) => state.accountUsers.error;
export const accountUsers = (state: RootState) => state.accountUsers.users;
export const accountUsersStatus = (state: RootState) => state.accountUsers.status;
export const deletedAccountUserId = (state: RootState) => state.accountUsers.deletedAccountUserId;
export const addedUser = (state: RootState) => state.accountUsers.addedUser;


export default accountUsersSlice.reducer;
