import { HeadersTab } from "./HeadersTab";
import { BodyTab } from "./BodyTab";
import { CatchTab } from "./CatchTab";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DelayTab } from "./DelayTab";

export const RenderTab = ({
  tab,
  rType,
  wsUrl,
}: {
  tab: string;
  rType: string;
  wsUrl: string;
}) => {
  switch (tab) {
    case "headers":
      if (wsUrl && rType === "websocket") {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
              alignItems: "center",
            }}
          >
            <Typography>WebSocket headers already defined</Typography>
          </Box>
        );
      }
      return <HeadersTab />;
    case "body":
      return <BodyTab />;
    case "catch":
      return <CatchTab rType={rType} />;
    case "delay":
      return <DelayTab />;
    default:
      return <HeadersTab />;
  }
};
