import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { Button, Skeleton, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TableContainer from "@mui/material/TableContainer";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  selectCampaign,
  getCampaigns,
  Campaign,
  clearCampaigns,
  campaignsListStatus,
} from "./slice/campaign";
import { createCampaignResult } from "./slice/create";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { clearResult } from "./slice/create";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { switchStep } from "../section/slice/section";
import { clearDeletedCampaign } from "../dashboard/slice/campaign";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";

const RenderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dispatch = useAppDispatch();

  const e = data[index];

  return (
    <Box key={index} style={{ ...style }}>
      <Box
        sx={{
          borderRadius: 3,
          border: "0.5px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          maxHeight: 70,
          padding: 1.8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography variant="h5" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e.name}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              ID: {e.id}
            </Box>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {new Date(e.created).toLocaleString()}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              created at
            </Box>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography variant="subtitle2" align={"center"}>
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {e.owner_email}
            </Box>
            <Box
              sx={{
                color: "#bdbdbd",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              created by
            </Box>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            flexGrow: 1,
          }}
        >
          <Typography variant="subtitle2" align={"center"} sx={{ margin: 1 }}>
            <Button
              onClick={() => {
                dispatch(selectCampaign(e));
                dispatch(switchStep("dashboard"));
              }}
              size="small"
              variant="outlined"
              sx={{
                fontWeight: "bold",
                color: () => {
                  switch (e.status) {
                    case "created":
                      return "#4078b7";
                    case "running":
                      return "#4a9c07";
                    case "pending":
                      return "rgba(2,2,2,0.38)";
                    default:
                      return "#d32f2f";
                  }
                },
              }}
              endIcon={<ArrowForwardIcon />}
            >
              {e.status}
            </Button>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const LoadMore = (getBots: () => void, loading: boolean) => {
  return (
    <LoadingButton variant="text" loading={loading} onClick={() => getBots()}>
      Load more...
    </LoadingButton>
  );
};

const Loading = (b: boolean) => {
  if (b) {
    return (
      <TableContainer style={{ height: "calc(57vh)" }}>
        <Box>
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
          <Skeleton animation="wave" height={118} />
        </Box>
      </TableContainer>
    );
  }
  return (
    <TableContainer style={{ height: "calc(57vh)" }}>
      <Box
        sx={{
          flex: 1,
          flexGrow: 1,
          mx: "2px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1" align={"center"}>
          <Box
            sx={{
              marginTop: 10,
            }}
          />
          <DashboardRoundedIcon
            sx={{
              fontSize: 120,
              color: (theme) => theme.palette.text.secondary,
            }}
          />

          <Box
            sx={{
              fontWeight: "bold",
              margin: 4,
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            Campaigns list is empty. Please use action button below to add new
            campaign.
          </Box>
        </Typography>
      </Box>
    </TableContainer>
  );
};

export default function VirtualizedList() {
  const dispatch = useAppDispatch();

  const [data, setData] = useState([] as Campaign[]);
  const resultOnCreate = useAppSelector(createCampaignResult);
  const status = useAppSelector(campaignsListStatus);

  const loadMore = () => {
    const startFetching = async () => {
      const { payload } = await dispatch(getCampaigns(data.length));
      if ((payload as Campaign[])?.length > 0) {
        setData([...data, ...payload]);
      }
    };

    startFetching();
  };

  useEffect(() => {
    let ignore = false;

    const startFetching = async () => {
      const { payload } = await dispatch(getCampaigns(0));
      if (!ignore) {
        dispatch(clearDeletedCampaign(""));
        if ((payload as Campaign[])?.length > 0) {
          setData(payload);
        }
      }
    };

    startFetching();

    return () => {
      ignore = true;
    };
  }, [dispatch]);

  useEffect(() => {
    if (resultOnCreate?.referenceId) {
      dispatch(clearResult(0));
      dispatch(clearCampaigns(0));
      dispatch(getCampaigns(0));

      const startFetching = async () => {
        const { payload } = await dispatch(getCampaigns(0));
        if ((payload as Campaign[])?.length > 0) {
          setData(payload);
        }
      };

      startFetching();
    }
  }, [dispatch, resultOnCreate?.referenceId]);

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        mx: "2px",
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {data?.length ? (
        <TableContainer style={{ height: "calc(57vh)" }}>
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                itemData={data}
                height={height}
                width={width}
                itemSize={() => 80}
                itemCount={data.length}
                overscanCount={5}
              >
                {RenderRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        </TableContainer>
      ) : (
        Loading(status === "loading")
      )}
      {data.length >= 10
        ? LoadMore(loadMore, status === "loading" ? true : false)
        : ""}
    </Box>
  );
}
