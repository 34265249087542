import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Bot } from "./slice/bots";
import Typography from "@mui/material/Typography";
import PayloadList from "./PayloadList";
import { BootstrapDialog, BootstrapDialogTitle } from "./DeleteBotsDialog";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  applyBotPayload,
  selectedPayload,
  botsError,
} from "./slice/bots";
import { campaign } from "../campaign/slice/campaign";


export const BotPayloadDialog = ({
  campaignId,
  bot,
}: {
  campaignId: string;
  bot: Bot;
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const currentPayload = useAppSelector(selectedPayload);
  const campaignData = useAppSelector(campaign);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{ height: "30px", width: "120px" }}
        variant="outlined"
        color="primary"
        sx={{
          margin: 1
        }}
        endIcon={<AccountTreeRoundedIcon />}
      >
        payload
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Bot Payload
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Please select payloads and press apply. Selected payloads
            will be applied for one particular bot in the campaign. 
            You can select up to <b>{bot?.vUsers}</b> payloads all other selections will be ignored.
          </Typography>
          <PayloadList />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="secondary"
            autoFocus
            onClick={() => {
              const ids = currentPayload.map(i => i?.id);
              dispatch(
                applyBotPayload({
                  id: bot?.id,
                  payload: ids,
                })
              );
              handleClose();
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
