import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import TagIcon from "@mui/icons-material/Tag";

import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../dashboard/DeleteBotsDialog";
import { IconButton, Link } from "@mui/material";
import { selectPayloads, setPayload } from "./slice/new";

export const ChangeOrderDialog = ({ i }: { i: number }) => {
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState("");
  const payloads = useAppSelector(selectPayloads);

  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={handleClickOpen}
      >
        <TagIcon fontSize="inherit" />
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Order change
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Enter case number, where you want to move this test case.
          </Typography>
          <TextField
            fullWidth
            id="campaign_name"
            variant="outlined"
            color="info"
            label="case #"
            placeholder="1"
            size="small"
            onChange={(e) => setNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="success"
            autoFocus
            onClick={() => {
              const arr = JSON.parse(JSON.stringify(payloads));
              const c = Number(number);
              const n = c - 1;
              if (n >= 0 && n <= arr.length) {
                const element = arr[i];
                arr.splice(i, 1);
                arr.splice(n, 0, element);
                dispatch(setPayload(arr));
              }
              handleClose();
            }}
          >
            change
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
