import { useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "../../app/hooks";
import Link from "@mui/material/Link";

import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../dashboard/DeleteBotsDialog";
import { contactSales } from "./slice/sales";

export const ContactSales = ({
  title,
  location,
  text,
  btnVariant,
}: {
  title: string;
  location: string;
  text: string;
  btnVariant: "text" | "outlined" | "contained" | undefined;
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderLocation = (location: string) => {
    switch (location) {
      case "footer":
        return (
          <Link
            onClick={handleClickOpen}
            variant="subtitle1"
            style={{ color: "rgba(0,0,0,0.54)" }}
            underline="hover"
          >
            Sales
          </Link>
        );
      default:
        return (
          <Button fullWidth onClick={handleClickOpen} variant={btnVariant}>
            {text}
          </Button>
        );
    }
  };

  return (
    <div>
      {renderLocation(location)}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {text}
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Hello there. We are glad to help you. Please fill in the fields and
            we will reach you as soon as possible{" "}
          </Typography>
          <TextField
            fullWidth
            id="name"
            variant="outlined"
            color="info"
            label="Last/First Name"
            size="small"
            sx={{ marginTop: 5 }}
            onChange={(e) => setName(e.target.value)}
          />
          &nbsp;
          <TextField
            fullWidth
            id="email"
            variant="outlined"
            color="info"
            label="Company name"
            size="small"
            onChange={(e) => setCompany(e.target.value)}
          />
          &nbsp;
          <TextField
            fullWidth
            id="email"
            variant="outlined"
            color="info"
            type={"email"}
            label="Company email"
            size="small"
            onChange={(e) => setEmail(e.target.value)}
          />
          &nbsp;
          <TextField
            fullWidth
            id="email"
            variant="outlined"
            color="info"
            label="Your message to us"
            multiline
            rows={5}
            maxRows={40}
            size="small"
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
            }}
            color="success"
            autoFocus
            onClick={() => {
              dispatch(
                contactSales({
                  email: email,
                  name: name,
                  company: company,
                  message: message,
                })
              );
              handleClose();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
