import { useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { FormControl, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { MethodSelector } from "./MethodSelector";
import {
  PayloadCatchState,
  selectCurrentPayload,
  setCurrentPayload,
} from "./slice/new";
import { RenderCatch } from "./RenderCatch";

export const CatchTab = ({ rType }: { rType: string }) => {
  const dispatch = useAppDispatch();
  const currentPayload = useAppSelector(selectCurrentPayload);
  const [nameCatch, setNameCatchValue] = useState("");
  const [location, setLocation] = useState("body");
  const [wsReferenceTime, setWSReferenceTime] = useState("10s");
  const [wsReference, setWSReference] = useState("");
  const [wsReferenceId, setWSReferenceId] = useState("");
  const [path, setPath] = useState("");

  const updateCatch = () => {
    const newPayload = JSON.parse(JSON.stringify(currentPayload));
    const newCatch = newPayload?.catch ?? [];
    const catchObj = {
      location: location,
      name: nameCatch,
      path: path,
      wsReference: wsReference,
      wsReferenceId: wsReferenceId,
      wsReferenceTime: wsReferenceTime,
    };
    const arr = newCatch.filter(
      (e: PayloadCatchState) => e?.name !== nameCatch
    );
    arr.push(catchObj);
    newPayload.catch = arr;
    dispatch(setCurrentPayload(newPayload));
    setNameCatchValue("");
    setWSReference("");
    setPath("");
    setWSReferenceId("");
    setWSReferenceTime("10s");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        maxHeight: 250,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          alignContent: "center",
          alignItems: "center",
          marginBottom: 2
        }}
      >
        {rType === "websocket" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignContent: "center",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <InputLabel shrink htmlFor="bootstrap-input">
              Response reference path
            </InputLabel>
            <TextField
              sx={{ display: "flex", flex: 0.5, justifyContent: "flex-end" }}
              size="small"
              id="value"
              variant="outlined"
              label="Path"
              color="info"
              value={wsReference}
              placeholder="data.id"
              onChange={(e) => setWSReference(e.target.value)}
            />
          </Box>
        ) : (
          ""
        )}

        {rType === "websocket" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignContent: "center",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <InputLabel shrink htmlFor="bootstrap-input">
              Response reference ID
            </InputLabel>
            <TextField
              sx={{ display: "flex", flex: 0.5, justifyContent: "flex-end" }}
              size="small"
              id="value"
              variant="outlined"
              label="ID"
              color="info"
              value={wsReferenceId}
              placeholder="5dabd801-8e38-43a9-8aa3-edea2693a480"
              onChange={(e) => setWSReferenceId(e.target.value)}
            />
          </Box>
        ) : (
          ""
        )}

        {rType === "websocket" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignContent: "center",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <InputLabel shrink htmlFor="bootstrap-input">
              Response Time
            </InputLabel>
            <TextField
              sx={{ display: "flex", flex: 0.5, justifyContent: "flex-end" }}
              size="small"
              id="value"
              variant="outlined"
              label="Time(i.e. 1s)"
              color="info"
              value={wsReferenceTime}
              placeholder="10s"
              onChange={(e) => setWSReferenceTime(e.target.value)}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>

      {rType !== "websocket" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            alignContent: "center",
            alignItems: "center",
            marginBottom: 2
          }}
        >
          <Typography
            sx={{ display: "flex", flex: 1 }}
            variant="body2"
            align={"center"}
          >
            <Box
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              Response Code
            </Box>
          </Typography>
          <TextField
            sx={{ display: "flex", flex: 0.5, justifyContent: "flex-end" }}
            size="small"
            id="value"
            variant="outlined"
            label="Status"
            color="info"
            value={currentPayload?.status}
            required
            placeholder="response_catch"
            onChange={(e) => {
              const newPayload = JSON.parse(JSON.stringify(currentPayload));
              newPayload.status = Number(e.target.value);
              dispatch(setCurrentPayload(newPayload));
            }}
          />
        </Box>
      ) : (
        ""
      )}
      <Divider orientation="horizontal" flexItem />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          maxWidth: 680,
          alignContent: "stretch",
          alignItems: "stretch"
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flex: 0.3,
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            minWidth: 250,
          }}
        >
          <TextField
            sx={{ width: 200, marginTop: 2 }}
            size="small"
            id="value"
            variant="outlined"
            color="info"
            label="Name"
            value={nameCatch}
            placeholder="login_catch"
            onChange={(e) => setNameCatchValue(e.target.value)}
          />
          {rType === "http" ? (
            <FormControl sx={{ marginTop: 2 }}>
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                sx={{ width: 200 }}
                size="small"
                label="Location"
                labelId="location-label"
                value={location ?? "body"}
                onChange={(e) => setLocation(e.target.value)}
              >
                {MethodSelector(["body", "header"])}
              </Select>
            </FormControl>
          ) : (
            ""
          )}
          <TextField
            sx={{ width: 200, marginTop: 2 }}
            size="small"
            id="value"
            variant="outlined"
            color="info"
            label="Path"
            value={path}
            placeholder="login_catch"
            onChange={(e) => setPath(e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flex: 0.1,
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            color="secondary"
            sx={{ borderRadius: 8 }}
            endIcon={<ArrowForwardIosIcon />}
            variant="contained"
            onClick={() => {
              updateCatch();
            }}
          >
            add
          </Button>
        </Box>

        <Box
          sx={{
            minWidth: 250,
            maxHeight: 300,
            display: "flex",
            flex: 0.6,
            flexDirection: "column",
            flexWrap: "wrap",
            alignContent: "stretch",
            alignItems: "stretch",
            overflow: "auto"

          }}
        >
          {RenderCatch()}
        </Box>
      </Box>
    </Box>
  );
};
