import React from "react";

import { Grid, Typography } from "@mui/material";
import { TermsDialog } from "../policy/TermsDialog";
import { PrivacyDialog } from "../policy/PrivacyDialog";
import { ContactSales } from "../contact/SalesDialog";
import { SupportDialog } from "../contact/SupportDialog";

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
];

export default function Footer() {
  return (
    <React.Fragment>
      {/* Footer */}
      <Grid
        container
        spacing={4}
        justifyContent="space-evenly"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        {/* {footers.map((footer) => (
          <Grid item xs={6} sm={3} key={footer.title}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.description.map((item) => (
                <li key={item}>
                  <Link href="#" variant="subtitle1" color="text.secondary">
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))} */}
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            Contact
          </Typography>
          <ContactSales
            title={"Contact sales"}
            location={"footer"}
            text={""}
            btnVariant={undefined}
          />
          <SupportDialog location={"footer"} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            Services
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Security automation
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Threat modeling
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Application Security
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Software development
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Software testing
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            Legal
          </Typography>
          <TermsDialog />
          <br />
          <PrivacyDialog color={"rgba(0,0,0,0.54)"} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
