import { useCallback } from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import { HTTP } from '../../../helper/http';
import { Payload, BotPayload } from "../../payloads/slice/payload";

export interface Bot {
    id: string;
    created: string;
    updated: string;
    campaignId: string;
    accountId: string;
    userId: string;
    instanceId: string;
    regionId: string;
    ip: string;
    startAt: string;
    duration: number;
    vUsers: number;
    status: string;
    name: string
    payload: Payload[];
}

export interface BotsState {
    botsStatus: 'idle' | 'loading' | 'failed';
    botsListStatus: 'idle' | 'loading' | 'failed';
    bots: Bot[];
    botsError: string;
    currentPayload: Payload[];
    deletedBot: string;
    botsDeletedFrom: string;
}

const initialState: BotsState = {
    botsStatus: 'idle',
    botsListStatus: 'idle',
    bots: [] as Bot[],
    botsError: "",
    currentPayload: [] as Payload[],
    deletedBot: "",
    botsDeletedFrom: ""
};

export const getBots = createAsyncThunk(
    'dashboard/getBots',
    async (payload: { id: string; offset: number }) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/${payload.id}/bots?offset=${payload.offset}`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);

export const deleteBot = createAsyncThunk(
    'campaign/deleteBot',
    async (payload: { campaignId: string; botId: string }) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/${payload.campaignId}/bot/${payload.botId}`,
                {
                    method: "DELETE",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        'Content-Type': `application/json`
                    },
                }
            )
        );
        if (response.status !== 204) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return payload.botId
    }
);

export const deleteBots = createAsyncThunk(
    'campaign/deleteBots',
    async (payload: { campaignId: string }) => {
        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/${payload.campaignId}/bots`,
                {
                    method: "DELETE",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        'Content-Type': `application/json`
                    },
                }
            )
        );
        if (response.status !== 204) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }

        return payload.campaignId
    }
);


export const applyBotPayload = createAsyncThunk(
    'dashboard/applyBotPayload',
    async (payload: { payload: string[]; id: string }) => {

        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/payload/bot`,
                {
                    method: "POST",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        'Content-Type': `application/json`
                    },
                    body: JSON.stringify(payload)
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.text()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.text()
    }
);

export const applyCampaignPayload = createAsyncThunk(
    'dashboard/applyCampaignPayload',
    async (payload: { campaignId: string; payload: string[] }) => {

        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/payload/campaign`,
                {
                    method: "POST",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        'Content-Type': `application/json`
                    },
                    body: JSON.stringify(payload)
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.text()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.text()
    }
);

export const getBotsState = createAsyncThunk(
    'dashboard/getBotsState',
    async (campaignId: string ) => {

        const response = await HTTP(
            new Request(
                `${process.env.REACT_APP_URL}/campaign/${campaignId}/bots/state`,
                {
                    method: "GET",
                    redirect: "follow",
                    mode: "cors",
                    credentials: "include"
                }
            )
        );
        if (response.status !== 200) {
            let err = await response.json()
            if (response.status === 401 || response.status === 403) {
                err = "Please re-login"
            }
            throw err
        }
        return await response.json()
    }
);


export const botsSlice = createSlice({
    name: 'bots',
    initialState,
    reducers: {
        setPayload: (state, action) => {
            state.currentPayload = action.payload;
        },
        clearError: (state, action) => {
            state.botsError = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBots.pending, (state) => {
                state.botsListStatus = 'loading';
            })
            .addCase(getBots.fulfilled, (state, action) => {
                if (action.payload) {
                    state.bots = action.payload;
                }
                state.botsListStatus = 'idle';
                state.botsError = "";
            })
            .addCase(getBots.rejected, (state, action) => {
                state.botsListStatus = 'failed';
                state.botsError = `Unable to get bots. Error:  ${action.error.message}`;
            })
            .addCase(applyBotPayload.pending, (state) => {
                state.botsStatus = 'loading';
            })
            .addCase(applyBotPayload.fulfilled, (state, action) => {
                state.botsStatus = 'idle';
                state.botsError = "";
            })
            .addCase(applyBotPayload.rejected, (state, action) => {
                state.botsStatus = 'failed';
                state.botsError = `Unable apply payload to bot. Error:  ${action.error.message}`;
            })
            .addCase(applyCampaignPayload.pending, (state) => {
                state.botsStatus = 'loading';
            })
            .addCase(applyCampaignPayload.fulfilled, (state, action) => {
                state.botsStatus = 'idle';
                state.botsError = "";
            })
            .addCase(applyCampaignPayload.rejected, (state, action) => {
                state.botsStatus = 'failed';
                state.botsError = `Unable apply payload to campaign. Error:  ${action.error.message}`;
            })
            .addCase(getBotsState.pending, (state) => {
                state.botsStatus = 'loading';
            })
            .addCase(getBotsState.fulfilled, (state, action) => {
                state.botsStatus = 'idle';
                state.botsError = "";
            })
            .addCase(getBotsState.rejected, (state, action) => {
                state.botsStatus = 'failed';
                state.botsError = `Unable get bots state. Error:  ${action.error.message}`;
            })
            .addCase(deleteBots.pending, (state) => {
                state.botsStatus = 'loading';
            })
            .addCase(deleteBots.fulfilled, (state, action) => {
                state.botsStatus = 'idle';
                state.botsError = "";
                state.botsDeletedFrom = action.payload
            })
            .addCase(deleteBots.rejected, (state, action) => {
                state.botsStatus = 'failed';
                state.botsError = `Unable to delete bots. Error:  ${action.error.message}`;
            })
            .addCase(deleteBot.pending, (state) => {
                state.botsStatus = 'loading';
            })
            .addCase(deleteBot.fulfilled, (state, action) => {
                state.botsStatus = 'idle';
                state.deletedBot = action.payload;
                state.botsError = "";
            })
            .addCase(deleteBot.rejected, (state, action) => {
                state.botsStatus = 'failed';
                state.botsError = `Unable to delete bots. Error:  ${action.error.message}`;
            })
    },
});

export const { setPayload, clearError } = botsSlice.actions;


export const botsError = (state: RootState) => state.bots.botsError;
export const bots = (state: RootState) => state.bots.bots;
export const botsStatus = (state: RootState) => state.bots.botsStatus;
export const botsListStatus = (state: RootState) => state.bots.botsListStatus;
export const selectedPayload = (state: RootState) => state.bots.currentPayload;
export const deletedBot = (state: RootState) => state.bots.deletedBot;
export const botsDeletedFrom = (state: RootState) => state.bots.botsDeletedFrom;


export default botsSlice.reducer;