import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { List, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { PayloadState, selectCurrentPayload, setCurrentPayload } from "./slice/new";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export const RenderCatch = () => {
  const dispatch = useAppDispatch();
  const currentPayload = useAppSelector(selectCurrentPayload);

  if (currentPayload?.catch?.length === 0) {
    return (
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        fontWeight: "bold",
        color: (theme) => theme.palette.text.disabled,
        flex: 1,
      }}>
        <Typography>
          There are no catch variables yet.
        </Typography>
      </Box>
    )
  }

  return (
    <List
      sx={{
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      {(currentPayload as PayloadState).catch &&
        currentPayload?.catch?.map((e, i) => (
          <ListItem key={i}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flexGrow: 1,
                  flex: 0.3,
                }}
              >
                <Typography variant="body2" align={"center"}>
                  <Box
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {e?.name}
                  </Box>
                </Typography>
              </Box>

              <ArrowRightAltIcon />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flexGrow: 1,
                  flex: 0.3,
                }}
              >
                <Typography variant="body2" align={"center"}>
                  <Box
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {e?.location}
                  </Box>
                </Typography>
              </Box>

              <ArrowRightAltIcon />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flexGrow: 1,
                  flex: 0.3,
                }}
              >
                <Typography variant="body2" align={"center"}>
                  <Box
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {e?.path}
                  </Box>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  flexGrow: 1,
                  flex: 0.1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  align={"center"}
                  sx={{ margin: 1 }}
                >
                  <IconButton
                    sx={{}}
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      const newPayload = JSON.parse(
                        JSON.stringify(currentPayload)
                      );
                      const arr = (newPayload as PayloadState)?.catch.filter(
                        (i) => {
                          return e?.path !== i.path;
                        }
                      );
                      newPayload.catch = arr;
                      dispatch(setCurrentPayload(newPayload));
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
              </Box>
            </Box>
          </ListItem>
        ))}
    </List>
  );
};
