import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { TestRunMsg, wsTestRunMsg } from "../connect/slice/ws";
import { BootstrapDialog, BootstrapDialogTitle } from "./DeleteBotsDialog";
import { Box } from "@mui/material";
import { TestResultList } from "./TestResultList";
import { runTest} from "../dashboard/slice/launch";

export const TestMsgDialog = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const testMsg = useAppSelector(wsTestRunMsg);
  const [msg, setMsg] = useState(testMsg);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (testMsg?.referenceId !== msg?.referenceId) {
      setMsg(testMsg);
      setOpen(true);
      dispatch(runTest(false));
    }
  }, [testMsg?.referenceId, msg?.referenceId]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Bot test run
          <br />
          bot-<strong>{msg?.botId}</strong>
        </BootstrapDialogTitle>
        <DialogContent>
          <TestResultList msg={msg} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
